import { Box, Button, Flex, Image, Link, Text, useBreakpointValue } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { getEcommerceTrackingObj } from "@/pages/checkout/[[...step]]";
import { dataLayerUserIdlocalStorageId } from "@/services/client";
import { useStore } from "@/store";
import { StarIcon } from "../../icons";
import { Stickers } from "../Stickers";
import { handleProductClickTracking } from "./handleProductClickTracking";
import { ProductCardPrice } from "./ProductCardPrice";
import { ProductCardSection } from "./ProductCardSection";
import { ProductCardTab } from "./ProductCardTab";
export function ProductCard(props) {
  const {
    voiceHours,
    campaign,
    data,
    textUnderData,
    productTab,
    euData,
    textUnderPrice,
    subscriptionTypeIcon,
    ctaText,
    isActive,
    stickers,
    readMoreUrl,
    readMoreLabel,
    readMoreLinkColor,
    context,
    nonInteractive,
    // Non interactive cards have no action button because they are not used for ordering
    ...rest
  } = props;
  const price = campaign ? campaign.fields.campaignPrice : rest.price;
  const priceBefore = campaign ? rest.price : null;
  const router = useRouter();
  const [checkoutSession, setCheckoutSession, resetCheckoutSession] = useStore(state => [state.checkout.checkoutSession, state.checkout.setCheckoutSession, state.checkout.resetCheckoutSession]);
  useEffect(() => {
    if (typeof window === "undefined" || nonInteractive) return;
    const trackingUserId = localStorage.getItem(dataLayerUserIdlocalStorageId);
    window.dataLayer = window.dataLayer || [];
    const lastEntry = window.dataLayer[window.dataLayer.length - 1];
    // Add view_item event to the dataLayer and if it already exists, add the new item to the items array
    // Check if the last event was a view_item event
    if (lastEntry && lastEntry.event === "view_item" && lastEntry.ecommerce && Array.isArray(lastEntry.ecommerce.items)) {
      const newItem = getEcommerceTrackingObj(props).items[0];
      // Check if the item is already in the ecommerce object
      if (!lastEntry.ecommerce.items.some(item => item.item_id === newItem.item_id)) {
        // Add the new item to the ecommerce items object
        lastEntry.ecommerce.items.push(newItem);
      }
    } else {
      // If the last event was not a view_item event, push a new view_item event
      window.dataLayer.push({
        ecommerce: null
      }); // Clear the previous ecommerce object.
      window.dataLayer.push({
        event: "view_item",
        loginStatus: !!trackingUserId,
        ecommerce: getEcommerceTrackingObj(props)
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  function handleProductClick(productItem) {
    // Reset checkout session on every productClick
    resetCheckoutSession();

    // Destructure to exclude stickers
    // eslint-disable-next-line no-unused-vars
    const {
      stickers,
      ...productItemWithoutStickers
    } = productItem;

    // Update checkout session
    setCheckoutSession({
      ...checkoutSession,
      productKey: productItem.optionKey,
      subscription: {
        ...productItemWithoutStickers,
        campaign: productItem.campaign?.fields || null
      },
      analytics: {
        utm_medium: router.query.utm_medium,
        utm_source: router.query.utm_source,
        utm_campaign: router.query.utm_campaign
      }
    });

    // Handle Product Click Tracking and redirect
    handleProductClickTracking(productItem);
    router.push("/checkout");
  }
  const {
    onClick = !nonInteractive ? handleProductClick : null
  } = rest;
  let isMobile = useBreakpointValue({
    base: true,
    lg: false
  });
  isMobile = !nonInteractive ? isMobile : true;
  return <Flex as={motion.div} direction="column" sx={{
    position: "relative",
    color: "white",
    flex: "0 0 auto",
    textAlign: "center"
  }} {...!isMobile && {
    whileHover: {
      scale: 1.1
    }
  }} data-sentry-element="Flex" data-sentry-component="ProductCard" data-sentry-source-file="ProductCard.jsx">
            <Flex as={motion.div} className="productcard" direction="column" sx={{
      width: "grid.columns.3",
      position: "relative",
      flex: "1 0 auto",
      cursor: !nonInteractive ? "pointer" : "unset",
      userSelect: "none",
      ...rest.sx
    }} data-sentry-element="Flex" data-sentry-source-file="ProductCard.jsx">
                {!!productTab && !nonInteractive && <ProductCardTab as={motion.div} {...isMobile && {
        animate: {
          opacity: isActive ? 1 : 0.2
        }
      }}>
                        {productTab}
                    </ProductCardTab>}

                <Box as={motion.div} className="productcard-content-wrapper" sx={{
        position: "relative",
        zIndex: 1,
        width: "100%",
        height: "100%",
        borderRadius: "card",
        backgroundColor: "#000000",
        background: "linear-gradient(15.51deg, rgba(0, 0, 0, 0.8) 0.87%, rgba(1, 30, 122) 87.69%)",
        boxShadow: "-20px 40px 50px 0px rgba(0, 0, 0, 0)"
      }}

      // Diffentiate between mobile and desktop animations
      {...!isMobile ? {
        whileHover: {
          boxShadow: "-20px 40px 50px 0px rgba(0, 0, 0, 0.25)"
        }
      } : {
        animate: {
          opacity: isActive ? 1 : 0.2
        }
      }} data-sentry-element="Box" data-sentry-source-file="ProductCard.jsx">
                    <Box sx={{
          position: "relative",
          height: "100%",
          paddingBottom: 4
        }} onClick={() => onClick(props)} data-sentry-element="Box" data-sentry-source-file="ProductCard.jsx">
                        <Stickers stickers={stickers} data-sentry-element="Stickers" data-sentry-source-file="ProductCard.jsx" />
                        <Flex className="productcard-content" sx={{
            flexDirection: "column",
            height: "100%"
          }} data-sentry-element="Flex" data-sentry-source-file="ProductCard.jsx">
                            <ProductCardSection hasBottomBorder data-sentry-element="ProductCardSection" data-sentry-source-file="ProductCard.jsx">
                                <Text sx={{
                fontSize: "h4",
                fontWeight: "black",
                textTransform: "uppercase",
                marginBottom: 0
              }} data-sentry-element="Text" data-sentry-source-file="ProductCard.jsx">
                                    {voiceHours}
                                </Text>
                            </ProductCardSection>

                            <ProductCardSection hasBottomBorder data-sentry-element="ProductCardSection" data-sentry-source-file="ProductCard.jsx">
                                <Text variant="productCardGradient" sx={{
                fontSize: "96px",
                lineHeight: "0.8",
                textTransform: "uppercase",
                marginBottom: 1
              }} data-sentry-element="Text" data-sentry-source-file="ProductCard.jsx">
                                    {data}
                                </Text>
                                <Text sx={{
                fontSize: "h4",
                fontWeight: "black",
                textTransform: "uppercase",
                marginBottom: 0
              }} data-sentry-element="Text" data-sentry-source-file="ProductCard.jsx">
                                    {textUnderData}
                                </Text>
                                <Text variant="productCardSmallBlue" sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: 0,
                gap: 1,
                marginTop: 1
              }} data-sentry-element="Text" data-sentry-source-file="ProductCard.jsx">
                                    {euData ? <>
                                            {subscriptionTypeIcon ? <Image src={`https:${subscriptionTypeIcon.fields.file.url}`} width="16px" height="16px" marginRight={1} alt={subscriptionTypeIcon.fields.description} /> : <StarIcon width="16px" height="16px" />}
                                            <Text as="span">
                                                {euData} gb data i EU
                                            </Text>
                                        </> : <>
                                            {subscriptionTypeIcon ? <Image src={`https:${subscriptionTypeIcon.fields.file.url}`} width="16px" height="16px" marginRight={1} alt={subscriptionTypeIcon.fields.description} /> : <StarIcon width="16px" height="16px" />}
                                            <Text as="span">
                                                Danmark
                                            </Text>
                                        </>}
                                </Text>

                            </ProductCardSection>

                            <ProductCardSection canFlex data-sentry-element="ProductCardSection" data-sentry-source-file="ProductCard.jsx">
                                <ProductCardPrice price={price} priceBefore={priceBefore} data-sentry-element="ProductCardPrice" data-sentry-source-file="ProductCard.jsx" />

                                {!!textUnderPrice && <Text variant="productCardSmallBlue" sx={{
                marginTop: 4,
                marginBottom: 0
              }}>
                                        {textUnderPrice}
                                    </Text>}
                            </ProductCardSection>
                            {!nonInteractive && <Button data-test-id="product-item-selector" type="button" variant="action" isDisabled={!!nonInteractive} sx={{
              fontWeight: "black",
              display: "flex",
              textTransform: "uppercase",
              marginY: 0,
              marginX: 3,
              whiteSpace: "nowrap"
            }}>
                                    {ctaText}
                                </Button>}
                        </Flex>
                    </Box>
                </Box>
            </Flex>
            {!!readMoreUrl && <Link href={readMoreUrl} sx={{
      position: "absolute",
      bottom: context === "hero" ? "-2.8rem" : "-1rem",
      paddingY: 3,
      marginX: "auto",
      fontWeight: "black",
      display: "block",
      textDecoration: "none",
      whiteSpace: "nowrap",
      color: readMoreLinkColor,
      width: "100%",
      textAlign: "center",
      transition: "color 0.3s, padding 0.3s",
      zIndex: 99,
      _hover: {
        color: readMoreLinkColor === "#ffffff" ? "#f1f1f1" : "white"
      },
      _focus: {
        boxShadow: 'none',
        borderColor: 'inherit'
      }
    }}>
                    {readMoreLabel}
                </Link>}
        </Flex>;
}
ProductCard.defaultProps = {
  isActive: true,
  ctaText: "Bestil nu",
  nonInteractive: false
};