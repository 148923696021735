import { chakra } from "@chakra-ui/react";
export function DankortIcon({
  sx,
  ...rest
}) {
  return <chakra.svg xmlns="http://www.w3.org/2000/svg" width="40" height="26" fill="none" viewBox="0 0 40 26" sx={{
    ...sx
  }} {...rest} data-sentry-element="unknown" data-sentry-component="DankortIcon" data-sentry-source-file="DankortIcon.jsx">
            <path fill="#fff" d="M0 0h40v26H0z" data-sentry-element="path" data-sentry-source-file="DankortIcon.jsx" />
            <path fill="#fff" d="M12.3 1.79a11.04 11.04 0 0 0-10.95 11.1C1.35 19.02 6.26 24 12.3 24h14.75C33.09 24 38 19.02 38 12.9c0-6.13-4.91-11.11-10.95-11.11H12.3Z" data-sentry-element="path" data-sentry-source-file="DankortIcon.jsx" />
            <path fill="#ED1C24" d="m30.23 11.82 4.17 5.52a9.22 9.22 0 0 0 1.38-4.89 9.2 9.2 0 0 0-1.52-5.1l-4.03 4.47ZM15.84 6.96c3.56 0 6.24.86 6.52 3.71l3.74-3.7h7.46A9.01 9.01 0 0 0 26.84 4H12.5a9.01 9.01 0 0 0-6.7 2.96h10.05Zm-6.51 3.71L8 14h5.3c1.58 0 2.05-.6 2.4-1.75.35-1.14-.52-1.58-1.6-1.58H9.33Zm17.52 7.55-4.06-5.33c-.75 3.64-3.2 5.33-7.81 5.33H5.79a8.93 8.93 0 0 0 7.1 3.56h14.69c2.87 0 5.43-1.4 7.09-3.56h-7.82Z" data-sentry-element="path" data-sentry-source-file="DankortIcon.jsx" />
        </chakra.svg>;
}