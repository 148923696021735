import PropTypes from "prop-types";
import { forwardRef } from "react";
import { Flex } from "@chakra-ui/react";
import { Tooltip } from "../Tooltip";
import { FormControl, FormErrorMessage, FormLabel, Input as CInput, InputGroup, InputRightElement } from "@chakra-ui/react";
import { useComponentDidMount, useUniqueId } from "../../../hooks";
const messageMarginTop = 1;

/**
 * Default Input field. Use instad for Chakra's.
 * Input is by default wrapped in a <FormControl>'er and allows for ErrorMessage state.
 * Can be used with React-Hook-Form (RHF) and yup validation.
 *
 * Usage:
 *
 *        <Input
 *            label="Nuværende nummer"
 *            placeholder="jsdjad"
 *            errorMessage={errors?.firstName?.message ?? null}
 *            isRequired={true}
 *            {...register("firstName")}
 *        />
 *
 */
function InputComponent({
  label,
  sx,
  labelSx,
  inputSx,
  errorMessage,
  placeholder,
  type,
  inputProps,
  /* HTML validation control for reqiored. Not related to RHF/yup */
  isRequired = false,
  onChange,
  onBlur,
  name,
  RightIcon,
  tooltip,
  ...rest
}, ref) {
  const fieldId = useUniqueId();
  const componentDidMount = useComponentDidMount();
  return componentDidMount ? <FormControl isRequired={isRequired} sx={{
    marginBottom: 4,
    ...sx
  }} isInvalid={errorMessage} {...rest} data-sentry-element="FormControl" data-sentry-component="InputComponent" data-sentry-source-file="Input.jsx">
            <Flex data-sentry-element="Flex" data-sentry-source-file="Input.jsx">
                {label && <FormLabel htmlFor={fieldId} sx={{
        fontWeight: "bold",
        fontSize: "h5",
        ...labelSx
      }}>
                        {label}
                    </FormLabel>}
                {tooltip && <Tooltip label={tooltip} size="small" />}
            </Flex>
            <InputGroup data-sentry-element="InputGroup" data-sentry-source-file="Input.jsx">
                {RightIcon ? <InputRightElement sx={{
        height: "100%"
      }}>
                        {RightIcon}
                    </InputRightElement> : null}
                <CInput id={fieldId} sx={{
        _placeholder: {
          fontWeight: "bold"
        },
        ...inputSx
      }} {...{
        type,
        placeholder,
        onChange,
        onBlur,
        name,
        ref,
        ...inputProps
      }} className="mask-me" data-sentry-element="CInput" data-sentry-source-file="Input.jsx" />
            </InputGroup>
            {errorMessage && <FormErrorMessage sx={{
      marginTop: messageMarginTop,
      color: "error"
    }}>
                    {errorMessage}
                </FormErrorMessage>}
        </FormControl> : null;
}
export const Input = forwardRef(InputComponent);
Input.propTypes = {
  errorMessage: PropTypes.string,
  inputSx: PropTypes.object,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  labelSx: PropTypes.object,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  inputProps: PropTypes.object,
  sx: PropTypes.object,
  type: PropTypes.string
};