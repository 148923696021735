import PropTypes from "prop-types";
export function ArrowRightIcon({
  fill = null,
  ...rest
}) {
  return <svg fill="none" height={13} width={8} xmlns="http://www.w3.org/2000/svg" {...rest} data-sentry-element="svg" data-sentry-component="ArrowRightIcon" data-sentry-source-file="ArrowRightIcon.jsx">
            <path d="M.47.48a1.641 1.641 0 0 0-.471 1.16 1.67 1.67 0 0 0 .47 1.159L4.091 6.5.469 10.203A1.64 1.64 0 0 0 0 11.362a1.669 1.669 0 0 0 .47 1.158 1.6 1.6 0 0 0 1.133.48 1.572 1.572 0 0 0 1.134-.48l4.458-4.557a1.6 1.6 0 0 0 .337-.262c.3-.324.468-.754.468-1.2 0-.447-.168-.876-.468-1.2a1.646 1.646 0 0 0-.337-.264L2.736.48C2.436.173 2.027 0 1.602 0 1.177 0 .77.174.47.48Z" fill={fill ?? "currentColor"} data-sentry-element="path" data-sentry-source-file="ArrowRightIcon.jsx" />
        </svg>;
}
ArrowRightIcon.propTypes = {
  fill: PropTypes.string
};