import { chakra } from "@chakra-ui/react";
export function TikTokIcon({
  sx,
  ...rest
}) {
  return <chakra.svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width={256} height={256} xmlSpace="preserve" sx={{
    height: "50px",
    width: "auto",
    ...sx
  }} {...rest} data-sentry-element="unknown" data-sentry-component="TikTokIcon" data-sentry-source-file="TikTokIcon.jsx">
            <g style={{
      stroke: "none",
      strokeWidth: 0,
      strokeDasharray: "none",
      strokeLinecap: "butt",
      strokeLinejoin: "miter",
      strokeMiterlimit: 10,
      fill: "none",
      fillRule: "nonzero",
      opacity: 1
    }} data-sentry-element="g" data-sentry-source-file="TikTokIcon.jsx">
                <path d="M36.203 35.438v-3.51a27.092 27.092 0 0 0-3.677-.268c-15.047 0-27.289 12.244-27.289 27.291 0 9.23 4.613 17.401 11.65 22.342a27.18 27.18 0 0 1-7.328-18.58c0-14.833 11.894-26.929 26.644-27.275" style={{
        stroke: "none",
        strokeWidth: 1,
        strokeDasharray: "none",
        strokeLinecap: "butt",
        strokeLinejoin: "miter",
        strokeMiterlimit: 10,
        fill: "#00f2ea",
        fillRule: "nonzero",
        opacity: 1
      }} transform="matrix(2.81 0 0 2.81 1.407 1.407)" data-sentry-element="path" data-sentry-source-file="TikTokIcon.jsx" />
                <path d="M36.847 75.175c6.714 0 12.19-5.341 12.44-11.997l.023-59.417h10.855a20.558 20.558 0 0 1-.35-3.762H44.989l-.025 59.419c-.247 6.654-5.726 11.993-12.438 11.993-2.015.001-4-.49-5.782-1.431a12.444 12.444 0 0 0 10.103 5.195M80.441 23.93v-3.302a20.496 20.496 0 0 1-11.232-3.339 20.632 20.632 0 0 0 11.234 6.641" style={{
        stroke: "none",
        strokeWidth: 1,
        strokeDasharray: "none",
        strokeLinecap: "butt",
        strokeLinejoin: "miter",
        strokeMiterlimit: 10,
        fill: "#00f2ea",
        fillRule: "nonzero",
        opacity: 1
      }} transform="matrix(2.81 0 0 2.81 1.407 1.407)" data-sentry-element="path" data-sentry-source-file="TikTokIcon.jsx" />
                <path d="M69.209 17.286a20.537 20.537 0 0 1-5.073-13.522h-3.972a20.664 20.664 0 0 0 9.045 13.522m-36.683 29.2c-6.88.008-12.455 5.583-12.463 12.463a12.47 12.47 0 0 0 6.679 11.032 12.378 12.378 0 0 1-2.358-7.268c.007-6.88 5.582-12.457 12.463-12.465 1.284 0 2.515.212 3.677.577V35.689a27.092 27.092 0 0 0-3.677-.268c-.216 0-.429.012-.643.016v11.626a12.279 12.279 0 0 0-3.678-.577" style={{
        stroke: "none",
        strokeWidth: 1,
        strokeDasharray: "none",
        strokeLinecap: "butt",
        strokeLinejoin: "miter",
        strokeMiterlimit: 10,
        fill: "#ff004f",
        fillRule: "nonzero",
        opacity: 1
      }} transform="matrix(2.81 0 0 2.81 1.407 1.407)" data-sentry-element="path" data-sentry-source-file="TikTokIcon.jsx" />
                <path d="M80.441 23.93v11.523a35.261 35.261 0 0 1-20.627-6.633v30.13c0 15.047-12.24 27.289-27.287 27.289a27.128 27.128 0 0 1-15.639-4.947 27.21 27.21 0 0 0 19.959 8.709c15.047 0 27.289-12.242 27.289-27.287v-30.13a35.26 35.26 0 0 0 20.627 6.633v-14.83c-1.484 0-2.927-.161-4.323-.46" style={{
        stroke: "none",
        strokeWidth: 1,
        strokeDasharray: "none",
        strokeLinecap: "butt",
        strokeLinejoin: "miter",
        strokeMiterlimit: 10,
        fill: "#ff004f",
        fillRule: "nonzero",
        opacity: 1
      }} transform="matrix(2.81 0 0 2.81 1.407 1.407)" data-sentry-element="path" data-sentry-source-file="TikTokIcon.jsx" />
                <path d="M59.813 58.949v-30.13a35.252 35.252 0 0 0 20.627 6.633V23.93a20.631 20.631 0 0 1-11.232-6.644 20.664 20.664 0 0 1-9.045-13.522H49.309l-.023 59.417c-.249 6.654-5.726 11.995-12.44 11.995a12.446 12.446 0 0 1-10.102-5.194 12.471 12.471 0 0 1-6.681-11.032c.008-6.88 5.583-12.455 12.463-12.463 1.282 0 2.513.21 3.677.577V35.438c-14.75.346-26.644 12.442-26.644 27.275 0 7.173 2.787 13.703 7.328 18.58a27.125 27.125 0 0 0 15.639 4.945c15.048 0 27.287-12.242 27.287-27.289" style={{
        stroke: "none",
        strokeWidth: 1,
        strokeDasharray: "none",
        strokeLinecap: "butt",
        strokeLinejoin: "miter",
        strokeMiterlimit: 10,
        fill: "#000",
        fillRule: "nonzero",
        opacity: 1
      }} transform="matrix(2.81 0 0 2.81 1.407 1.407)" data-sentry-element="path" data-sentry-source-file="TikTokIcon.jsx" />
            </g>
        </chakra.svg>;
}