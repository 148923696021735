import PropTypes from "prop-types";
import { Box, VStack, Stack, Text, useBreakpointValue } from "@chakra-ui/react";
import { colors } from "../../../theme/foundations/colors";
import { ArrowHanddrawnRightIcon } from "@/components/icons";
export function Step({
  isCompleted,
  title,
  stepIndex,
  onClick
}) {
  const color = isCompleted ? colors.blue.darkest : colors.gray.lighter;
  const strokeWidth = useBreakpointValue({
    base: "3px",
    md: "2px"
  });
  return <>
            {stepIndex != 0 && <Box width={{
      base: "22px",
      sm: "26px",
      md: "31px"
    }}>
                    <ArrowHanddrawnRightIcon stroke={color} strokeWidth={strokeWidth} transform="translate(0, -10)" />
                </Box>}
            <VStack as="button" spacing={0} direction="column" sx={{
      flex: 1,
      color
    }} disabled={!isCompleted} onClick={onClick} data-sentry-element="VStack" data-sentry-source-file="Step.jsx">
                <Stack as="span" spacing="0" align="center" direction="row" data-sentry-element="Stack" data-sentry-source-file="Step.jsx">
                    <Text sx={{
          fontSize: "h3",
          lineHeight: 1,
          paddingX: 4,
          margin: 0,
          fontWeight: "black"
        }} data-sentry-element="Text" data-sentry-source-file="Step.jsx">
                        {stepIndex + 1}
                    </Text>
                </Stack>

                <Stack as="span" sx={{
        justifyContent: {
          base: "start",
          md: "center"
        },
        display: {
          // base: "none",
          sm: "block"
        }
      }} data-sentry-element="Stack" data-sentry-source-file="Step.jsx">
                    <Text sx={{
          fontSize: "h5",
          fontWeight: "bold",
          margin: 0
        }} data-sentry-element="Text" data-sentry-source-file="Step.jsx">
                        {title}
                    </Text>
                </Stack>
            </VStack>
        </>;
}
Step.propTypes = {
  isCompleted: PropTypes.bool,
  title: PropTypes.string.isRequired,
  stepIndex: PropTypes.number.isRequired,
  onClick: PropTypes.func
};
Step.defaultProps = {
  isCompleted: false,
  onClick: () => {}
};