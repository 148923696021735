import { useEffect } from "react";
import { useRouter } from "next/router";
import { optimizelyInit } from "@/services/client";
export function OptimizelyTag() {
  const router = useRouter();
  useEffect(() => {
    router.events.on("routeChangeComplete", optimizelyInit);
    return () => {
      router.events.off("routeChangeComplete", optimizelyInit);
    };
  }, [router.events]);
}