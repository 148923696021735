import React from "react";
import { HStack, useMediaQuery } from "@chakra-ui/react";
import { useRouter } from "next/router";
import { Radios } from "@/components/primitives/Radios";
import { DankortIcon, MasterCardIcon, MobilePayLogo, VisaIcon } from "@/components/icons";
import { Card } from "@/components/layouts/Card";
export const SelectNewPaymentProvider = ({
  onChange
}) => {
  const [isSmallerThan400] = useMediaQuery("(max-width: 400px)");
  return <Card data-sentry-element="Card" data-sentry-component="SelectNewPaymentProvider" data-sentry-source-file="SelectNewPaymentProvider.jsx">
            <Radios defaultValue="notSelected" options={[{
      value: "MOBILEPAY",
      label: "MobilePay",
      icon: <MobilePayLogo width="auto" height="22px" alt="MobilePay" />
    }, {
      value: "ADYEN",
      label: "Betalingskort",
      icon: <HStack gap={isSmallerThan400 ? 1 : 2} flexWrap="wrap" // Should only happen if user overwrites fontsize
      justifyContent="flex-end" overflow="hidden">
                                <DankortIcon width="auto" height="26px" borderRadius="4px" alt="Dankort" />
                                <VisaIcon width="auto" height="26px" borderRadius="4px" alt="Visa" />
                                <MasterCardIcon width="auto" height="26px" borderRadius="4px" alt="MasterCard" />
                            </HStack>
    }]} sx={{
      flexDirection: "column",
      mb: 0
    }} labelSx={{
      fontWeight: "black"
    }} onChange={evt => onChange(evt.target.value)} divider data-sentry-element="Radios" data-sentry-source-file="SelectNewPaymentProvider.jsx" />
        </Card>;
};