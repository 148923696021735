import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { richTextOptions } from "../richTextOptions";
import { Box, Flex, Button, Link, Heading, Text, Spinner } from "@chakra-ui/react";
import { textSpecs } from "@/theme/foundations/typography";
import { Section } from "@/components/layouts/Section";
import { Toggle } from "@/components/primitives/Toggle";
import { useState, useEffect, useCallback } from "react";
import { ProductCardSection } from "../ProductCard/ProductCardSection";
import { ProductCardPrice } from "../ProductCard/ProductCardPrice";
import { CustomSlider } from "./CustomSlider";
import { useRouter } from "next/router";
import { useStore } from "@/store";
import { Popover } from "@/components/primitives/Popover";
import { useMicrocopy } from "@/hooks/useMicrocopy";
import { handleProductClickTracking } from "@/components/modules/ProductCard/handleProductClickTracking";
export function FlexibleSubscription({
  headline,
  options,
  currentSubscription,
  defaultCountry = "eu",
  defaultNetwork = "4g",
  defaultData = null,
  context = "module",
  handleProductClick
}) {
  const [checkoutSession, setCheckoutSession, resetCheckoutSession] = useStore(state => [state.checkout.checkoutSession, state.checkout.setCheckoutSession, state.checkout.resetCheckoutSession]);
  const {
    getMicrocopy
  } = useMicrocopy();
  const router = useRouter();
  const [enableButton, setEnableButton] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [stateCountry, setStateCountry] = useState(defaultCountry.toLowerCase());
  const [stateNetwork, setStateNetwork] = useState(defaultNetwork);
  const [stateOptions, setStateOptions] = useState(options?.[stateCountry]?.[stateNetwork]);
  const [stateIndex, setStateIndex] = useState(null);
  const [currentOption, setCurrentOption] = useState(stateOptions?.[stateIndex]?.fields);
  const [loadedOnce, setLoadedOnce] = useState({
    all: false,
    country: false,
    network: false,
    data: false
  });
  const selectOption = useCallback(value => {
    setStateIndex(() => Math.min(Math.max(value, 0), stateOptions.length - 1));
  }, [stateOptions]);

  // Set initial state based on router query
  // this currently listens for Country=[EU,DK], Network=[5g,4g] and gb=[value in array]
  useEffect(() => {
    if (router.isReady && (!loadedOnce.country || !loadedOnce.network || !loadedOnce.data)) {
      if (router.query.country && !loadedOnce.country) {
        setStateCountry(router.query.country.toLowerCase());
        setLoadedOnce({
          ...loadedOnce,
          country: true
        });
      }
      if (router.query.network && !loadedOnce.network) {
        setStateNetwork(router.query.network.toLowerCase());
        setLoadedOnce({
          ...loadedOnce,
          network: true
        });
      }
      if (router.query.gb && !loadedOnce.data) {
        let dataIndex = -1;
        if (router.query.gb.toUpperCase() === "FRI") {
          dataIndex = stateOptions.findIndex(option => option.fields.data === "FRI");
        } else {
          dataIndex = stateOptions.findIndex(option => parseInt(option.fields.data) === parseInt(router.query.gb));
        }
        if (dataIndex !== -1) {
          setStateIndex(dataIndex);
          setLoadedOnce({
            ...loadedOnce,
            data: true
          });
        }
      }
    }
  }, [router, loadedOnce, stateOptions]);

  // Update stateOptions when options, stateCountry, or stateNetwork changes
  useEffect(() => {
    if (options) {
      setStateOptions(options[stateCountry]?.[stateNetwork]);
    }
  }, [options, stateCountry, stateNetwork]);
  useEffect(() => {
    if (stateIndex === null) {
      setCurrentOption(stateOptions[defaultData !== -1 ? defaultData - 1 : 0]?.fields);
      setStateIndex(defaultData !== -1 && defaultData !== null ? defaultData - 1 : 0);
    }
    if (stateIndex >= 0 && stateIndex < stateOptions.length) {
      setCurrentOption(stateOptions[stateIndex]?.fields);
    }
  }, [stateOptions, stateIndex, defaultData, context]);
  useEffect(() => {
    if (!stateOptions[stateIndex] || context === "module" || !currentSubscription) {
      return;
    }
    const {
      fields: {
        productId
      } = {}
    } = stateOptions[stateIndex];
    const currentProductExist = productId === currentSubscription;
    setEnableButton(!currentProductExist);
  }, [stateIndex, context, currentSubscription, stateOptions]);
  const textStyle = context === "module" ? "white" : "blue.darkest";
  const bgStyle = context === "module" ? "blue.darkestTransparent" : "gray.lighter";
  const tooltipActiveStyle = context === "module" ? "whiteActive" : "flexibleDark";
  const tooltipInactiveStyle = context === "module" ? "whiteInactive" : "flexibleGray";
  const microcopySet = `${context === "module" ? "global.flexible.subscription" : "mit.flexible.subscription"}`;
  const microcopyKey = type => `${context === "module" ? `open.tooltip.${type}` : `mit.tooltip.${type}`}`;
  const fallbackText = type => `Opret ${context === "module" ? "global.flexible.subscription" : "mit.flexible.subscription"} som microcopySet og ${context === "module" ? `open.tooltip.${type}` : `mit.tooltip.${type}`}`;
  function localProductClick(productItem) {
    // Reset checout session on every product click
    resetCheckoutSession();

    // Destructure to exclude stickers
    // eslint-disable-next-line no-unused-vars
    const {
      stickers,
      ...productItemWithoutStickers
    } = productItem;

    // Update checkout session
    setCheckoutSession({
      ...checkoutSession,
      productKey: productItem.optionKey,
      subscription: {
        ...productItemWithoutStickers,
        campaign: productItem.campaign?.fields || null
      },
      analytics: {
        utm_medium: router.query.utm_medium,
        utm_source: router.query.utm_source,
        utm_campaign: router.query.utm_campaign
      }
    });

    // Handle Product Click Tracking and redirect
    handleProductClickTracking(productItem);
    router.push("/checkout");
  }
  if (!options) return null;
  return <Section className="flexible-subscription" sx={{
    paddingBottom: {
      base: context === "module" ? 10 : 5,
      lg: context === "module" ? 20 : 10
    }
  }} data-sentry-element="Section" data-sentry-component="FlexibleSubscription" data-sentry-source-file="FlexibleSubscription.jsx">
            <Section.Content sx={{
      color: textStyle,
      paddingTop: {
        base: context === "module" ? 10 : 3,
        lg: context === "module" ? 20 : 5
      },
      paddingBottom: {
        base: context === "module" ? 10 : 5,
        lg: context === "module" ? 20 : 10
      },
      paddingLeft: {
        base: context === "module" ? 4 : 0,
        lg: 0
      },
      paddingRight: {
        base: context === "module" ? 4 : 0,
        lg: 0
      },
      maxWidth: {
        lg: "container.sm",
        xl: "container.md",
        "2xl": "container.md"
      },
      minWidth: {
        md: "0"
      }
    }} data-sentry-element="unknown" data-sentry-source-file="FlexibleSubscription.jsx">
                <Heading as={context === "module" ? "h2" : "h3"} sx={{
        color: textStyle,
        textAlign: "center",
        marginBottom: {
          base: 4,
          lg: 8
        }
      }} data-sentry-element="Heading" data-sentry-source-file="FlexibleSubscription.jsx">
                    {headline}
                </Heading>
                <Box data-sentry-element="Box" data-sentry-source-file="FlexibleSubscription.jsx">
                    {processing && <Flex justifyContent="center" alignItems="center" sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "full",
          height: "full",
          zIndex: 99
        }}>
                        <Spinner />
                    </Flex>}
                    <Box sx={{
          transition: "opacity 0.15s linear",
          opacity: processing ? 0.5 : 1
        }} data-sentry-element="Box" data-sentry-source-file="FlexibleSubscription.jsx">
                        <Flex alignItems={"center"} justifyContent={"center"} marginBottom={{
            base: 5,
            md: 8
          }} pt={4} data-sentry-element="Flex" data-sentry-source-file="FlexibleSubscription.jsx">
                            <Button variant="unstyled" sx={{
              fontSize: textSpecs.h3.fontSize,
              fontWeight: 900,
              color: stateCountry === "eu" ? bgStyle : textStyle,
              transition: "all 0.15s linear",
              mr: 5
            }} onClick={() => setStateCountry("dk")} data-sentry-element="Button" data-sentry-source-file="FlexibleSubscription.jsx">
                                DK
                                <Box sx={{
                pos: "absolute",
                right: -6,
                top: 0,
                height: 3.5,
                display: "flex"
              }} data-sentry-element="Box" data-sentry-source-file="FlexibleSubscription.jsx">
                                    <Popover content={getMicrocopy(microcopySet, microcopyKey("dk"), {
                  fallback: fallbackText("dk")
                })} size="XS" variant={stateCountry !== "dk" ? tooltipInactiveStyle : tooltipActiveStyle} sx={{
                  mt: 0
                }} data-sentry-element="Popover" data-sentry-source-file="FlexibleSubscription.jsx" />
                                </Box>
                            </Button>
                            <Box sx={{
              marginInline: "1rem"
            }} data-sentry-element="Box" data-sentry-source-file="FlexibleSubscription.jsx">
                                <Toggle variant={context === "module" ? "flexible" : "flexibleUser"} size={"xl"} isChecked={stateCountry === "eu"} labels={{
                on: "",
                off: ""
              }} setIsChecked={() => {
                setStateCountry(stateCountry === "dk" ? "eu" : "dk");
              }} data-sentry-element="Toggle" data-sentry-source-file="FlexibleSubscription.jsx" />
                            </Box>
                            <Button variant="unstyled" sx={{
              fontSize: textSpecs.h3.fontSize,
              fontWeight: 900,
              cursor: "pointer",
              color: stateCountry === "eu" ? textStyle : bgStyle,
              transition: "all 0.15s linear",
              mr: 4
            }} onClick={() => setStateCountry("eu")} data-sentry-element="Button" data-sentry-source-file="FlexibleSubscription.jsx">
                                EU
                                <Box sx={{
                pos: "absolute",
                right: -6,
                top: 0,
                height: 3.5,
                display: "flex"
              }} data-sentry-element="Box" data-sentry-source-file="FlexibleSubscription.jsx">
                                    <Popover content={getMicrocopy(microcopySet, microcopyKey("eu"), {
                  fallback: fallbackText("eu")
                })} size="XS" variant={stateCountry !== "eu" ? tooltipInactiveStyle : tooltipActiveStyle} sx={{
                  mt: 0
                }} data-sentry-element="Popover" data-sentry-source-file="FlexibleSubscription.jsx" />
                                </Box>
                            </Button>
                        </Flex>
                        <Flex alignItems={"center"} justifyContent={"center"} mb={9} data-sentry-element="Flex" data-sentry-source-file="FlexibleSubscription.jsx">
                            <Button variant="unstyled" sx={{
              fontSize: textSpecs.h3.fontSize,
              fontWeight: 900,
              cursor: "pointer",
              color: stateNetwork === "5g" ? bgStyle : textStyle,
              transition: "all 0.15s linear",
              mr: 5
            }} onClick={() => setStateNetwork("4g")} data-sentry-element="Button" data-sentry-source-file="FlexibleSubscription.jsx">
                                4G
                                <Box sx={{
                pos: "absolute",
                right: -6,
                top: 0,
                height: 3.5,
                display: "flex"
              }} data-sentry-element="Box" data-sentry-source-file="FlexibleSubscription.jsx">
                                    <Popover content={getMicrocopy(microcopySet, microcopyKey("4g"), {
                  fallback: fallbackText("4g")
                })} size="XS" variant={stateNetwork !== "4g" ? tooltipInactiveStyle : tooltipActiveStyle} sx={{
                  mt: 0
                }} data-sentry-element="Popover" data-sentry-source-file="FlexibleSubscription.jsx" />
                                </Box>
                            </Button>
                            <Box sx={{
              marginInline: "1rem"
            }} data-sentry-element="Box" data-sentry-source-file="FlexibleSubscription.jsx">
                                <Toggle variant={context === "module" ? "flexible" : "flexibleUser"} size={"xl"} isChecked={stateNetwork === "5g"} labels={{
                on: "",
                off: ""
              }} setIsChecked={() => setStateNetwork(stateNetwork === "4g" ? "5g" : "4g")} data-sentry-element="Toggle" data-sentry-source-file="FlexibleSubscription.jsx" />
                            </Box>
                            <Button variant="unstyled" sx={{
              fontSize: textSpecs.h3.fontSize,
              position: "relative",
              fontWeight: 900,
              cursor: "pointer",
              color: stateNetwork === "5g" ? textStyle : bgStyle,
              transition: "all 0.15s linear",
              mr: 4
            }} onClick={() => setStateNetwork("5g")} data-sentry-element="Button" data-sentry-source-file="FlexibleSubscription.jsx">
                                5G
                                <Box sx={{
                pos: "absolute",
                right: -6,
                top: 0,
                height: 3.5,
                display: "flex"
              }} data-sentry-element="Box" data-sentry-source-file="FlexibleSubscription.jsx">
                                    <Popover content={getMicrocopy(microcopySet, microcopyKey("5g"), {
                  fallback: fallbackText("5g")
                })} size="XS" variant={stateNetwork !== "5g" ? tooltipInactiveStyle : tooltipActiveStyle} sx={{
                  mt: 0
                }} data-sentry-element="Popover" data-sentry-source-file="FlexibleSubscription.jsx" />
                                </Box>
                            </Button>
                        </Flex>
                        {currentOption && !!currentOption.data && <Flex m={"auto"} mb={{
            base: "0.5rem"
          }} alignItems={"end"} justifyContent={"space-between"} width={{
            base: "calc(100% - 6rem)",
            md: "calc(85% - 7rem)"
          }}>
                                <Flex alignItems={"end"} justifyContent={"center"} sx={{
              color: textStyle,
              fontWeight: "black"
            }}><Text sx={{
                mb: 0,
                mr: 2,
                fontSize: {
                  base: "2.5rem",
                  md: "3rem"
                },
                lineHeight: "0.9"
              }}>{currentOption.data}</Text><Text sx={{
                fontSize: {
                  base: "0.8rem",
                  md: "1rem"
                },
                lineHeight: {
                  base: "0.5rem",
                  md: "1rem"
                }
              }}>{currentOption.data !== "FRI" ? "GB" : ""} DATA</Text></Flex>
                                {stateCountry === "eu" && <Box sx={{
              color: "yellow",
              fontWeight: "black",
              fontSize: {
                base: "0.8rem",
                md: "1rem"
              },
              lineHeight: {
                base: "1.5rem",
                md: "1rem"
              }
            }}>{currentOption.euData} GB I EU</Box>}
                            </Flex>}
                        <CustomSlider context={context} stateIndex={stateIndex} options={stateOptions} callback={value => selectOption(value)} data-sentry-element="CustomSlider" data-sentry-source-file="FlexibleSubscription.jsx" />
                        {currentOption && !!currentOption.subscriptionDescription && <Box m={"auto"} width={{
            base: "calc(100% - 7rem)",
            md: "calc(85% - 8rem)"
          }} mt={{
            base: "1rem"
          }}>
                                {documentToReactComponents(currentOption.subscriptionDescription, richTextOptions)}
                            </Box>}
                        <Box sx={{
            m: "auto",
            textAlign: "center",
            maxWidth: {
              base: "grid.columns.3"
            }
          }} data-sentry-element="Box" data-sentry-source-file="FlexibleSubscription.jsx">
                            {currentOption && <ProductCardSection canFlex>
                                    <Text fontSize={"0.75rem"} fontWeight={900} lineHeight={textSpecs.h3.lineHeight} pb={0} pt={4}>
                                        {!!currentOption.campaignDescription && currentOption.campaignDescription}
                                    </Text>
                                    <ProductCardPrice price={currentOption.campaign ? currentOption.campaign.fields.campaignPrice : currentOption.price || 59} priceBefore={currentOption.campaign ? currentOption.price : null} priceBeforeColor={textStyle} includePriceUnit={false} includeCustomPriceUnit={true} sx={{
                mt: 3,
                color: "blue.darkest",
                textAlign: "center",
                fontSize: {
                  base: "4rem",
                  md: "4.5rem"
                }
              }} />
                                    <Text variant="productCardSmallBlue" sx={{
                marginTop: 2,
                fontSize: "0.75rem",
                marginBottom: 0,
                color: "blue.darkest"
              }}>
                                        {currentOption.textUnderPrice || "PR: Måned"}
                                    </Text>
                                </ProductCardSection>}
                        </Box>
                    </Box>
                    <Flex alignItems={"center"} justifyContent={"center"} data-sentry-element="Flex" data-sentry-source-file="FlexibleSubscription.jsx">
                        <Button data-test-id="product-item-selector" type="button" variant={!enableButton ? "disabled" : "action"} isDisabled={!enableButton || processing} sx={{
            fontWeight: "black",
            display: "flex",
            whiteSpace: "nowrap"
          }} onClick={() => {
            if (enableButton && !processing) {
              if (context === "user") {
                handleProductClick({
                  ...currentOption,
                  itemCategory: "Flex"
                });
              } else {
                setProcessing(true);
                localProductClick({
                  ...currentOption,
                  itemCategory: "Flex"
                });
              }
            }
          }} data-sentry-element="Button" data-sentry-source-file="FlexibleSubscription.jsx">
                            {getMicrocopy(microcopySet, context === "user" ? "mit.flex.cta" : "open.flex.cta", {
              fallback: "Bestil nu"
            })}
                        </Button>
                    </Flex>
                </Box>
                {enableButton && currentOption && !!currentOption.readMoreUrl && !!currentOption.readMoreLabel && <Box sx={{
        transition: "opacity 0.15s linear",
        opacity: processing ? 0 : 1
      }}>
                        <Flex alignItems={"center"} justifyContent={"center"}>
                            <Link href={currentOption.readMoreUrl} sx={{
            fontWeight: 900,
            fontSize: "14px",
            textDecoration: "none",
            marginTop: "0.5rem"
          }}>
                                {currentOption.readMoreLabel}
                            </Link>
                        </Flex>
                    </Box>}
            </Section.Content>
        </Section>;
}