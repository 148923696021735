/**
 * Get the device from useragent and return a login_hint
 * @see https://docs.criipto.com/verify/guides/appswitch/
 * @returns {string} login_hint
 */
export function criiptoLoginHint(enabled) {
    let userAgent = window?.navigator?.userAgent;

    if (!userAgent || !enabled) {
        return "target:web";
    }

    userAgent = userAgent.toLowerCase();

    if (userAgent.includes("crios")) {
        return "target:web";    // Disable app on chrome ios
    }

    if (userAgent.includes("android")) {
        return "appswitch:android target:web";
    }
    else if (userAgent.includes("iphone") || userAgent.includes("ipad;")) {
        return "appswitch:ios target:web";
    }
    return "target:web";
}
