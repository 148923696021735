import PropTypes from "prop-types";
import { Modal as ChakraModal, ModalBody as ChakraModalBody, ModalFooter as ChakraModalFooter, ModalHeader, ModalContent, ModalOverlay, ModalCloseButton } from "@chakra-ui/react";
import { CloseIcon } from "@/components/icons";
function Modal({
  title,
  isOpen,
  onClose,
  children,
  closeable = true,
  ...rest
}) {
  return <ChakraModal isCentered motionPreset="slideInBottom" onClose={onClose} isOpen={isOpen} closeOnOverlayClick={closeable} closeOnEsc={closeable} {...rest} data-sentry-element="ChakraModal" data-sentry-component="Modal" data-sentry-source-file="Modal.jsx">
            <ModalOverlay data-sentry-element="ModalOverlay" data-sentry-source-file="Modal.jsx" />

            <ModalContent data-sentry-element="ModalContent" data-sentry-source-file="Modal.jsx">
                {closeable && <ModalCloseButton _focus={{
        boxShadow: "none"
      }}>
                        <CloseIcon color={null} sx={{
          margin: 1
        }} />
                    </ModalCloseButton>}

                {title && <ModalHeader mb={4}>{title}</ModalHeader>}
                {children}
            </ModalContent>
        </ChakraModal>;
}
Modal.propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node
};
Modal.defaultProps = {
  isOpen: false
};
function ModalBody({
  children,
  ...rest
}) {
  return <ChakraModalBody {...rest} data-sentry-element="ChakraModalBody" data-sentry-component="ModalBody" data-sentry-source-file="Modal.jsx">
            {children}
        </ChakraModalBody>;
}
ModalBody.propTypes = {
  children: PropTypes.node
};
function ModalFooter({
  children,
  ...rest
}) {
  return <ChakraModalFooter {...rest} data-sentry-element="ChakraModalFooter" data-sentry-component="ModalFooter" data-sentry-source-file="Modal.jsx">
            {children}
        </ChakraModalFooter>;
}
ModalFooter.propTypes = {
  children: PropTypes.node
};
Modal.Body = ModalBody;
Modal.Footer = ModalFooter;
export { Modal };