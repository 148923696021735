import { useState, useRef } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { apiFlexii } from "@/services/client";
import { Input } from "@/components/primitives/Input";
import { Radios } from "@/components/primitives/Radios";
import { Tooltip } from "@/components/primitives/Tooltip";
import { Modal } from "@/components/primitives/Modals";
import { Box, Button, Checkbox, Flex, Text } from "@chakra-ui/react";
import { textSpecs } from "@/theme/foundations/typography";
import { ExclamationPointIcon } from "@/components/icons";

/**
 *  Creates an input form for user to enter the existing simcard number that need to be ported to Flexii
 */
export function IccForm({
  subscriptionId,
  msisdn,
  showWarning,
  missingOldIcc,
  invalidOldIcc,
  mitFlexii = false
}) {
  const iccRef = useRef();
  const [iccSubmitted, setIccSubmitted] = useState(false);
  const [apiError, setApiError] = useState(null);
  const {
    register,
    setError,
    handleSubmit,
    formState: {
      errors,
      isValid
    }
  } = useForm({
    resolver: yupResolver(yup.object({
      icc: yup.string().label("Simkortnummer").required().matches(/^[0-9]{11,21}$/, "Dit simkortnummer er mellem 11 og 21 cifre. Du må kun bruge tal"),
      instant: yup.boolean(),
      terms: yup.boolean().oneOf([true], "Du mangler at acceptere vores betingelser")
    }))
  });
  const onSubmit = formData => {
    const {
      icc,
      instant
    } = formData;
    apiFlexii.put(`v1/protected/resource/set-icc?subscriptionId=${subscriptionId}&old_icc=${icc}&msisdn=${msisdn}&instant=${instant}`, {}).then(() => setIccSubmitted(true)).catch(error => {
      if (error.response?.data?.errorCode === 10) {
        setError("icc", {
          message: "Det indtastede simkortnummer er ikke korrekt"
        });
      } else {
        setApiError("Dit simkortnummer kunne ikke registreres, prøv igen");
      }
    });
  };
  return <Box data-sentry-element="Box" data-sentry-component="IccForm" data-sentry-source-file="IccForm.jsx">
            {iccSubmitted ? <Box sx={{
      marginBottom: 12
    }}>
                    <Text as="h4" sx={{
        fontWeight: "black",
        marginBottom: 4
      }}>
                        Sådan der!
                    </Text>
                    <Text as="h4" sx={{
        marginBottom: 4
      }}>
                        Dit simkortnummer er registreret og vi igangsætter
                        nummerflytningen mettevuns!
                    </Text>
                </Box> : <form onSubmit={handleSubmit(onSubmit)}>
                    {!!showWarning && <Flex sx={{
        flexDirection: "row"
      }}>
                            <ExclamationPointIcon />
                            <Text as="h4" sx={{
          color: "red",
          marginLeft: 2
        }}>
                                Indtast simkortnummer
                            </Text>
                        </Flex>}
                    <Text as="span" variant="cardCopy">
                        {missingOldIcc && "Vi mangler dit simkortnummer!"}{" "}
                        {!mitFlexii && <Tooltip label="Et simkortnummer starter typisk med 8945- og er mellem 11-20 cifre. Find dit simkortnummer i din mobils indstillinger, direkte på dit simkort eller hiv fat i dit nuværende mobilselskab." />}
                        {invalidOldIcc && "Din nummerflytning er afvist"}
                    </Text>
                    <Box sx={{
        marginBottom: 12
      }}>
                        <Text fontWeight="bold">
                            {missingOldIcc && !mitFlexii && `For at vi kan flytte dit mobilnummer, skal vi bruge dit nuværende simkortnummer. Sidder du ikke lige med dit simkortnummer, så kan du sagtens indtaste det på Mit Flexii senere.
                                    `}
                            {invalidOldIcc && "Din nummerflytning er afvist fordi dit nummer og simkortnummer ikke stemmer overens! Så – hvis dit mobilnummer er korrekt indtastet, så tjek lige dit simkortnummer en ekstra gang eller hiv lige fat i dit nuværende teleselskab for at få det oplyst og smid det herind. Så kan vi få genstartet din nummerflytning!"}
                        </Text>
                        <Input type="number" ref={iccRef} label={mitFlexii ? "For at vi kan flytte dit mobilnummer, skal vi bruge dit nuværende simkortnummer" : ""} placeholder="Simkortnummer" {...register("icc")} errorMessage={errors?.icc?.message} tooltip={mitFlexii ? "Et simkortnummer starter typisk med 8945- og er mellem 11-20 cifre. Find dit simkortnummer i din mobils indstillinger, direkte på dit simkort eller hiv fat i dit nuværende mobilselskab." : null} sx={{
          marginBottom: 2,
          maxWidth: "475px",
          fontSize: {
            base: textSpecs.h2.fontSize.l
          }
        }} className="mask-me" />

                        <Box sx={{
          marginBottom: 4
        }}>
                            <Radios {...register("instant")} defaultValue={"false"} options={[{
            value: "false",
            label: "Efter bindings-/opsigelsesperioden hos mit nuværende mobilselskab",
            tooltip: "Vi flytter dit nummer så snart din opsigelses- og/eller bindingsperiode udløber hos dit nuværende teleselskab"
          }, {
            value: "true",
            label: "Flyt nummeret hurtigst muligt",
            tooltip: <>
                                                Vi flytter dit nummer så hurtigt
                                                det kan lade sig gøre. Dog
                                                tidligst efter 5 hverdage, så du
                                                kan nå at modtage dit simkort
                                                fra os.
                                                <br />
                                                Husk, at du kan blive
                                                restopkrævet fra dit nuværende
                                                teleselskab, hvis dit nummer
                                                flytter inden din
                                                opsigelsesperiode er udløbet
                                            </>
          }]} sx={{
            flexDirection: "column"
          }} labelSx={{
            fontWeight: "black"
          }} />

                            <Checkbox isInvalid={errors?.terms ?? null} {...register("terms")} sx={{
            fontWeight: "black"
          }}>
                                Jeg giver hermed Flexii lov til at flytte mit
                                nummer.
                                <Tooltip label="Vi har brug for din fuldmagt til at flytte nummeret" size="small" />
                            </Checkbox>
                        </Box>
                        <Button type="submit" isDisabled={!isValid} width="270px">
                            Send
                        </Button>
                    </Box>
                </form>}

            {!!apiError && <Modal title="For Flexii da – der skete en fejl!" isOpen={!!apiError} onClose={() => setApiError(null)}>
                    <Modal.Body>{apiError}</Modal.Body>
                </Modal>}
        </Box>;
}