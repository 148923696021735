import { useState, useEffect } from "react";
import { useRouter } from "next/router";
import NextLink from "next/link";
import { Button, useBreakpointValue, Container, Box, Heading, Text, Flex, Spinner } from "@chakra-ui/react";
import { ArrowLeftIcon, ArrowRightIcon } from "../../../components/icons";
import { stepRoutes } from "../../../pages/checkout/[[...step]]";
import { AdyenDropInPayment } from "@/components/primitives/AdyenDropInPayment";
import { useStore } from "@/store";
import { Modal } from "@/components/primitives/Modals";
import { useMicrocopy } from "@/hooks/useMicrocopy";
import { apiFlexii } from "@/services/client";
import { SelectNewPaymentProvider } from "@/components/modules/SelectNewPaymentProvider";
export function Step3({
  trackingData
}) {
  const router = useRouter();
  const {
    getMicrocopy
  } = useMicrocopy();
  const [error, setError] = useState(null);
  const [validationError, setValidationError] = useState(null);
  const [newPaymentProvider, setNewPaymentProvider] = useState(); // undefined = select not started, notSelected = user has started selected a new provider.
  const [showAdyen, setShowAdyen] = useState(null);
  const [showSpinner, setShowSpinner] = useState(false);
  const [checkoutSession, setCheckoutSession] = useStore(state => [state.checkout.checkoutSession, state.checkout.setCheckoutSession]);
  const existingPaymentProvider = checkoutSession.paymentProvider;
  const previousStep = {
    query: {
      step: stepRoutes[1].path
    }
  }; // update only current path slug to the previous step
  const nextStep = {
    query: {
      step: stepRoutes[3].path,
      reference: null
    }
  };
  const buttonSize = useBreakpointValue({
    base: "full",
    md: ""
  });

  // Run validations checks
  useEffect(() => {
    const clientRejectMessage = <>
                Det ser ud til at vi mangler oplysninger på dig eller din ordre.
                <br />
                Start venligst forfra eller kontakt kundeservice
            </>;

    // Validate that we have all data before showing payment
    const {
      anumber,
      anumberImportRequest,
      productKey,
      subscription,
      user
    } = checkoutSession;

    // If basket is empty
    if (!productKey || !subscription) {
      setValidationError({
        internalMsg: "Missing subscription/productKey",
        clientMsg: clientRejectMessage
      });
      return;
    }

    // If no phonenumber has been selected
    if (!anumber.reservationToken && !anumberImportRequest.phoneNumber) {
      setValidationError({
        internalMsg: "Missing phonenumber",
        clientMsg: clientRejectMessage
      });
      return;
    }

    // Check if any user field is missing
    const isMissingUserFields = [user.personalId, user.firstName, user.lastName, user.streetName, user.zip, user.city, user.phone, user.email].some(field => !field);
    if (isMissingUserFields) {
      setValidationError({
        internalMsg: "Missing user field",
        clientMsg: clientRejectMessage
      });
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    // New users and users without a valid payment provider
    if (!existingPaymentProvider && !newPaymentProvider) {
      setNewPaymentProvider("notSelected");
    }
  }, [existingPaymentProvider, newPaymentProvider]);
  const handlePaymentSuccess = ({
    merchantReference,
    useExistingAgreement
  }) => {
    setCheckoutSession({
      ...checkoutSession,
      clientSidePaymentSuccess: true
    });
    setError(null);
    const nextStepWithReference = {
      query: {
        ...nextStep.query,
        reference: merchantReference //set order id reference
      }
    };
    {
      useExistingAgreement ? router.push(nextStepWithReference) : setTimeout(() => {
        router.push(nextStepWithReference);
      }, 2000); // Wait for Adyen payment confirmation animation to finish
    }
  };
  const onErrorClose = () => {
    // These errors should throw the user out of our payment flow
    setError(null);
    router.push("/");
  };
  const onValidationErrorClose = () => {
    // These errors should can be caused due to missing data. Let the user start over without deleting the cart..
    setError(null);
    router.push("/checkout");
  };

  /**
   * Push e-commerce tracking data to DataLayer
   */
  const trackPaymentSelection = () => {
    let payment_type = newPaymentProvider || existingPaymentProvider;
    payment_type = payment_type === "MOBILEPAY" ? "MobilePay" : "Credit Card";
    // Set price as either regular or campaign price
    if (trackingData?.ecommerce) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        ecommerce: null
      }); // Clear the previous ecommerce object.
      window.dataLayer.push({
        event: "add_payment_info",
        ecommerce: {
          ...trackingData.ecommerce,
          payment_type
        }
      });
    }
  };
  const handlePaymentFlow = () => async () => {
    trackPaymentSelection();
    const useExistingAgreement = existingPaymentProvider && !newPaymentProvider;
    if (useExistingAgreement) {
      // Reuse existing agreement
      setShowSpinner(true);
      apiFlexii.post("v1/protected/resource/payment/signup-with-exisiting-ticket", {
        ...checkoutSession
      }).then(({
        data
      }) => {
        handlePaymentSuccess({
          ...data,
          useExistingAgreement: useExistingAgreement
        });
      }).catch(error => {
        setShowSpinner(false);
        setError(error.message);
      });
    } else if (newPaymentProvider === "MOBILEPAY") {
      // New MobilePay agreement
      setShowSpinner(true);
      apiFlexii.post("v1/protected/resource/payment/create-agreement", {
        ...checkoutSession
      }).then(({
        data
      }) => {
        setCheckoutSession({
          ...checkoutSession,
          clientSidePaymentSuccess: true
        });
        router.push(data.confirmationUrl);
      }).catch(error => {
        setShowSpinner(false);
        setError(error.message);
      });
    } else if (newPaymentProvider === "ADYEN") {
      // New Adyen agreement
      setShowAdyen(true);
    } else {
      // Provider not selected
    }
  };
  const getLayout = () => {
    const switchVar = newPaymentProvider ? "default" : existingPaymentProvider;
    switch (switchVar) {
      case "MOBILEPAY":
        return <Text sx={{
          fontWeight: "black"
        }}>
                        {getMicrocopy("checkout.step3", "mobilepay.betalingsoplysninger", {
            fallback: <>
                                        Du er allerede tilmeldt med{" "}
                                        <i>MobilePay</i>. Gennemfør betaling for
                                        at afslutte dit køb eller vælg ny
                                        betalingsform.
                                    </>
          })}
                    </Text>;
      case "ADYEN":
        return <Text sx={{
          fontWeight: "black"
        }}>
                        {getMicrocopy("checkout.step3", "adyen.betalingsoplysninger", {
            fallback: <>
                                        Du er allerede tilmeldt med{" "}
                                        <i>
                                            betalingskort
                                            <Flex as="span" display="inline-flex">
                                                {checkoutSession.creditCardDigits && ` ****${checkoutSession.creditCardDigits}`}
                                                .
                                            </Flex>
                                        </i>{" "}
                                        Gennemfør betaling for at afslutte dit
                                        køb eller vælg ny betalingsform.
                                    </>,
            replacements: {
              kortcifre: checkoutSession.creditCardDigits
            }
          })}
                    </Text>;
      default:
        return <>
                        <Text sx={{
            fontWeight: "black"
          }}>
                            {getMicrocopy("checkout.step3", "indtast.betalingsoplysninger", {
              fallback: `Vælg mellem betalingskort eller MobilePay. Vi gemmer dine oplysninger til dine fremtidige betalinger. `
            })}
                        </Text>
                        <Box sx={{
            marginTop: 6
          }}>
                            {showAdyen ? <AdyenDropInPayment payload={checkoutSession} onPaymentSuccess={handlePaymentSuccess} onError={error => setError(error.message)} /> : <SelectNewPaymentProvider onChange={setNewPaymentProvider} />}
                        </Box>
                    </>;
    }
  };
  return <Container maxWidth="container.sm" sx={{
    padding: 0
  }} data-sentry-element="Container" data-sentry-component="Step3" data-sentry-source-file="Step3.jsx">
            <Heading as="h3" sx={{
      marginBottom: 2
    }} data-sentry-element="Heading" data-sentry-source-file="Step3.jsx">
                {getMicrocopy("checkout.step3", "betalingstid", {
        fallback: "Så skal der betales"
      })}
            </Heading>
            {getLayout()}

            <Flex sx={{
      justifyContent: "center",
      gap: 5,
      flexWrap: "wrap",
      marginTop: 8,
      gridTemplateColumns: [null, null, "repeat(3, 1fr)"],
      flexDirection: existingPaymentProvider && !newPaymentProvider ? "column" : ["column", null, "row"]
    }} data-sentry-element="Flex" data-sentry-source-file="Step3.jsx">
                {/* Hide back button if user already has a payment provider, show if they're changing it. */}
                {(!existingPaymentProvider || newPaymentProvider) && <Button variant="ghost" onClick={() => {
        if (showAdyen) {
          setNewPaymentProvider("notSelected");
          setShowAdyen(false);
        } else if (newPaymentProvider && newPaymentProvider !== "notSelected") {
          setNewPaymentProvider();
        } else {
          setNewPaymentProvider();
          router.push(previousStep);
        }
      }} size={buttonSize} leftIcon={<ArrowLeftIcon />} sx={{
        margin: 0,
        order: {
          base: 2,
          md: 0
        },
        // Back is last on mobile but first(left) on desktop,
        paddingX: {
          base: 14,
          xl: 8,
          "2xl": 12
        }
      }}>
                        {getMicrocopy("global", "tilbage", {
          fallback: "Tilbage"
        })}
                    </Button>}

                {!showAdyen &&
      // Adyen has it's own continue button
      <Button type="submit" variant={newPaymentProvider === "notSelected" // started selecting new provider, but not selected yet
      ? "disabled" : "action"} onClick={handlePaymentFlow()} size={buttonSize} rightIcon={!showSpinner && <ArrowRightIcon />} sx={{
        margin: 0,
        order: {
          base: 0,
          md: 1
        },
        // Submit is first on mobile but second(right) on desktop
        paddingX: {
          base: 10,
          xl: 8,
          "2xl": 8
        },
        minWidth: "200px"
      }} data-test-id="button_continue-to-payment">
                        {showSpinner ? <Spinner /> : existingPaymentProvider && !newPaymentProvider ? getMicrocopy("checkout.step3", "gennemfør.betaling", {
          fallback: "Gennemfør betaling"
        }) : getMicrocopy("global", "fortsæt", {
          fallback: "Fortsæt"
        })}
                    </Button>}
                {existingPaymentProvider && !newPaymentProvider && <Button type="submit" variant="anchorLink" onClick={() => setNewPaymentProvider("notSelected")} size={buttonSize} rightIcon={<ArrowRightIcon />} sx={{
        margin: 0,
        order: {
          base: 1,
          md: 3
        } // New payment is second on mobile but last(under) on desktop
      }} color={"blue.dark"} data-test-id="button_continue-to-payment">
                        {getMicrocopy("checkout.step3", "tilmeld.betaling", {
          fallback: "Tilmeld ny betalingsform"
        })}
                    </Button>}
            </Flex>

            <Modal title={getMicrocopy("global", "ukendtFejl", {
      fallback: "For Flexii da – der skete en fejl!"
    })} isOpen={!!error} onClose={onErrorClose} data-sentry-element="Modal" data-sentry-source-file="Step3.jsx">
                <Modal.Body data-sentry-element="unknown" data-sentry-source-file="Step3.jsx">{error}</Modal.Body>
            </Modal>

            {!!validationError &&
    // Validation has failed.. Let the user start checkout over after closing
    <Modal title={getMicrocopy("global", "ukendtFejl", {
      fallback: "For Flexii da – der skete en fejl!"
    })} isOpen={!!error} onClose={onValidationErrorClose}>
                    <Modal.Body>{validationError?.clientMsg}</Modal.Body>
                    <Modal.Footer>
                        <Button as={NextLink} href="/checkout" variant="action">
                            {getMicrocopy("checkout.step3", "startForfra", {
            fallback: "Start forfra"
          })}
                        </Button>
                    </Modal.Footer>
                </Modal>}
        </Container>;
}