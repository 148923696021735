import PropTypes from "prop-types";
export function PdfDownloadIcon() {
  return <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" x={0} y={0} height={24} width={24} viewBox="0 0 76.5 74.6" style={{
    enableBackground: "new 0 0 76.5 74.6"
  }} xmlSpace="preserve" data-sentry-element="svg" data-sentry-component="PdfDownloadIcon" data-sentry-source-file="PdfDownloadIcon.jsx">
            <style>{".st0{fill:#168ddd}"}</style>
            <path className="st0" d="M23.4 16.8h-7.5v15.4h3.9v-4.6h3.6c3 0 5.4-2.4 5.4-5.4s-2.4-5.4-5.4-5.4zm0 6.9h-3.6v-3h3.6c.8 0 1.5.7 1.5 1.5 0 .9-.7 1.5-1.5 1.5zM62.4 20.7v-3.9H50.9v15.4h3.9v-4.6h5.6v-3.9h-5.6v-3zM38.9 16.8h-5.5v15.3h5.5c4.2 0 7.7-3.4 7.7-7.7-.1-4.1-3.5-7.6-7.7-7.6zm0 11.5h-1.6v-7.5h1.6c2.1 0 3.8 1.7 3.8 3.8-.1 2-1.7 3.7-3.8 3.7z" data-sentry-element="path" data-sentry-source-file="PdfDownloadIcon.jsx" />
            <path className="st0" d="M69.7 0H6.8C3.1 0 0 3.1 0 6.8v35.4C0 45.9 3.1 49 6.8 49h28.3v14.8L30.3 59c-1.2-1.2-3.2-1.2-4.4 0-1.2 1.2-1.2 3.2 0 4.4L36 73.7c.1.1.3.3.5.4.5.3 1.1.5 1.7.5.6 0 1.2-.2 1.7-.5.2-.1.3-.2.5-.4l10.2-10.2c1.2-1.2 1.2-3.2 0-4.4s-3.2-1.2-4.4 0l-4.8 4.8V49.1h28.4c3.8 0 6.8-3.1 6.8-6.8V6.8C76.5 3.1 73.4 0 69.7 0zm-.2 42H7V7h62.5v35z" data-sentry-element="path" data-sentry-source-file="PdfDownloadIcon.jsx" />
        </svg>;
}
PdfDownloadIcon.propTypes = {
  fill: PropTypes.string
};