import { useRef, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import PropTypes from "prop-types";
import { Container, Divider, Flex, Grid, GridItem, Heading, HStack, SimpleGrid, Text } from "@chakra-ui/react";
import { Section } from "../../layouts/Section";
import { Tooltip } from "@/components/primitives/Tooltip";

/**
 * PriceOverview Component for Checkout Pages
 * Sticky = PriceOverview || Non-sticky = OrderSummary
 *
 * In order to reserve space in the bottom of the page for the "sticky" version, so that
 * the user's can scroll all the way to the bottom, we use React's Portal technique to
 * duplicate the component at the bottom of the page, but with "visibility=hidden".
 * @see https://reactjs.org/docs/portals.html
 * @see https://github.com/vercel/next.js/tree/canary/examples/with-portals
 * @see https://stackoverflow.com/a/59154364
 */
export function PriceOverview({
  isSticky,
  phoneNumber,
  subscription,
  sx
}) {
  const ref = useRef();
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    ref.current = document.querySelector("#portal");
    setMounted(true);
  }, []);
  if (!subscription) {
    return null;
  }
  const totalPrice = subscription.campaign ? subscription.campaign.campaignPrice : subscription.price;
  const priceExtension = "kr./md";
  const renderOverview = (visibility = "visible") => {
    return <Section className="price-overview" sx={{
      width: "full",
      ...(isSticky ? {
        position: visibility === "visible" ? "fixed" : "relative",
        bottom: 0,
        right: 0,
        left: 0,
        background: "blue.darkest",
        color: "white"
      } : {
        background: "gray.lighter",
        color: "text",
        borderRadius: "card"
      }),
      visibility,
      ...sx
    }} data-sentry-element="Section" data-sentry-component="renderOverview" data-sentry-source-file="PriceOverview.jsx">
                <Container sx={{
        paddingX: isSticky ? null : {
          base: 4,
          md: 8
        },
        maxWidth: {
          md: "container.md"
        }
      }} data-sentry-element="Container" data-sentry-source-file="PriceOverview.jsx">
                    <SimpleGrid columns={isSticky ? [0, 2] : null} rows={[0, 2]} spacing={2} sx={{
          paddingY: 7,
          fontWeight: "bold",
          lineHeight: isSticky ? "1.5rem" : null,
          "*": {
            marginBottom: 0
          },
          ...sx
        }} data-sentry-element="SimpleGrid" data-sentry-source-file="PriceOverview.jsx">
                        <Heading as="h4" sx={{
            fontWeight: "black",
            fontSize: "base"
          }} data-sentry-element="Heading" data-sentry-source-file="PriceOverview.jsx">
                            Din bestilling
                        </Heading>

                        <Flex sx={{
            flexDirection: "column"
          }} data-sentry-element="Flex" data-sentry-source-file="PriceOverview.jsx">
                            <Text sx={{
              fontWeight: "black",
              fontSize: "20px"
            }} data-sentry-element="Text" data-sentry-source-file="PriceOverview.jsx">
                                {subscription.displayName}
                            </Text>

                            {!!phoneNumber ?
            // Prevent content from jumping
            <Text className="mask-me">Tlf: {phoneNumber}</Text> : <Text> </Text>}
                        </Flex>
                        <Flex sx={{
            flexDirection: "column",
            justifyContent: "space-between"
          }} data-sentry-element="Flex" data-sentry-source-file="PriceOverview.jsx">
                            <HStack sx={{
              justifyContent: "space-between",
              marginBottom: isSticky ? 1 : 0,
              gap: 2
            }} data-sentry-element="HStack" data-sentry-source-file="PriceOverview.jsx">
                                <Text data-sentry-element="Text" data-sentry-source-file="PriceOverview.jsx">Pris pr. måned </Text>
                                <Text sx={{
                position: "relative"
              }} data-sentry-element="Text" data-sentry-source-file="PriceOverview.jsx">
                                    {!!subscription.campaign && <>
                                            <Text as="span" sx={{
                    display: "inline-block",
                    marginRight: 2,
                    fontWeight: "normal",
                    textDecoration: "line-through"
                  }}>
                                                {subscription.price}{" "}
                                                {priceExtension}
                                            </Text>{" "}
                                        </>}
                                    {totalPrice}
                                    {subscription.campaign ? " kr." : ` ${priceExtension}`}
                                </Text>
                            </HStack>

                            {!!subscription.campaign && <Text sx={{
              fontSize: "h5"
            }}>
                                    {subscription.campaign.priceDescription}
                                </Text>}

                            <Divider sx={{
              borderColor: "blue.darkest",
              opacity: 0.1
            }} data-sentry-element="Divider" data-sentry-source-file="PriceOverview.jsx" />

                            <HStack sx={{
              justifyContent: "space-between",
              gap: 2
            }} data-sentry-element="HStack" data-sentry-source-file="PriceOverview.jsx">
                                <Text data-sentry-element="Text" data-sentry-source-file="PriceOverview.jsx">Oprettelse</Text>
                                <Text data-sentry-element="Text" data-sentry-source-file="PriceOverview.jsx">0 kr.</Text>
                            </HStack>
                            <HStack sx={{
              justifyContent: "space-between",
              fontWeight: "black",
              color: isSticky && "yellow",
              gap: 2
            }} data-sentry-element="HStack" data-sentry-source-file="PriceOverview.jsx">
                                <Text sx={{
                fontSize: "h4"
              }} data-sentry-element="Text" data-sentry-source-file="PriceOverview.jsx">Betales nu</Text>
                                <Text sx={{
                fontSize: "h4"
              }} data-sentry-element="Text" data-sentry-source-file="PriceOverview.jsx">0 kr.</Text>
                            </HStack>
                            <HStack sx={{
              justifyContent: "space-between",
              gap: 2
            }} data-sentry-element="HStack" data-sentry-source-file="PriceOverview.jsx">
                                <Flex data-sentry-element="Flex" data-sentry-source-file="PriceOverview.jsx">
                                    <Text data-sentry-element="Text" data-sentry-source-file="PriceOverview.jsx">Mindstepris</Text>
                                    <Tooltip size="small" sx={{
                  padding: 6
                }} label={<>
                                                <Heading as="h5" textStyle="h4">
                                                    Samlet mindstepris
                                                </Heading>
                                                <Grid sx={{
                    gridTemplateColumns: "auto auto",
                    columnGap: 6
                  }}>
                                                    <GridItem>
                                                        <Text>
                                                            Abonnement 1 x{" "}
                                                            {totalPrice} kr.
                                                        </Text>
                                                    </GridItem>
                                                    <GridItem sx={{
                      textAlign: "right"
                    }}>
                                                        <Text>
                                                            {totalPrice} kr.
                                                        </Text>
                                                    </GridItem>

                                                    <GridItem>
                                                        <Text>Oprettelse</Text>
                                                    </GridItem>
                                                    <GridItem sx={{
                      textAlign: "right"
                    }}>
                                                        <Text>0 kr.</Text>
                                                    </GridItem>

                                                    <GridItem>
                                                        <Text>Mindstepris</Text>
                                                    </GridItem>
                                                    <GridItem sx={{
                      textAlign: "right"
                    }}>
                                                        <Text>
                                                            <b>
                                                                {totalPrice} kr.
                                                            </b>
                                                        </Text>
                                                    </GridItem>
                                                </Grid>
                                            </>} data-sentry-element="Tooltip" data-sentry-source-file="PriceOverview.jsx" />
                                </Flex>
                                <Text data-sentry-element="Text" data-sentry-source-file="PriceOverview.jsx">{totalPrice} kr.</Text>
                            </HStack>
                        </Flex>
                    </SimpleGrid>
                </Container>
            </Section>;
  };
  return <>
            {mounted ? renderOverview() : isSticky ? createPortal(renderOverview("hidden"), ref.current) : null}
        </>;
}
PriceOverview.propTypes = {
  isSticky: PropTypes.bool,
  phoneNumber: PropTypes.string,
  subscription: PropTypes.object,
  sx: PropTypes.object
};
PriceOverview.defaultProps = {
  isSticky: false
};