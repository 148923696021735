import { contentfulApi } from "@/services/server";
import { Layout } from "@/components/layouts/Layout";
import { getModuleComponent } from "@/components/modules/getModuleComponent";
export default function Home({
  data
}) {
  return <Layout {...data} title={data?.displayTitle || data?.seoTitle} seoTitle={data?.seoTitle} seoDescription={data?.seoDescription} data-sentry-element="Layout" data-sentry-component="Home" data-sentry-source-file="index.jsx">
            {/* Map components from Contentful Models. */}
            {data?.modules?.map(getModuleComponent)}
        </Layout>;
}
export const getStaticProps = async ({
  preview
}) => {
  const client = contentfulApi(preview);

  // TODO: Once we know the specific ID of the frontpage, lets use getEntry instead to avoid fetching big amount of unnessesary data
  const frontpage = await client.getEntries({
    content_type: "standardPage",
    include: 10 // Specify the amount of nested levels to retrieve. 10 is the max number - only available on getEntries and not single entry
  }).then(pages => pages.items.find(page => page?.fields.slug === "/"));
  const globalSettings = await client.getEntries({
    content_type: "globalSettings",
    include: 4,
    limit: 1
  }).then(response => response.items[0].fields).catch(console.error);
  const header = await client.getEntries({
    content_type: "header",
    include: 4 // Specify the amount of nested levels to retrieve. 10 is the max number - only available on getEntries and not single entry
  });
  const footer = await client.getEntries({
    content_type: "footer",
    include: 4
  });
  const microcopySets = [...globalSettings.microcopy];
  return {
    revalidate: 60,
    // Every minute
    props: {
      data: {
        ...frontpage?.fields,
        header: header.items[0],
        footer: footer.items[0],
        microcopySets
      }
    }
  };
};

/**
 * Standalone Docker workaround
 * In progress
 */
// const alt = [
//     "knex",
//     "tildify",
//     "tarn",
//     "resolve-from",
//     "rechoir",
//     "resolve",
//     "supports-preserve-symlinks-flag",
//     "path-parse",
//     "is-core-module",
//     "has",
//     "function-bind",
//     "pg-connection-string",
//     "lodash",
//     "interpret",
//     "getopts",
//     "get-package-type",
//     "esm",
//     "escalade",
//     "debug",
//     "ms",
//     "commander",
//     "colorette",
// ];
// export const config = {
//     unstable_includeFiles: alt.map(
//         (dep) => `node_modules/${dep}/**/*.+(js|json)`
//     ),
// };
// console.log("MUUUUHHAAAA", config.unstable_includeFiles);