import PropTypes from "prop-types";
import { Box, Text } from "@chakra-ui/react";
const productTabPadding = 3;
const productTabHeight = 40; // px

export function ProductCardTab({
  children,
  ...rest
}) {
  return <Box className="productcard-tab" sx={{
    backgroundColor: "rgba(0,0,0, 0.2)",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    height: "100%",
    borderBottomLeftRadius: "card",
    borderBottomRightRadius: "card"
  }} {...rest} data-sentry-element="Box" data-sentry-component="ProductCardTab" data-sentry-source-file="ProductCardTab.jsx">
            <Text sx={{
      display: "flex",
      justifyContent: "center",
      gap: 2,
      position: "absolute",
      bottom: "100%",
      left: 0,
      right: 0,
      marginBottom: 0,
      padding: productTabPadding,
      height: `${productTabHeight}px`,
      fontSize: "h5",
      lineHeight: 1.36,
      fontWeight: 900,
      textTransform: "uppercase",
      background: "inherit",
      borderTopLeftRadius: "card",
      borderTopRightRadius: "card"
    }} data-sentry-element="Text" data-sentry-source-file="ProductCardTab.jsx">
                {children}
            </Text>
        </Box>;
}
ProductCardTab.propTypes = {
  children: PropTypes.node.isRequired
};