import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { shallow } from "zustand/shallow";
import { mergeDeepLeft } from "ramda";
import { immer } from "zustand/middleware/immer";

import { checkout } from "./checkout";

const localStorageStateName = "clientStorage";

const options = {
    name: localStorageStateName,
    /**
     * Custom merge function
     * Zustand stores, during a persist, handle badly nested persisted state.
     * @see https://github.com/pmndrs/zustand/blob/main/docs/integrations/persisting-store-data.md
     */
    merge: (persistedState, currentState) =>
        mergeDeepLeft(persistedState, currentState),
};

/**
 * rootStore
 * Add all substores inside here
 */
const store = persist(
    immer((set, get) => ({
        checkout: checkout(set, get),
    })),
    options
);

// We can use Redux-Devtools to inspect the state
const createStore = create(
    process.env.NODE_ENV === "development" ? devtools(store) : store
);

/**
 * @example
 *
 *     const [checkoutSession, setCheckoutSession] = useStore((state) => [
 *         state.checkout.checkoutSession,
 *         state.checkout.setCheckoutSession,
 *     ]);
 *
 */
export const useStore = (statePicks = () => []) =>
    createStore(statePicks, shallow);
