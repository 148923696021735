import { tokenReplacer } from "@/utils/tokenReplacer";
import { isEmpty } from "lodash-es";
import { Fragment, createContext, useContext } from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";

/**
 * Context with all array of microcopySets from Contentful,
 * usually global and page specific sets
 */
const MicrocopyContext = createContext(undefined);
const {
  Provider: MicrocopyProvider
} = MicrocopyContext;

/**
 * Hook with methods to retrieve microcopies from context
 */
const useMicrocopy = () => {
  const context = useContext(MicrocopyContext);
  if (context === undefined) {
    throw new Error("useMicrocopy must be within MicrocopyProvider");
  }
  /**
   * Example: getMicrocopy("mit.overblik", "hilsen", {fallback: `Hej ${profileData.firstName}`, replacements: {name: {profileData.firstName} } })
   *
   * @param {string} microcopySetKey set key from contentful, e.g. global, mit, frontpage
   * @param {string} microcopyKey specific key for finding microcopy, e.g. name.label, greeting, phone.error
   * @param {?object} options
   * @param {string} [options.fallback=""] optional fallback value if microcopyKey was not found
   * @param {object} [options.replacements=""] optional replacements e.g. { name: 'John' } would replace {{name}} from Contentful.
    */
  const getMicrocopy = (microcopySetKey, microcopyKey, options = {
    fallback: "",
    replacements: ""
  }) => {
    const {
      fallback
    } = options;
    const microcopySet = context.find(set => set?.fields?.key === microcopySetKey);
    if (!microcopySet || !microcopySet?.fields?.microcopy) {
      // console.warn(
      //     `microcopy not found in set: ${microcopySetKey} with key: ${microcopyKey}${fallback && `, using fallback: ${fallback}`
      //     }`
      // );
      return fallback;
    }
    const result = microcopySet?.fields?.microcopy?.find(microcopy => microcopy?.fields?.key === microcopyKey);
    if (result) {
      const {
        replacements
      } = options;
      let resultValue = result?.fields?.value;
      const {
        enableMarkdown
      } = result?.fields || {};
      if (!isEmpty(replacements)) {
        resultValue = tokenReplacer(resultValue, replacements);
      }
      const mdValue = resultValue.replace(/\\n/gi, "\n").replace(/\n/gi, "<br/>");
      return enableMarkdown ? <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]} components={{
        p: Fragment
      }}>
                    {mdValue}
                </ReactMarkdown> : resultValue;
    } else {
      // console.warn(
      //     `microcopy not found in set: ${microcopySetKey} with key: ${microcopyKey}${fallback && `, using fallback: ${fallback}`
      //     }`
      // );
      return fallback;
    }
  };
  return {
    getMicrocopy
  };
};
export { MicrocopyContext, MicrocopyProvider, useMicrocopy };