import { Text, VStack } from "@chakra-ui/react";
import PropTypes from "prop-types";

/**
 *
 * @param {string} headline - the headline for the user detail,
 * @param {*} children - the body for the user detail,
 * @returns a headline and children for a user detail
 */
export const UserDetailsCardItem = ({
  headline,
  children
}) => <Text sx={{
  marginBottom: 0
}} data-sentry-element="Text" data-sentry-component="UserDetailsCardItem" data-sentry-source-file="UserDetailsCard.jsx">
        <Text as="b" sx={{
    display: "block"
  }} data-sentry-element="Text" data-sentry-source-file="UserDetailsCard.jsx">
            {headline}
        </Text>

        <Text as="span" className="mask-me" data-sentry-element="Text" data-sentry-source-file="UserDetailsCard.jsx">
            {children}
        </Text>
    </Text>;

/**
 * @param {object} userDetails - the props object,
 * @param {string} userDetails.firstName - the first name of the user,
 * @param {string} userDetails.lastName - the last name of the user,
 * @param {string} userDetails.streetName - the street name of the user,
 * @param {string} userDetails.houseNumber - the house number of the user,
 * @param {string} userDetails.buildingNumber - the building number of the user,
 * @param {string} userDetails.floor - the floor number of the user,
 * @param {string} userDetails.sideNumber - the side number of the user,
 * @param {string} userDetails.zip - the zip code of the user,
 * @param {string} userDetails.city - the city of the user,
 * @param {string} userDetails.email - the email of the user,
 * @param {string} userDetails.phoneNumber - the phone number of the user,
 * @param {node} childrenBefore - children to be displayed before the user details,
 * @param {node} childrenAfter - children to be displayed after the user details,
 * @returns a vertical stack with user details
 */
export const UserDetailsCard = ({
  firstName,
  lastName,
  streetName,
  houseNumber,
  buildingNumber,
  floor,
  sideNumber,
  zip,
  city,
  email,
  phoneNumber,
  childrenBefore,
  childrenAfter
}) => {
  return <VStack spacing={6} sx={{
    alignItems: "flex-start",
    backgroundColor: "gray.lightest",
    borderRadius: "card",
    paddingY: 6,
    paddingX: 8,
    marginBottom: 6
  }} data-sentry-element="VStack" data-sentry-component="UserDetailsCard" data-sentry-source-file="UserDetailsCard.jsx">
            {childrenBefore}

            <UserDetailsCardItem headline="Navn" data-sentry-element="UserDetailsCardItem" data-sentry-source-file="UserDetailsCard.jsx">
                {firstName} {lastName}
            </UserDetailsCardItem>

            <UserDetailsCardItem headline="Adresse" data-sentry-element="UserDetailsCardItem" data-sentry-source-file="UserDetailsCard.jsx">
                {streetName} {houseNumber}{" "}
                {buildingNumber} {floor}{" "}
                {sideNumber}
                <br />
                {zip} {city}
            </UserDetailsCardItem>

            {!!email && <UserDetailsCardItem headline="Email">
                    {email}
                </UserDetailsCardItem>}

            {!!phoneNumber && <UserDetailsCardItem headline="Kontaktnummer">
                    {phoneNumber}
                </UserDetailsCardItem>}

            {childrenAfter}
        </VStack>;
};
UserDetailsCard.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  streetName: PropTypes.string,
  houseNumber: PropTypes.string,
  buildingNumber: PropTypes.string,
  floor: PropTypes.string,
  sideNumber: PropTypes.string,
  zip: PropTypes.string,
  city: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  childrenBefore: PropTypes.node,
  childrenAfter: PropTypes.node
};