import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Head from "next/head";
import { useRouter } from "next/router";
import { Box, ChakraProvider, Flex, Text } from "@chakra-ui/react";
import { SessionProvider } from "next-auth/react";
import { setLocale } from "yup";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Cookies from 'universal-cookie';
import { yupDA } from "../locales/yupDA";
import { theme } from "@/theme/theme";
import { clientConstants } from "@/services/client";
import { dataLayerUserIdlocalStorageId } from "@/services/client";
import { MicrocopyProvider } from "@/hooks/useMicrocopy";
import { GoogleTag } from "@/components/modules/GoogleTag";
import { OptimizelyTag } from "../components/modules/OptimizelyTag";
import { PreviewBanner } from "@/components/primitives/PreviewBanner";
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 1
    }
  } // 1 minute
});
// Set yup validation danish messages
setLocale(yupDA);
const impersonationBannerHeight = "35px";
export default function FlexiiApp({
  Component,
  pageProps: {
    session,
    ...pageProps
  }
}) {
  const router = useRouter();
  const [showImpersonationBanner, setShowImpersonationBanner] = useState(false); // Show or hide impersonation banner in the whole app

  useEffect(() => {
    // Impersonation Banner
    // ======================
    const isEnabled = localStorage.getItem(clientConstants.IMPERSONATE_CPR_KEY_NAME);
    if (isEnabled) {
      setShowImpersonationBanner(true);
    }

    // DataLayer
    // ======================
    handleRouteChange(); // run initial page load
    // Fire handleRouteChange for every route change
    router.events.on("routeChangeComplete", handleRouteChange); // run subsequent changes

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <>
            <Head data-sentry-element="Head" data-sentry-source-file="_app.jsx">
                {/* Favicon */}
                <link href="/favicon.ico" rel="icon" />
                <link href="/favicon-32x32.png" rel="icon" sizes="32x32" type="image/png" />
                <link href="/favicon-16x16.png" rel="icon" sizes="16x16" type="image/png" />

                <meta name="facebook-domain-verification" content="8pkj69g50bp706f1b5jgj2kgnox2f0" data-sentry-element="meta" data-sentry-source-file="_app.jsx" />
            </Head>

            <GoogleTag data-sentry-element="GoogleTag" data-sentry-source-file="_app.jsx" />
            <OptimizelyTag data-sentry-element="OptimizelyTag" data-sentry-source-file="_app.jsx" />
            <QueryClientProvider client={queryClient} data-sentry-element="QueryClientProvider" data-sentry-source-file="_app.jsx">
                <ChakraProvider theme={theme} data-sentry-element="ChakraProvider" data-sentry-source-file="_app.jsx">
                    <PreviewBanner data-sentry-element="PreviewBanner" data-sentry-source-file="_app.jsx" />
                    <SessionProvider session={session} basePath="/api/v1/auth" data-sentry-element="SessionProvider" data-sentry-source-file="_app.jsx">
                        {/* Show or hide the impersonation message banner */}
                        {showImpersonationBanner ? <Box sx={{
            height: impersonationBannerHeight,
            position: "relative",
            zIndex: 2000
          }}>
                                <Flex sx={{
              position: "fixed",
              width: "100%",
              background: "orange",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "35px"
            }}>
                                    <Text sx={{
                fontWeight: "bold",
                marginBottom: 0
              }}>
                                        You are currently in CPR impersonation
                                        mode
                                    </Text>
                                </Flex>
                            </Box> : null}

                        {/* Mount portal elements here. See: https://reactjs.org/docs/portals.html */}
                        <div id="portal" />
                        <MicrocopyProvider value={pageProps?.data?.microcopySets || pageProps?.microcopySets || []} data-sentry-element="MicrocopyProvider" data-sentry-source-file="_app.jsx">
                            <Flex sx={{
              flexDirection: "column",
              height: "100%"
            }} data-sentry-element="Flex" data-sentry-source-file="_app.jsx">
                                <Component {...pageProps} data-sentry-element="Component" data-sentry-source-file="_app.jsx" />
                                {/* Mount portal elements here. See: https://reactjs.org/docs/portals.html */}
                                <div id="portal" />
                            </Flex>
                        </MicrocopyProvider>
                    </SessionProvider>
                </ChakraProvider>
            </QueryClientProvider>
        </>;
}
FlexiiApp.propTypes = {
  Component: PropTypes.any,
  pageProps: PropTypes.any
};

/**
 * This method is executed for every route change.
 * Use with caution due to performance impact!
 */
const handleRouteChange = () => {
  pushLoginToDataLayer();
  refreshCookie("optimizelyEndUserId");
};
export const pushLoginToDataLayer = () => {
  window.dataLayer = window.dataLayer || []; // if dataLayer doesn't exist yet, initialize it

  const trackingUserId = localStorage.getItem(dataLayerUserIdlocalStorageId);
  window.dataLayer.push({
    loginStatus: !!trackingUserId,
    userId: trackingUserId
  });
};
function refreshCookie(cookieName) {
  const cookies = new Cookies(null, {
    path: '/'
  });
  // Calculate expiration date
  const expirationDate = new Date();
  expirationDate.setDate(expirationDate.getDate() + 60); // Adding 60 days to the current date

  // Get the existing cookie value
  let existingValue = cookies.get(cookieName);

  // If no existing cookie value found, generate a unique value
  if (!existingValue) {
    existingValue = generateGUID(); // Generate a unique value (e.g., GUID)
  }

  // Set the cookie with the same value and expiration date
  cookies.set(cookieName, existingValue, {
    expires: expirationDate
  });
}

// Function to generate a GUID (Globally Unique Identifier)
function generateGUID() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0,
      v = c == "x" ? r : r & 0x3 | 0x8;
    return v.toString(16);
  });
}