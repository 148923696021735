import PropTypes from "prop-types";
import { Flex } from "@chakra-ui/react";
import { PadlockButton } from "./PadlockButton";
export function DesktopNavigation({
  children
}) {
  return <Flex className="main-navigation" sx={{
    alignItems: "center"
  }} data-sentry-element="Flex" data-sentry-component="DesktopNavigation" data-sentry-source-file="DesktopNavigation.jsx">
            {children}

            {/* Desktop Login button (Padlock) */}
            <PadlockButton data-sentry-element="PadlockButton" data-sentry-source-file="DesktopNavigation.jsx" />
        </Flex>;
}
DesktopNavigation.propTypes = {
  children: PropTypes.node.isRequired
};