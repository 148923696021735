import { ArrowRightIcon } from "@/components/icons";
import { Box, Button } from "@chakra-ui/react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { motion } from "framer-motion";
import { useMemo } from "react";
import { richTextOptions } from "../richTextOptions";
export function FaqQuestion({
  questionId,
  question,
  answer,
  onQuestionClick,
  openQuestions,
  lastQuestion = false,
  sx,
  isCheckout = false
}) {
  const isOpen = useMemo(() => openQuestions.includes(questionId), [openQuestions, questionId]);
  return <Box as="li" id={questionId} sx={{
    borderBottomWidth: "1px",
    borderBottomStyle: lastQuestion ? "none" : "solid",
    borderColor: "gray.lighter",
    listStyle: "none"
  }} data-sentry-element="Box" data-sentry-component="FaqQuestion" data-sentry-source-file="FaqQuestion.jsx">
            <Button size="full" onClick={() => onQuestionClick(questionId, question)} sx={{
      paddingTop: 4,
      paddingBottom: isCheckout && isOpen ? 2 : 4,
      paddingX: 0,
      display: "flex",
      justifyContent: "space-between",
      textAlign: "left",
      background: "none",
      fontWeight: isOpen ? "black" : "bold",
      marginBottom: 0,
      borderRadius: 0,
      _focus: {
        outline: "none",
        fontWeight: "black"
      },
      ...sx
    }} data-sentry-element="Button" data-sentry-source-file="FaqQuestion.jsx">
                {question}

                <Box as={motion.span} sx={{
        display: "inline-block",
        paddingX: 2
      }} animate={{
        rotate: isOpen ? 90 : 0
      }} data-sentry-element="Box" data-sentry-source-file="FaqQuestion.jsx">
                    <ArrowRightIcon data-sentry-element="ArrowRightIcon" data-sentry-source-file="FaqQuestion.jsx" />
                </Box>
            </Button>

            <Box as={motion.div} animate={{
      opacity: openQuestions.includes(questionId) ? 1 : 0,
      y: openQuestions.includes(questionId) ? 0 : 50
    }} sx={{
      color: "text",
      paddingBottom: isCheckout ? 4 : 2,
      display: openQuestions.includes(questionId) ? "block" : "none"
    }} data-sentry-element="Box" data-sentry-source-file="FaqQuestion.jsx">
                {/* Check if answer is richtext from Contentful (FAQ->FaqCategory) or simple string/markdown from QuestionAndAnswer.jsx  */}
                {answer.nodeType === "document" ? documentToReactComponents(answer, richTextOptions) : answer}
            </Box>
        </Box>;
}