import PropTypes from "prop-types";
export function CheckIcon({
  fill = null,
  ...rest
}) {
  return <svg fill="none" height={15} width={15} xmlns="http://www.w3.org/2000/svg" {...rest} data-sentry-element="svg" data-sentry-component="CheckIcon" data-sentry-source-file="CheckIcon.jsx">
            <path d="m3.8 14.792.162-.18.144-.09.162.09c.234-.18.432-.378.594-.576.162-.198.468-.72.954-1.53l1.116-1.89c.594-1.008 3.708-5.238 4.41-6.03 1.62-1.854 2.16-2.412 2.376-2.7.198-.252.324-.45.324-.594 0-.09-.054-.234-.144-.234s-.234.072-.414.198l.144-.324-.612.36.036-.252c-.036.108-.324.396-.396.396-.054 0-.072-.054-.072-.144V1.22l-.504.252c-.036 0-.072-.018-.072-.072 0-.054.036-.18.072-.36-.252.27-.612.576-1.08.9-.63.432-3.078 3.51-4.068 5.112-.666 1.062-.936 1.692-1.044 1.692-.036 0-.054-.072-.072-.216-.324.738-.612 1.35-.9 1.8-.288.45-.558.684-.756.684-.234 0-.432-.45-.648-1.368-.09-.396-.288-.648-.576-.792l-.054-.018c-.054 0-.126.036-.216.09s-.144.108-.18.108h-.018l-.324-.108-.288.432c-.036.054-.108.072-.162.072-.09 0-.162-.018-.198-.072-.072.252-.162.378-.234.378-.054 0-.162-.036-.306-.126.126.954.234 1.584.342 1.89.216.594.702 1.944 1.17 2.844.054.108.126.234.18.342.18-.108.234-.126.36-.126l.144.018c.09.036.144.09.144.18l.414-.198c.072 0 .09.072.09.234Z" fill={fill ?? "#FFCA0F"} data-sentry-element="path" data-sentry-source-file="CheckIcon.jsx" />
        </svg>;
}
CheckIcon.propTypes = {
  fill: PropTypes.string
};