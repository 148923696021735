/**
 * Constants file
 * Not to be misinterpreted as .env file.
 *
 * Object.freeze prohibits an object to be modified, and either fails
 * silently or throws an error
 * Source: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/freeze.
 *
 * Naming convention: Use UPPER_CASE as property name
 */

const clientConstants = Object.freeze({
    APP_ENV_VALUES: {
        LOCAL: "local",
        TEST: "test",
        STAGING: "staging",
        PRODUCTION: "production",
    },
    SUBSCRIPTION_STATES: {
        NEW: "new", // Sim not yet activated
        ACTIVE: "active", // Normal active state
        BLOCKED: "blocked", // Tyveri spær
        PENDING_CANCEL: "pendingCancel",
        EXPORT: "export",
        EXPORT_REJECTED: "exportRejected",
        BLOCKED_PENDING_CANCEL: "blockedPendingCancel",
        BLOCKED_EXPORT: "blockedExport"

    },
    IMPERSONATE_CPR_KEY_NAME: "impersonateCpr",
    ORIGIN_ID: {
        SIGNUP: 1,
        UPDATE_PAYMENT: 2,
    },
});

module.exports = clientConstants;
