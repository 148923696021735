import PropTypes from "prop-types";
import Head from "next/head";
import { Navigation } from "../Navigation";
import { Footer } from "../Footer";
import { Section } from "../Section";
import { GradientBackground } from "@/components/primitives/GradientBackground";
import { navigationHeight } from "../Navigation/Navigation";
/**
 * Default Page Layout with Navigation and footer
 */
export function Layout({
  title,
  seoTitle,
  seoDescription,
  seoHideFromSearchEngines,
  children,
  centerContent,
  header,
  footer
}) {
  return <>
            <Head data-sentry-element="Head" data-sentry-source-file="Layout.jsx">
                <title>{title ? `${title}` : "Flexii"}</title>
                {!!seoTitle && <meta content={seoTitle || title} name="title" />}
                {!!seoDescription && <meta content={seoDescription} name="description" />}
                {!!seoHideFromSearchEngines && <meta name="robots" content="noindex, nofollow" />}
            </Head>

            {!!header && <Navigation {...header} />}

            <Section className="content-layout" sx={{
      position: "relative",
      flex: "1",
      paddingTop: navigationHeight,
      flexDirection: "column",
      ...(centerContent ? {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      } : null)
    }} data-sentry-element="Section" data-sentry-source-file="Layout.jsx">
                <Section.Root data-sentry-element="unknown" data-sentry-source-file="Layout.jsx">
                    <GradientBackground data-sentry-element="GradientBackground" data-sentry-source-file="Layout.jsx" />
                </Section.Root>

                {children}
            </Section>

            {!!footer && <Footer {...footer} />}
        </>;
}
Layout.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  seoTitle: PropTypes.string,
  seoDescription: PropTypes.string,
  seoHideFromSearchEngines: PropTypes.bool,
  header: PropTypes.object,
  footer: PropTypes.object,
  centerContent: PropTypes.bool
};
Layout.defaultProps = {
  centerContent: false,
  seoHideFromSearchEngines: false
};