import { Box, Divider, Flex, Heading, Text } from "@chakra-ui/react";
import PropTypes from "prop-types";

/**
 * Default wrapper component. Give the card some character
 */
function Card({
  children,
  heading,
  sx,
  variant,
  ...rest
}) {
  const body = <Box className="card" sx={{
    padding: {
      base: 5,
      lg: 10
    },
    borderRadius: "card",
    backgroundColor: "gray.lightest",
    position: "relative",
    ...sx
  }} {...rest}>
            {heading && <Heading as="h2">{heading}</Heading>}
            {children}
        </Box>;
  return variant === "MITFLEXII" ? <Box as="section" data-sentry-element="Box" data-sentry-component="Card" data-sentry-source-file="Card.jsx">
            <Flex sx={{
      flexDirection: "column",
      gap: 8
    }} data-sentry-element="Flex" data-sentry-source-file="Card.jsx">{body}</Flex>
        </Box> : <>{body}</>;
}
Card.propTypes = {
  children: PropTypes.node,
  heading: PropTypes.string,
  variant: PropTypes.PropTypes.oneOf(["MITFLEXII"])
};

/**
 * Card section with optional headline
 */
function CardSection({
  heading,
  tooltip,
  children
}) {
  return <>
            {heading && <Flex sx={{
      marginBottom: 2,
      alignItems: "center"
    }}>
                    {heading && <Text variant="cardSectionHeadline">{heading}</Text>}
                    {tooltip && tooltip}
                </Flex>}

            {children}
        </>;
}
CardSection.propTypes = {
  heading: PropTypes.string,
  tooltip: PropTypes.node,
  children: PropTypes.node
};

/**
 * Card divider between sections
 */
function CardDivider() {
  return <Divider data-sentry-element="Divider" data-sentry-component="CardDivider" data-sentry-source-file="Card.jsx" />;
}
Card.Section = CardSection;
Card.Divider = CardDivider;
export { Card };