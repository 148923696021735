import { Text } from "@chakra-ui/react";
import Image from "next/image";
import { Section } from "../../layouts/Section";
import { GradientAsset } from "../../primitives/GradientAsset";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, MARKS } from "@contentful/rich-text-types";

/**
 * Quote is configures to receive the quote as RichText, where text marked as bold is converted to a gradient text.
 * Bold is the only option available in the RichText fields in Contentful.
*/
export function Quote({
  quote,
  backgroundAsset
}) {
  const richTextOptions = {
    renderMark: {
      [MARKS.BOLD]: boldText => <Text as="span" variant="productCardGradient" sx={{
        whiteSpace: "pre"
      }}>
                    {boldText}
                </Text>
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <>{children}</>
    }
  };
  return <Section className="Quote" sx={{
    position: "relative",
    display: "flex",
    overflow: "hidden",
    flex: "0 0 auto",
    maxHeight: "80vh",
    backgroundColor: backgroundAsset ? null : "gray.lightest",
    color: backgroundAsset ? "white" : "text",
    _after: {
      content: '""',
      paddingTop: {
        base: null,
        lg: backgroundAsset ? "56%" : null // 16/9 aspect ratio
      }
    }
  }} data-sentry-element="Section" data-sentry-component="Quote" data-sentry-source-file="Quote.jsx">
            {!!backgroundAsset && <Section.Root>
                    <GradientAsset isBackground hasDots>
                        <Image src={`https:${backgroundAsset.url}`} fill style={{
          objectFit: "cover",
          objectPosition: "center"
        }} alt={backgroundAsset.description || ""} />
                    </GradientAsset>
                </Section.Root>}

            <Section.Content sx={{
      paddingTop: {
        base: 10,
        "2xl": 20
      },
      paddingBottom: {
        "2xl": 20
      },
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    }} data-sentry-element="unknown" data-sentry-source-file="Quote.jsx">
                <Text sx={{
        margin: "auto",
        color: "inherit",
        fontSize: {
          base: "5rem",
          lg: "8.125rem"
        },
        lineHeight: {
          base: 1,
          lg: 0.9
        },
        fontWeight: "black",
        textAlign: "center"
      }} data-sentry-element="Text" data-sentry-source-file="Quote.jsx">
                    <Text as="span" className="start-quotationmark" sx={{
          display: {
            base: "block",
            "2xl": "inline-block"
          },
          marginRight: {
            base: 0,
            "2xl": 16
          }
        }} data-sentry-element="Text" data-sentry-source-file="Quote.jsx">
                        &ldquo;
                    </Text>

                    {documentToReactComponents(quote, richTextOptions)}

                    <Text as="span" className="end-quotationmark" sx={{
          display: {
            base: "block",
            "2xl": "inline-block"
          },
          marginTop: {
            base: 10,
            "2xl": 0
          },
          marginLeft: {
            base: 0,
            "2xl": 16
          }
        }} data-sentry-element="Text" data-sentry-source-file="Quote.jsx">
                        <span>&rdquo;</span>
                    </Text>
                </Text>
            </Section.Content>
        </Section>;
}