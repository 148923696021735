import { Box, Slider, SliderTrack, SliderFilledTrack } from "@chakra-ui/react";
import { colors } from "../../../../theme/foundations/colors";
import { PlusIcon } from "../../../icons/Plus";
import { MinusIcon } from "../../../icons/Minus";

/* SHOULD BE REPLACED BY THE PROPS */
const defaultOptions = [{}, {}, {}, {}, {}, {}];
export function CustomSlider({
  context,
  stateIndex,
  options = defaultOptions,
  callback = () => {}
}) {
  const controls = {
    // height: "3rem",
    // width: "3.2rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    bgColor: context === "module" ? "whiteAlpha.400" : colors.gray.lighterer,
    rounded: "full",
    fontSize: "h5",
    fontWeight: "bold",
    textAlign: "center",
    padding: 1,
    cursor: "pointer",
    userSelect: "none"
  };
  const bgColor = context === "module" ? colors.blue.darkestTransparent : colors.gray.lighterer;
  const handleSlide = value => {
    callback(value);
  };
  return <Box display={"flex"} alignItems={"center"} width={{
    base: "full",
    md: "85%"
  }} m={"auto"} data-sentry-element="Box" data-sentry-component="CustomSlider" data-sentry-source-file="CustomSlider.jsx">
            <Box sx={controls} onClick={event => {
      event.preventDefault();
      handleSlide(stateIndex - 1);
    }} data-sentry-element="Box" data-sentry-source-file="CustomSlider.jsx">
                <MinusIcon width="1.5rem" height="1.5rem" data-sentry-element="MinusIcon" data-sentry-source-file="CustomSlider.jsx" />
            </Box>
            <Box width="full" paddingLeft={{
      base: "1rem",
      md: "1.5rem"
    }} paddingRight={{
      base: "1rem",
      md: "1.5rem"
    }} height={10} display={"flex"} alignItems={"center"} data-sentry-element="Box" data-sentry-source-file="CustomSlider.jsx">
                <Slider onChange={val => {
        handleSlide(val);
      }} step={1} max={options.length - 1} min={0} value={stateIndex || 0} data-sentry-element="Slider" data-sentry-source-file="CustomSlider.jsx">
                    <SliderTrack height={"10"} rounded={"3xl"} bgColor={bgColor} data-sentry-element="SliderTrack" data-sentry-source-file="CustomSlider.jsx">
                        <SliderFilledTrack bgColor={colors.yellow} borderRadius={"20px"} minW={"10"} data-sentry-element="SliderFilledTrack" data-sentry-source-file="CustomSlider.jsx" />
                    </SliderTrack>
                </Slider>
            </Box>
            <Box sx={controls} onClick={event => {
      event.preventDefault();
      handleSlide(stateIndex + 1);
    }} data-sentry-element="Box" data-sentry-source-file="CustomSlider.jsx">
                <PlusIcon width="1.5rem" height="1.5rem" data-sentry-element="PlusIcon" data-sentry-source-file="CustomSlider.jsx" />
            </Box>
        </Box>;
}