import { SignInOutButton } from "@/components/primitives/SignInOutButton";
import { Box, Button } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { getSession } from "next-auth/react";
import { PadlockIcon } from "../../icons";

/**
 * Padlock Button, changing view (open or closed)
 * based on auth session.
 * Extends SignInOutButton handling login/logout
 */
export function PadlockButton() {
  // const { data: session, status } = useSession();
  const sessionQuery = useQuery({
    queryKey: ["session-status"],
    queryFn: () => getSession().then(session => session),
    // returns boolean
    refetchInterval: 1000 * 60 * 5,
    // 5 minutes
    staleTime: 1000 * 60 * 5 // 5 minutes
    // initialData: null,
  });
  const backgroundCircleSx = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: "-1",
    display: "block",
    width: {
      base: "80%",
      lg: "100%"
    },
    height: {
      base: "80%",
      lg: "100%"
    },
    flexShrink: 0,
    borderRadius: "inherit",
    background: "rgba(0,0,0,0.15)"
  };
  return <SignInOutButton data-sentry-element="SignInOutButton" data-sentry-component="PadlockButton" data-sentry-source-file="PadlockButton.jsx">
            <Button title={sessionQuery.data ? "log ud" : "log ind"} variant="none" sx={{
      position: "relative",
      margin: 0,
      marginLeft: 3,
      height: 14,
      width: 14,
      borderRadius: "50%",
      flex: "0 0 auto",
      visibility: sessionQuery.isLoading ? "hidden" : "visible"
    }} data-sentry-element="Button" data-sentry-source-file="PadlockButton.jsx">
                <PadlockIcon isOpen={!!sessionQuery.data} sx={{
        height: {
          base: "20px",
          lg: "25px"
        }
      }} data-sentry-element="PadlockIcon" data-sentry-source-file="PadlockButton.jsx" />
                <Box sx={{
        ...backgroundCircleSx
      }} data-sentry-element="Box" data-sentry-source-file="PadlockButton.jsx" />
                <Box sx={{
        ...backgroundCircleSx,
        width: {
          base: "60%",
          lg: 10
        },
        height: {
          base: "60%",
          lg: 10
        }
      }} data-sentry-element="Box" data-sentry-source-file="PadlockButton.jsx" />
            </Button>
        </SignInOutButton>;
}

// scale .9