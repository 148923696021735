import { Box, Button } from "@chakra-ui/react";
import { motion } from "framer-motion";
import PropTypes from "prop-types";
export function BurgerMenuIcon({
  onClick,
  isOpen,
  sx
}) {
  return <Button className="burgermenu" variant="none" onClick={onClick} sx={{
    color: "gray.lightest",
    flex: "0 0 auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    alignSelf: "center",
    gap: 1.5,
    margin: 0,
    width: 8,
    height: 8,
    _focus: {
      outline: "none"
    },
    transitionDuration: "0.2s",
    ...sx
  }} data-sentry-element="Button" data-sentry-component="BurgerMenuIcon" data-sentry-source-file="BurgerMenuIcon.jsx">
            <Box as={motion.span} animate={isOpen ? "open" : "closed"} variants={{
      open: {
        y: 0,
        rotate: "45deg",
        transition: {
          delay: 0.1
        }
      },
      closed: {
        y: -10,
        rotate: "0deg"
      }
    }} sx={{
      position: "absolute",
      top: "50%",
      left: 0,
      right: 0,
      height: 1,
      borderRadius: "card",
      backgroundColor: "currentColor"
    }} data-sentry-element="Box" data-sentry-source-file="BurgerMenuIcon.jsx" />
            <Box as={motion.span} animate={isOpen ? "open" : "closed"} variants={{
      open: {
        x: "50%",
        opacity: 0
      },
      closed: {
        x: "0%",
        opacity: 1
      }
    }} sx={{
      position: "absolute",
      top: "50%",
      translateY: "-50%",
      left: 0,
      right: 0,
      height: 1,
      borderRadius: "card",
      backgroundColor: "currentColor"
    }} data-sentry-element="Box" data-sentry-source-file="BurgerMenuIcon.jsx" />
            <Box as={motion.span} animate={isOpen ? "open" : "closed"} variants={{
      open: {
        y: 0,
        rotate: "-45deg",
        transition: {
          delay: 0.1
        }
      },
      closed: {
        y: 10,
        rotate: "0deg"
      }
    }} sx={{
      position: "absolute",
      top: "50%",
      left: 0,
      right: 0,
      height: 1,
      borderRadius: "card",
      backgroundColor: "currentColor"
    }} data-sentry-element="Box" data-sentry-source-file="BurgerMenuIcon.jsx" />
        </Button>;
}
BurgerMenuIcon.propTypes = {
  onClick: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  sx: PropTypes.object
};