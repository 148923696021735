import { chakra } from "@chakra-ui/react";
export function MasterCardIcon({
  sx,
  ...rest
}) {
  return <chakra.svg xmlns="http://www.w3.org/2000/svg" width="40" height="26" viewBox="0 0 40 26" sx={{
    ...sx
  }} {...rest} data-sentry-element="unknown" data-sentry-component="MasterCardIcon" data-sentry-source-file="MasterCardIcon.jsx">
            <path fill="#fff" d="M0 0h40v26H0z" data-sentry-element="path" data-sentry-source-file="MasterCardIcon.jsx" />
            <path fill="#F06022" d="M16.13 19.29h7.74V6.7h-7.74v12.58z" data-sentry-element="path" data-sentry-source-file="MasterCardIcon.jsx" />
            <path fill="#EA1D25" d="M16.93 13A7.93 7.93 0 0 1 20 6.71a8.02 8.02 0 0 0-10.65.65 7.96 7.96 0 0 0 0 11.28 8.02 8.02 0 0 0 10.65.65A8.02 8.02 0 0 1 16.93 13" data-sentry-element="path" data-sentry-source-file="MasterCardIcon.jsx" />
            <path fill="#F79D1D" d="M33 13c0 2.12-.84 4.15-2.34 5.65a8.1 8.1 0 0 1-10.66.64A8.05 8.05 0 0 0 23.07 13 7.96 7.96 0 0 0 20 6.71a8.02 8.02 0 0 1 10.66.64A7.93 7.93 0 0 1 33 13" data-sentry-element="path" data-sentry-source-file="MasterCardIcon.jsx" />
        </chakra.svg>;
}