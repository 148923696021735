import { useState, useEffect, useCallback } from "react";
import { useRouter } from "next/router";
import Nextlink from "next/link";
import { Box, Button, Container, Heading, Text } from "@chakra-ui/react";
import { serialize, parse } from "cookie";
import { serializeError } from "serialize-error";
import { apiFlexii } from "@/services/client";
import { sha256 } from "@/services/client/cryptography";
import { getEcommerceTrackingObj } from "@/pages/checkout/[[...step]]";
import { useMicrocopy } from "@/hooks/useMicrocopy";
import { PriceOverview } from "@/components/modules/PriceOverview";
import { LoadingSpinner } from "@/components/primitives/LoadingSpinner";
import { ArrowRightIcon } from "@/components/icons";
import { Timeline } from "./components/Timeline";
import { IccForm } from "./components/IccForm";
export function Step4() {
  const router = useRouter();
  const {
    getMicrocopy
  } = useMicrocopy();
  const [orderData, setOrderData] = useState(null);
  let retryCount = 0;
  useEffect(() => {
    const reference = router?.query?.reference;
    if (!router.isReady || !reference) return;

    // Fetch data from backend in loop until success to show to customer
    const longPoll = setInterval(() => {
      apiFlexii.get(`v1/protected/resource/order-status?id=${reference}`).then(response => {
        if (response.data.processed) {
          clearInterval(longPoll);
          if (response.data.errorCode === "UsernameAlreadyExists") {
            router.push("/checkout/error?e=733&ref=" + reference);
          } else if (response.data.errorCode === "UserRejectedMobilePayAgreement") {
            router.push("/checkout/error?e=701&ref=" + reference);
          } else if (response.data.errorCode === "MobilePayAgreementExpired") {
            router.push("/checkout/error?e=702&ref=" + reference);
          } else if (response.data.errorCode === "GenericMobilePayError") {
            router.push("/checkout/error?e=703&ref=" + reference);
          } else {
            // Happy case - finalized subscription
            sendGTMPurchaseEvent(response.data); // send GTM event
            setOrderData(response.data);
          }
        } else {
          // Not processed yet, continue polling up to 60 times / 30sec.
          retryCount++;
          if (retryCount > 60) {
            clearInterval(longPoll);
            throw new Error(`${reference} not processed after 60 retries, giving up.`);
          }
        }
      }).catch(error => {
        clearInterval(longPoll);
        try {
          console.error("Error Detected Step 4", JSON.stringify(serializeError(error), null, 2));
        } catch (stringifyError) {
          console.error("Error Detected Step 4, but could not stringify the error: ", error);
        }
        router.push("/checkout/error?e=650&id=" + reference);
      });
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router]);

  /**
   * Send e-commerce purchase event on receipt page
   * Note: Must only be sent once, so use a cookie to prevent multiple events being sent
   * @param {object} data
   */
  const sendGTMPurchaseEvent = useCallback(async data => {
    const reference = router.query.reference; // get adyen payment reference
    const eventKey = `purchase-event-${reference}`;
    const cookies = parse(document.cookie);
    if (!cookies[eventKey] && data.user) {
      // Hash personal identifiable data (PID) for dataLayer anayltics
      const userData = {
        emailHashed: await sha256(data.user.email),
        phoneNumberHashed: await sha256(data.anumber.reservationToken ? data.anumber.anumber : data.anumberImportRequest.phoneNumber),
        firstName: await sha256(data.user.firstName),
        lastName: await sha256(data.user.lastName),
        street: await sha256(data.user.streetName),
        city: await sha256(data.user.city),
        region: await sha256(data.user.city),
        country: "Denmark",
        postCode: await sha256(data.user.zip)
      };

      // event has not been fired yet
      document.cookie = serialize(eventKey, 1); // no need to set expire - most clients see a cookie without expire as non-persistent and are removed when browser closes

      window.dataLayer = window.dataLayer || []; // init if not done before

      // trackingData props is null here
      window.dataLayer.push({
        ecommerce: null
      });
      window.dataLayer.push({
        event: "purchase",
        ecommerce: {
          ...getEcommerceTrackingObj(data.subscription),
          currency: "DKK",
          // required
          value: data.subscription.campaign?.campaignPrice || data.subscription.price,
          // required
          transaction_id: router.query.reference // required
        },
        userData
      });
    }
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [router.query.reference]);
  return <Container sx={{
    maxWidth: "container.sm",
    paddingX: 0,
    paddingBottom: 10
  }} data-sentry-element="Container" data-sentry-component="Step4" data-sentry-source-file="Step4.jsx">
            {!orderData ? <LoadingSpinner label="Vent venligst..." /> : <>
                    {orderData.successful ? <>
                            <Box sx={{
          marginBottom: 10
        }}>
                                <Heading as="h3" sx={{
            marginBottom: 2
          }}>
                                    {getMicrocopy("checkout.step4", "takForOrdren.overskrift", {
              fallback: "Tak for din ordre!"
            })}
                                </Heading>
                                <Text sx={{
            fontWeight: "black",
            marginBottom: 4
          }}>
                                    {getMicrocopy("checkout.step4", "takForOrdren.undertekst1", {
              fallback: `Vi er smadderglade for, at du har valgt
                                    Flexii`
            })}
                                    <br />
                                    {getMicrocopy("checkout.step4", "takForOrdren.undertekst2", {
              fallback: "– vi vil hoppe og danse for dig!"
            })}
                                </Text>
                                <Button as={Nextlink} variant="rounded" rightIcon={<ArrowRightIcon />} href="/mitflexii">
                                    {getMicrocopy("checkout.step4", "takForOrdren.mitFlexiiKnap", {
              fallback: "Mit Flexii"
            })}
                                </Button>
                            </Box>

                            {/* SIM-number (ICC) form */}
                            {!!orderData.anumberImportRequest.phoneNumber && <IccForm subscriptionId={orderData.subscriptionId} msisdn={orderData.anumberImportRequest.phoneNumber} showWarning={false} missingOldIcc={true} invalidOldIcc={false} />}

                            {/* Order confirmation */}
                            <Box sx={{
          marginBottom: 12
        }}>
                                <Text as="h4" sx={{
            fontWeight: "black",
            marginBottom: 4
          }}>
                                    {getMicrocopy("checkout.step4", "ordreAfsendt", {
              fallback: "Din ordre er på vej"
            })}
                                </Text>

                                <Timeline stepItems={[{
            title: getMicrocopy("checkout.step4", "ordrebekræftigelseSendesTil", {
              fallback: "Ordrebekræftelse sendes til"
            }),
            description: orderData.user.email
          }, {
            title: getMicrocopy("checkout.step4", "simkort.leveresIndenfor", {
              fallback: "Simkortet leveres inden for"
            }),
            description: getMicrocopy("checkout.step4", "simkort.leveringstid", {
              fallback: "5 hverdage"
            })
          }, {
            title: getMicrocopy("checkout.step4", "simkort.leveresTil", {
              fallback: "Vi leverer til"
            }),
            description: <>
                                                    {orderData.user.firstName}{" "}
                                                    {orderData.user.lastName}
                                                    <br />
                                                    {orderData.user.streetName}{" "}
                                                    {orderData.user.houseNumber}{" "}
                                                    {orderData.user.buildingNumber}
                                                    {orderData.user.floor}
                                                    {orderData.user.sideNumber}
                                                    <br />
                                                    {orderData.user.zip}{" "}
                                                    {orderData.user.city}
                                                </>
          }]} />
                            </Box>

                            <Text sx={{
          fontWeight: "black",
          marginBottom: 4
        }}>
                                {getMicrocopy("checkout.step4", "glædDig", {
            fallback: "Glæd dig til dit nye abonnement"
          })}
                            </Text>

                            <PriceOverview subscription={orderData.subscription} phoneNumber={orderData.anumber.reservationToken ? orderData.anumber.anumber : orderData.anumberImportRequest.phoneNumber} />
                        </> : <Box sx={{
        marginBottom: 10
      }}>
                            <Heading as="h3" sx={{
          marginBottom: 2
        }}>
                                {getMicrocopy("checkout.step4", "checkoutFejl.overskrift", {
            fallback: "Der skete desværre en fejl"
          })}
                            </Heading>
                            <Text sx={{
          fontWeight: "black",
          marginBottom: 0
        }}>
                                {getMicrocopy("checkout.step4", "checkoutFejl.beskrivelse", {
            fallback: `Der opstod en fejl da vi forsøgte at oprette dit
                                    abonnement`
          })}

                                <br />
                                {getMicrocopy("checkout.step4", "checkoutFejl.kontakt", {
            fallback: "Kontakt venligst kundeservice"
          })}
                            </Text>
                            <>
                                {orderData.result != null && <Text sx={{
            fontWeight: "black",
            marginBottom: 0
          }}>
                                        <br />
                                        Debug information:
                                        <br />
                                        {JSON.stringify(orderData.result)}
                                    </Text>}
                            </>
                        </Box>}
                </>}
        </Container>;
}