import { useRef } from "react";
import { Box, Container, Flex, useToken } from "@chakra-ui/react";
import Head from "next/head";
import PropTypes from "prop-types";
import { GradientBackground } from "../../primitives/GradientBackground";
import { Logo } from "../../primitives/Logo";
import { Breadcrumbs } from "../Breadcrumbs";
import { Footer } from "../Footer";
import { Navigation } from "../Navigation";
import { navigationHeight } from "../Navigation/Navigation";
import { Section } from "../Section";
import { Sidebar } from "@/components/layouts/Navigation/MitFlexii";

/**
 * Default Page Layout with Navigation and footer
 */
export function CardLayout({
  title,
  seoTitle,
  seoDescription,
  seoHideFromSearchEngines,
  children,
  withBreadcrumbs = false,
  /* Render the default navigation or navigation for checkout pages */
  navigationType = "default",
  navigationData,
  /* Provide a custom navigation to override the default one. */
  navigation,
  footer,
  /* SX props for Section.Content container */
  sx,
  /* Rest props for Section.Content container */
  ...rest
}) {
  const contentRef = useRef();
  const isCheckoutPage = navigationType === "checkout";
  const isMitFlexiiPage = navigationType === "mitflexii";

  /**
   * WYSIWYG Pages and Checkout use two different Navigations.
   * Allow for specifying Navigation type + a custom Navigation component overrides them all.
   */
  const renderNavigation = () => {
    if (navigation) return navigation;

    // Render Navigation for checkout page
    if (isCheckoutPage) {
      return <Flex as="nav" sx={{
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        zIndex: "banner",
        height: navigationHeight
      }}>
                    <Section sx={{
          width: "full"
        }}>
                        <Section.Content>
                            <Logo />
                        </Section.Content>
                    </Section>
                </Flex>;
    }
    return !!navigationData ? <Navigation {...navigationData} data-sentry-element="Navigation" data-sentry-component="renderNavigation" data-sentry-source-file="CardLayout.jsx" /> : null;
  };

  // Calculate width/maxWidth of the layout when MitFlexii is navigation type
  const mitFlexiiSidebarWidthRendered = `calc(${useToken("sizes", "mitFlexiiSidebarWidth.xl")} - 1rem)`;
  const mitFlexiiContentWidthRendered = `calc(100% - ${useToken("sizes", "mitFlexiiSidebarWidth.xl")})`;
  return <>
            <Head data-sentry-element="Head" data-sentry-source-file="CardLayout.jsx">
                <title>{title ? `${title}` : "Flexii"}</title>
                {!!seoTitle && <meta content={seoTitle || title} name="title" />}
                {!!seoDescription && <meta content={seoDescription} name="description" />}
                {!!seoHideFromSearchEngines && <meta name="robots" content="noindex, nofollow" />}
            </Head>

            {renderNavigation()}

            <Section sx={{
      marginTop: navigationHeight,
      position: "relative",
      flex: "1"
    }} className="card-layout" data-sentry-element="Section" data-sentry-source-file="CardLayout.jsx">
                <Section.Root data-sentry-element="unknown" data-sentry-source-file="CardLayout.jsx">
                    <GradientBackground sx={{
          top: {
            base: `calc(-1 * ${navigationHeight.base})`,
            md: `calc(-1 * ${navigationHeight.md})`
          },
          height: {
            base: `calc(100% + ${navigationHeight.base})`,
            md: `calc(100% + ${navigationHeight.md})`
          }
        }} data-sentry-element="GradientBackground" data-sentry-source-file="CardLayout.jsx" />
                </Section.Root>

                <Section.Content ref={contentRef} sx={{
        paddingBottom: {
          base: 20,
          lg: 44
        },
        ...(isMitFlexiiPage ? {
          display: "flex",
          alignItems: "flex-start",
          paddingInlineEnd: {
            base: "1rem",
            xl: "0rem"
          }
        } : {}),
        ...sx
      }} {...rest} data-sentry-element="unknown" data-sentry-source-file="CardLayout.jsx">
                    {isMitFlexiiPage && <Sidebar width={mitFlexiiSidebarWidthRendered} parentRef={contentRef} />}

                    <Box className="card-wrapper" sx={{
          background: "white",
          borderRadius: "section",
          paddingY: {
            base: 10,
            lg: 16
          },
          paddingX: {
            base: 0,
            lg: 4
          },
          ...(isMitFlexiiPage ? {
            paddingX: {
              base: 0,
              md: 2,
              xl: 5
              //   "2xl": 10,
            },
            width: "100%",
            maxWidth: {
              base: "100%",
              xl: mitFlexiiContentWidthRendered
            },
            minHeight: {
              base: "25vh",
              xl: "50vh"
            },
            paddingY: {
              base: 5,
              //   md: 5,
              lg: 7,
              xl: 9
            }
          } : {})
        }} data-sentry-element="Box" data-sentry-source-file="CardLayout.jsx">
                        {withBreadcrumbs && <Container maxWidth="container.lg">
                                <Breadcrumbs />
                            </Container>}
                        {children}
                    </Box>
                </Section.Content>
            </Section>

            {!isCheckoutPage && !!footer && <Footer {...footer} />}
        </>;
}
CardLayout.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  seoTitle: PropTypes.string,
  seoDescription: PropTypes.string,
  seoHideFromSearchEngines: PropTypes.bool,
  withBreadcrumbs: PropTypes.bool,
  navigation: PropTypes.node,
  navigationType: PropTypes.oneOf(["default", "checkout", "mitflexii"]),
  navigationData: PropTypes.object,
  footer: PropTypes.object
};
CardLayout.defaultProps = {
  seoHideFromSearchEngines: false
};