import { Button } from "@chakra-ui/react";
import { ArrowLeftIcon, ArrowRightIcon } from "@/components/icons";
export function SwiperNavigationBtn({
  isNext = false,
  handleClick
}) {
  return <Button type="button" onClick={handleClick} variant="null" sx={{
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    width: "32px",
    height: "32px",
    borderRadius: "50%",
    backgroundColor: "gray.lighterTransparent80",
    margin: 0,
    zIndex: 5,
    transitionDuration: "0.6s",
    _focus: {
      boxShadow: "none"
    },
    _hover: {
      backgroundColor: "gray.lightest"
    }
  }} data-sentry-element="Button" data-sentry-component="SwiperNavigationBtn" data-sentry-source-file="SwiperNavigationBtn.jsx">
            {isNext ? <ArrowRightIcon /> : <ArrowLeftIcon />}
        </Button>;
}
;