import { cloneElement, useMemo } from "react";
import { Container, Box } from "@chakra-ui/react";
import { CardLayout } from "@/components/layouts/CardLayout";
import { PriceOverview } from "@/components/modules/PriceOverview";
import { ProgressSteps } from "@/components/modules/ProgressSteps";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { Step1 } from "@/views/checkout/Step1";
import { QuestionAndAnswer } from "@/views/checkout/Step1/components/QuestionAndAnswer";
import { Step2 } from "@/views/checkout/Step2";
import { Step3 } from "@/views/checkout/Step3";
import { Step4 } from "@/views/checkout/Step4";
import { useStore } from "@/store";
import { contentfulApi } from "@/services/server";
import { LoadingSpinner } from "@/components/primitives/LoadingSpinner";
import { apiFlexii } from "@/services/client";
import { useMicrocopy } from "@/hooks/useMicrocopy";
import { useQuery, useQueryClient } from "@tanstack/react-query";

/**
 * Dynamic Checkout Step Route Pages
 */
export default function CheckoutSteps({
  globalSettings,
  requestedStepRoutePath
}) {
  const {
    getMicrocopy
  } = useMicrocopy();
  const queryClient = useQueryClient();
  const requestedStepRoute = getStepRouteObject(stepRoutes, requestedStepRoutePath);
  const [renderRoute, setRenderRoute] = useState(); // finally, renders the component

  const [isLoading, setIsLoading] = useState(false);
  const [loadingMsg, setLoadingMsg] = useState();

  // eslint-disable-next-line no-unused-vars
  const [checkoutSession, setCheckoutSession, resetCheckoutSession] = useStore(state => [state.checkout.checkoutSession, state.checkout.setCheckoutSession, state.checkout.resetCheckoutSession]);
  const {
    query,
    ...router
  } = useRouter();

  // Reused e-commerce tracking object
  const ecommerceTrackingObj = useMemo(() => {
    if (!checkoutSession.subscription) return null;
    const subscription = checkoutSession.subscription;
    return {
      ecommerce: getEcommerceTrackingObj(subscription)
    };
  }, [checkoutSession]);

  // Check if signup is under maintenance
  useQuery({
    queryKey: ["service-status"],
    queryFn: () => apiFlexii.get("v1/public/service-status?service=signup").then(res => res.data),
    onSuccess: ({
      underMaintenance
    }) => {
      if (underMaintenance) {
        router.push("/error?service=signup");
      }
    }
  });

  /**
   * Main Steps router
   * Main logic and handler for all routes under /checkout
   */
  useEffect(() => {
    // Path defined
    setRenderRoute(requestedStepRoute);

    // Redirect to error page if the checkoutSession has no selected product key
    if (!checkoutSession.productKey && requestedStepRoute.path != "ordrebekraeftelse") {
      router.push("/checkout/error?e=627");
    }
    if (checkoutSession.clientSidePaymentSuccess && requestedStepRoute.path != "ordrebekraeftelse") {
      // User went from order confirmation page back to previous step pages - throw them out
      console.error("Error 673 : User tried to go back from receipt to checkout steps");
      router.push("/checkout/error?e=673");
      return undefined;
    }

    // Reset session if user is starting a new checkoutsession
    if (checkoutSession.clientSidePaymentSuccess && requestedStepRoute.path != "mobilnummer") {
      resetCheckoutSession();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, setRenderRoute]);

  /**
   * Reset Zustand State when leaving /checkout or when returning to betaling after successful payment
   * Create an event listener on a global scale
   */
  useEffect(() => {
    const routeChangeHandler = url => {
      const isCheckoutUrl = url.split("/")[1] === "checkout";
      if (!isCheckoutUrl) {
        // Left checkout
        // Show loader before resetting state to prevent users seeing checkout data removed before routechange complete
        setLoadingMsg("Du forlader nu siden");
        setIsLoading(true);
        // Delete session if the user is not an active customer
        apiFlexii.get("/v1/protected/resource/is-active-customer-check").then(resp => {
          if (resp.data.sessionDestroyed) {
            setTimeout(() => {
              // Run in setTimeout to prevent session race condition
              // can happen when deleting the JWT Cookie is IMMEDIATELY followed
              // by reading the JWT value which stores it temporarily in memory
              // and sets it again with an updated timestamp
              queryClient.invalidateQueries({
                queryKey: ["session-status"]
              });
            }, 2000);
          }
        }).catch(error => {
          if (error?.response?.status === 401) {
            // Mute 401 errors if this api fails
          } else {
            throw error;
          }
        });
        // Reset state
        resetCheckoutSession();
        return undefined;
      }
    };
    router.events.on("routeChangeStart", routeChangeHandler);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off("routeChangeStart", routeChangeHandler);
    };
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [
    // No need for dependencies since we create an event listener on a global scope
  ]);

  /**
   * Handle clicks on progress steps items
   * @param {number} index
   */
  const onProgressStepClickHandler = index => {
    router.push({
      query: {
        step: stepRoutes[index].path
      }
    });
  };
  const isLastStep = useMemo(() => {
    return requestedStepRoute.index === stepRoutes.length - 1;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestedStepRoute.index]);
  const isFirstStep = useMemo(() => {
    return requestedStepRoute.index === 0;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestedStepRoute.index]);
  const isThirdStep = useMemo(() => {
    return requestedStepRoute.index === 2;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestedStepRoute.index]);
  return <CardLayout navigationType="checkout" data-sentry-element="CardLayout" data-sentry-component="CheckoutSteps" data-sentry-source-file="[[...step]].jsx">
            {!isLastStep && <ProgressSteps steps={stepRoutes.slice(0, -1)} // remove final step from Progress Steps
    currentStepIndex={(() => {
      return requestedStepRoute.index;
    })()} onStepClickHandler={onProgressStepClickHandler} />}

            {isLoading ? <LoadingSpinner label={loadingMsg} /> : <Container maxWidth="container.lg" sx={{
      display: {
        base: "flex",
        xl: "grid"
      },
      flexWrap: "wrap",
      gridTemplateColumns: {
        xl: isLastStep ? "auto" : "minmax(0px, auto) 360px"
      },
      gridTemplateRows: {
        xl: "auto"
      },
      columnGap: {
        base: 0,
        xl: 20
      },
      rowGap: {
        base: 10,
        xl: 0
      },
      alignItems: "flex-start"
    }}>
                    {renderRoute?.component && cloneElement(renderRoute.component, {
        globalSettings,
        trackingData: ecommerceTrackingObj
      })}

                    {!isLastStep && <Box sx={{
        width: "100%"
      }}>
                            <PriceOverview subscription={checkoutSession.subscription} phoneNumber={checkoutSession.anumber.reservationToken ? checkoutSession.anumber.anumber : checkoutSession.anumberImportRequest.phoneNumber} />

                            {isFirstStep && <QuestionAndAnswer isCheckout questions={[{
          question: getMicrocopy("checkout", "faq.step1.question1", {
            fallback: "Kan jeg beholde mit nummer?"
          }),
          answer: getMicrocopy("checkout", "faq.step1.answer1", {
            fallback: "Det kan du tro – Flexii sørger for både at flytte OG opsige dit nummer hos dit nuværende teleselskab, hvis du vælger ”Behold nummer”. Så du skal ikke foretage dig noget som helst, ud over at oprette dig hos os – så klarer vi resten."
          })
        }, {
          question: getMicrocopy("checkout", "faq.step1.question2", {
            fallback: "Skal jeg opsige mit mobilabonnement?"
          }),
          answer: getMicrocopy("checkout", "faq.step1.answer2", {
            fallback: "Nope – den klarer vi. Vælger du at flytte dit nummer over til os, så står vi for både at flytte og opsige dit nummer – og holder dig i hånden hele vejen!"
          })
        }, {
          question: getMicrocopy("checkout", "faq.step1.question3", {
            fallback: "Passer jeres nye simkort til min mobil?"
          }),
          answer: getMicrocopy("checkout", "faq.step1.answer3", {
            fallback: "Yep – vi sender dig et såkaldt trio-simkort som indeholder alle tre størrelser simkort på markedet: standard-, mikro- og nanosimkort. Du klikker blot den størrelse ud, der passer til din mobil."
          })
        }, {
          question: getMicrocopy("checkout", "faq.step1.question4", {
            fallback: "Hvornår kan jeg bruge mit nye abonnement?"
          }),
          answer: getMicrocopy("checkout", "faq.step1.answer4", {
            fallback: "Har du bestilt nummerflytning, så kan bruge dit simkort fra Flexii den dato dit nummer flytter – datoen giver vi dig naturligvis besked om. Har du bestilt nyt nummer, kan du bruge dit Flexii-simkort, så snart det kommer ind ad døren!"
          })
        }]} />}

                            {isThirdStep && <QuestionAndAnswer isCheckout questions={[{
          question: getMicrocopy("checkout", "faq.step2.question1", {
            fallback: "Hvornår skal jeg betale for mit abonnement?"
          }),
          answer: getMicrocopy("checkout", "faq.step2.answer1", {
            fallback: "Du opkræves første gang, den dag dit abonnement aktiveres og opkrævningen er for de resterende dage i den pågældende måned. Næste opkrævning sker ved næste månedsskift (den 1.), som dækker over hele næste måned."
          })
        }, {
          question: getMicrocopy("checkout", "faq.step2.question2", {
            fallback: "Hvorfor skal jeg godkende min betaling med MitID?"
          }),
          answer: getMicrocopy("checkout", "faq.step2.answer2", {
            fallback: "Du skal godkende din betaling med MitID, også kaldet 2-faktor-betaling. Det har til formål at sikre, at det faktisk er dig der benytter dit betalingskort og ikke en lumsk tricktyv!"
          })
        }, {
          question: getMicrocopy("checkout", "faq.step2.question3", {
            fallback: "Hvad hulen er 2-faktor-betaling?"
          }),
          answer: getMicrocopy("checkout", "faq.step2.answer3", {
            fallback: <>
                                                            2-faktor-betalinger
                                                            er din sikring mod
                                                            misbrug af dine
                                                            betalingskortoplysninger.
                                                            I praksis betyder
                                                            det, at du skal
                                                            angive måden du vil
                                                            godkende din
                                                            betaling på, efter
                                                            du har indtastet
                                                            dine
                                                            kortoplysninger.
                                                            <br />
                                                            Du kan godkende din
                                                            betaling på én af to
                                                            måder:
                                                            <br />
                                                            <b>Med MitID:</b>
                                                            <br />
                                                            Du godkender din
                                                            betaling via
                                                            MitID-appen. Husk at
                                                            du selv skal
                                                            fremsøge appen på
                                                            din mobil, når du
                                                            skal godkende
                                                            betalingen – den
                                                            dukker fiseme ikke
                                                            op af sig selv!
                                                            Swipe og betalingen
                                                            er godkendt!
                                                            <br />
                                                            <b>Med SMS-kode:</b>
                                                            <br />
                                                            Vil du benytte
                                                            SMS-kode,
                                                            forudsætter det, at
                                                            du er registreret
                                                            hos NETS. Når du har
                                                            modtaget SMS-koden
                                                            fra NETS, indtaster
                                                            du denne kode samt
                                                            dit eget personlige
                                                            kodeord. Vupti – så
                                                            er betalingen
                                                            godkendt!
                                                        </>
          })
        }]} />}
                        </Box>}
                </Container>}
        </CardLayout>;
}

/**
 * List of step components which are rendered based on the url path
 *
 * !!! IMPORTANT !!!
 * Order of the items matters, as they are displayed from first to last
 */
export let stepRoutes = [
/**
 * ===================================
 * Steps available inside progress bar
 * ===================================
 */
{
  title: "Nummer",
  component: <Step1 />,
  path: "mobilnummer"
}, {
  title: "Oplysninger",
  component: <Step2 />,
  path: "oplysninger"
}, {
  title: "Betaling",
  component: <Step3 />,
  path: "betaling"
},
// Last step is hidden in the progress bar and doesn't display a price overview
{
  title: "Ordrebekræftelse",
  component: <Step4 />,
  path: "ordrebekraeftelse"
}].map((stepRoute, i) => {
  return {
    ...stepRoute,
    index: i
  }; // Add index to each step, ordered by their order in the array
});

/**
 * Dynamic route, that uses getStaticProps, requires getStaticPaths.
 * An empty paths array speciefies to pre-render no routes at build time.
 * Using "fallback:'blocking'" renders non-defined routes when they are requested.
 */
export const getStaticPaths = async () => {
  const paths = stepRoutes.map(stepRoute => ({
    params: {
      step: stepRoute.path.split("/")
    }
  }));
  return {
    paths,
    // If path doesn't exist, handle it inside getStaticProps to decide if to prerender or redirect
    fallback: "blocking"
  };
};
export const getStaticProps = async ({
  params,
  preview
}) => {
  const client = contentfulApi(preview);
  let requestedStepRoutePath = params?.step?.join("/") || "";
  let requestedStepRoute = getStepRouteObject(stepRoutes, requestedStepRoutePath);
  if (!requestedStepRoute) {
    // Route is not defined - Redirect and default to first page
    return {
      redirect: {
        // Send them to the first page of checkout :)
        destination: `/checkout/${stepRoutes[0].path}`,
        permanent: false
      }
    };
  }
  const globalSettings = await client.getEntries({
    content_type: "globalSettings",
    include: 4,
    limit: 1
  }).then(response => response.items[0]).catch(console.error);
  const checkout = await client.getEntries({
    content_type: "checkout",
    include: 2,
    limit: 1
  }).then(response => response.items[0]?.fields).catch(console.error);

  // Route defined, return props
  return {
    revalidate: 60,
    // Every minute
    props: {
      globalSettings,
      requestedStepRoutePath,
      microcopySets: [...(globalSettings?.fields?.microcopy ?? []), ...(checkout?.microcopy ?? [])]
    }
  };
};

/**
 * ==============================
 * HELPER METHODS
 * ==============================
 */

/**
 * Get the matching step route object from the stepsRouteList
 * @param {[object]} stepRoutesList List of step route objects
 * @param {string} stepRoutePathNeedle A path that should match one of the objects.path values
 * @returns {object | null}
 */

const getStepRouteObject = (stepRoutesList, stepRoutePathNeedle) => {
  const matchedRoute = stepRoutesList.reduce((match, route, i) => {
    if (route.path === stepRoutePathNeedle) {
      match = {
        ...route
      };
      if (match.isStep) match.index = i; // attach step index position to step routes only
    }
    return match;
  }, {});
  return Object.keys(matchedRoute).length ? matchedRoute : null;
};

/**
 * Get the ecommerce tracking object for checkout GTM events
 * @param {object} subscription Subscription object that has the structure defined in the store
 * @returns {object}
 */
export const getEcommerceTrackingObj = subscription => ({
  items: [{
    item_id: subscription.optionKey,
    item_name: subscription.displayName,
    price: subscription.price,
    discount: subscription.campaign ? subscription.price - subscription.campaign.campaignPrice : 0,
    // calculate discount based on price and campaign price
    quantity: 1,
    // Currently checkout only supports one product at a time
    currency: "DKK",
    item_variant: subscription?.campaign?.code || null,
    // "DISCOUNT_DKK_30_M_12"
    item_brand: "Flexii",
    item_category: subscription.itemCategory || "Voice",
    item_category2: subscription.voiceHours,
    // Voice hours
    item_category3: `${subscription.data} gb` // GB
  }]
});