import PropTypes from "prop-types";
import { Box, Divider } from "@chakra-ui/react";
export function ProductCardSection({
  hasBottomBorder,
  canFlex,
  children
}) {
  return <Box className="productcard-section" sx={{
    paddingX: 3,
    flex: canFlex && "1 1 auto"
  }} data-sentry-element="Box" data-sentry-component="ProductCardSection" data-sentry-source-file="ProductCardSection.jsx">
            <Box sx={{
      paddingY: 5
    }} data-sentry-element="Box" data-sentry-source-file="ProductCardSection.jsx">
                {children}
            </Box>
            <Box sx={{
      paddingX: 7
    }} data-sentry-element="Box" data-sentry-source-file="ProductCardSection.jsx">
                {!!hasBottomBorder && <Divider variant="productCard" />}
            </Box>
        </Box>;
}
ProductCardSection.propTypes = {
  children: PropTypes.node,
  hasBottomBorder: PropTypes.bool,
  canFlex: PropTypes.bool
};
ProductCardSection.defaultProps = {
  hasBottomBorder: false,
  canFlex: false
};