// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"f2d52b1852251ff520a18aafe3382666e72562a3"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from "@sentry/nextjs";
import {
    beforeSend,
    beforeSendTransaction,
    ignoreErrors,
} from "./sentry.common.config";
import { clientConstants } from "@/services/client";
import { hasAcceptedCookies } from "@/utils";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

const isDevEnvironment = [
    clientConstants.APP_ENV_VALUES.LOCAL,
    clientConstants.APP_ENV_VALUES.TEST,
].includes(process.env.NEXT_PUBLIC_APP_ENV);

Sentry.init({
    dsn:
        SENTRY_DSN ||
        "https://297a1847b2354f7aaaea426061d7bd11@o4504207644033024.ingest.sentry.io/4504207784017920",
    tracesSampleRate: 0.1,
    environment: process.env.NEXT_PUBLIC_APP_ENV,
    debug: false,
    ignoreErrors,
    beforeSend,
    beforeSendTransaction,
    maxValueLength: 1000,
    replaysSessionSampleRate: isDevEnvironment ? 1.0 : 0.05, // 100% in dev, 5% in prod,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
        Sentry.captureConsoleIntegration({
            // array of methods that should be captured
            // defaults to ['log', 'info', 'warn', 'error', 'debug', 'assert']
            levels: ["error"],
        }),
    ],
});

const handleConsentGiven = () => {
    const replay = Sentry.getReplay(); // Check if an existing replay is already active
    if (!replay && hasAcceptedCookies("cookie_cat_statistic")) {
        console.info("Initializing session replay");
        Sentry.addIntegration(
            Sentry.replayIntegration({
                maskAllText: true,
                block: [".mask-me", ".Boost-ChatPanel-Container"],
                blockAllMedia: false,
            })
        );
    }
};

// Make sure only one event listener is attached
window.removeEventListener("CookieInformationConsentGiven", handleConsentGiven);
window.addEventListener("CookieInformationConsentGiven", handleConsentGiven);
