import { useEffect, useState } from "react";

/**
 * Detect if the client has an adblocker that may block ad links along tiktok, google,...
 *
 * How it works:
 *      - https://www3.doubleclick.net is an ad host of google and blocked by e.g. uBlock origin
 *      - When the client makes a request to https://www3.doubleclick.net, the request fails with an "net::ERR_BLOCKED_BY_CLIENT" network exception
 *      - Due to security reasons, we can't detect the network error type (https://stackoverflow.com/a/62297933)
 *      - We can however, detect if the request failed due to a network error or any other error (https://stackoverflow.com/a/61113372)
 *        by wrapping the FIRST promise in a try/catch statement (reminder, to get data from fetch, you use two promises, e.g.
 *        "fetch(...).then(res=>res.json()).then(data=>console.log(data))")
 *
 * Ad domains to test against taken from: https://github.com/gorhill/uBlock/blob/master/docs/tests/hostname-pool.js
 *
 * @returns {boolean}
 */
export const useHasAdblocker = () => {
  const [hasAdblocker, setHasAdblocker] = useState(false);
  useEffect(() => {
    async function detectAdblockerAttempt() {
      const options = {
        method: "HEAD",
        // Make a tiny request using the HEAD method: https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/HEAD <- doesn't return a body
        mode: "no-cors",
        cache: "no-store"
      };
      try {
        await Promise.all([
        // Test on two domains for increased trust
        fetch("https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js", options),
        // <- Google Tag Manager
        fetch("https://www3.doubleclick.net", options) // <- Google Ads Host
        ]);
      } catch (error) {
        // Network error
        setHasAdblocker(true);
      }
      // const data = await response.json() // No need for this - we just want to check for network errors
    }
    detectAdblockerAttempt();
  }, []);
  return hasAdblocker;
};