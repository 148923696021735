import PropTypes from "prop-types";
import { Box, Button, Flex, ListItem, UnorderedList, useToken } from "@chakra-ui/react";
import { useSize } from "@chakra-ui/react-use-size";
import { NavigationItem } from "@/components/layouts/Navigation/NavigationItem";
import { motion, useScroll } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { convertRemToPixels } from "@/utils/convertRemToPixels";
import { SignInOutButton } from "@/components/primitives/SignInOutButton";

/**
 * MitFlexii Sidebar
 */
export function Sidebar({
  width,
  parentRef
}) {
  const yMarginInPx = convertRemToPixels(useToken("space", 28)); // top/bottom margin relative to parent
  const [topPosition, setTopPosition] = useState(yMarginInPx);
  const sidebarRef = useRef(null);
  const {
    scrollYProgress
  } = useScroll();
  const parentDimensions = useSize(parentRef); // parent component height, playground to move on Y-axis
  const sidebarDimensions = useSize(sidebarRef);

  // Calculate new sidebar Y position on scroll, so that
  // the sidebar follows the screen during scrolling
  useEffect(() => {
    const sidebarHeight = sidebarDimensions?.height;
    const parentHeight = parentDimensions?.height;
    if (!parentHeight && !sidebarHeight) {
      return undefined;
    }

    // Create scroll event listener
    const scrollSubscription = scrollYProgress.onChange(yFactor => {
      // Calculate the new y top position for the sidebar
      let calcY = yFactor * parentHeight;

      // Check if calcY is lower than minimum top position
      if (calcY < yMarginInPx) {
        calcY = yMarginInPx;
      }

      // Check if calcY is higher than maximum top position
      if (calcY > parentHeight - 2 * yMarginInPx - sidebarHeight) {
        calcY = parentHeight - 2 * yMarginInPx - sidebarHeight;
      }
      setTopPosition(calcY);
    });
    return () => {
      // remove scroll event lisetener
      scrollSubscription();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollYProgress, parentDimensions]);
  return <Box as={motion.nav} ref={sidebarRef} sx={{
    position: "relative",
    // top: topPosition,
    background: "blue.darkest",
    paddingY: 10,
    paddingX: 10,
    borderLeftRadius: "section",
    color: "white",
    flex: `0 0 ${width}`,
    display: {
      base: "none",
      xl: "block"
    }
  }} animate={{
    y: topPosition
  }} transition={{
    type: "spring",
    duration: 1.6
  }}
  // transition={{ duration: 2, type: "tween" }}
  data-sentry-element="Box" data-sentry-component="Sidebar" data-sentry-source-file="Sidebar.jsx">
            <UnorderedList sx={{
      listStyle: "none",
      paddingLeft: 0,
      marginLeft: 0,
      marginTop: -2,
      // balances out Link padding top 1
      marginBottom: 10
    }} data-sentry-element="UnorderedList" data-sentry-source-file="Sidebar.jsx">
                {mitFlexiiNavItems.map(item => <ListItem key={item.url} sx={{
        marginBottom: 1
      }}>
                        <NavigationItem url={item.url} sx={{
          justifyContent: "flex-start",
          paddingX: 2,
          marginLeft: -2,
          paddingY: 1,
          fontSize: "base"
        }}>
                            {item.title}
                        </NavigationItem>
                    </ListItem>)}
            </UnorderedList>

            <Flex sx={{
      justifyContent: "flex-start",
      alignItems: "center"
    }} data-sentry-element="Flex" data-sentry-source-file="Sidebar.jsx">
                <SignInOutButton data-sentry-element="SignInOutButton" data-sentry-source-file="Sidebar.jsx">
                    <Button variant="action" borderRadius="full" sx={{
          marginTop: 0,
          paddingY: 3,
          paddingX: 12,
          fontSize: "base"
        }} data-sentry-element="Button" data-sentry-source-file="Sidebar.jsx">
                        Log ud
                    </Button>
                </SignInOutButton>
            </Flex>
        </Box>;
}
Sidebar.propTypes = {
  parentRef: PropTypes.any,
  width: PropTypes.string
};

/**
 * List of Navigation Menu items for MitFlexii
 */
export const mitFlexiiNavItems = [{
  title: "Overblik",
  url: "/mitflexii"
}, {
  title: "Abonnement",
  url: "/mitflexii/abonnement"
}, {
  title: "Forbrug",
  url: "/mitflexii/forbrug"
}, {
  title: "Profil",
  url: "/mitflexii/profil"
}, {
  title: "Betaling",
  url: "/mitflexii/betaling"
}];