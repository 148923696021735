import PropTypes from "prop-types";
import { Box, Flex } from "@chakra-ui/react";
const lineSpecs = {
  position: "absolute",
  display: "block",
  width: "100%",
  background: "currentColor",
  boxShadow: "-5px 7px 20px rgba(0, 0, 0, 0.15)",
  transform: "rotate(135deg)",
  borderRadius: "30px"
};

/* Made in CSS to make it easier to animate if required */
export function CloseIcon({
  thickness = "13%",
  color = "#ffffff",
  sx,
  ...rest
}) {
  return <Flex as="span" className="close-icon" sx={{
    position: "relative",
    height: 8,
    width: 8,
    justifyContent: "center",
    alignItems: "center",
    margin: 2,
    color: color,
    ...sx
  }} {...rest} data-sentry-element="Flex" data-sentry-component="CloseIcon" data-sentry-source-file="CloseIcon.jsx">
            <Box as="span" sx={{
      ...lineSpecs,
      height: thickness || "17%"
    }} data-sentry-element="Box" data-sentry-source-file="CloseIcon.jsx" />
            <Box as="span" sx={{
      ...lineSpecs,
      height: thickness || "17%",
      transform: "rotate(-135deg)"
    }} data-sentry-element="Box" data-sentry-source-file="CloseIcon.jsx" />
        </Flex>;
}
CloseIcon.propTypes = {
  /* Provide a number in percentage for the close lines thicknesses. */
  thickness: PropTypes.string,
  sx: PropTypes.shape({
    /* Set custom height & width to resize the close icon */
    height: PropTypes.any,
    width: PropTypes.any
  })
};