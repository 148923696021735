import * as React from "react";
import PropTypes from "prop-types";
export function ExclamationPointIcon() {
  return <svg height={26} width={24} x={10} y={10} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" xmlSpace="preserve" data-sentry-element="svg" data-sentry-component="ExclamationPointIcon" data-sentry-source-file="ExclamationPointIcon.jsx">
            <g transform="translate(0, 10)" data-sentry-element="g" data-sentry-source-file="ExclamationPointIcon.jsx">
            <path d="M50 5C25.1 5 5 25.1 5 50s20.1 45 45 45 45-20.1 45-45S74.9 5 50 5zm5.6 72c0 3.2-2.5 5.6-5.6 5.6-3.1 0-5.5-2.5-5.5-5.6v-1.1c0-3.1 2.5-5.6 5.5-5.6 3.1 0 5.6 2.5 5.6 5.6V77zm2.7-50.7-2.8 34.2c-.3 3.2-3 5.4-6.1 5.2-2.8-.2-4.9-2.5-5.1-5.2l-2.8-34.2c-.5-4.5 3.7-8.9 8.2-8.9 5 0 9 4.4 8.6 8.9z" fill="red" data-sentry-element="path" data-sentry-source-file="ExclamationPointIcon.jsx" />
            
            
            </g>

        </svg>;
}
ExclamationPointIcon.propTypes = {
  fill: PropTypes.string
};