import { Box, Flex, Spinner, Text } from "@chakra-ui/react";
import PropTypes from "prop-types";
export function LoadingSpinner({
  label,
  ...rest
}) {
  return <Flex sx={{
    padding: 6,
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    color: "text",
    ...rest
  }} data-sentry-element="Flex" data-sentry-component="LoadingSpinner" data-sentry-source-file="LoadingSpinner.jsx">
            <Box data-sentry-element="Box" data-sentry-source-file="LoadingSpinner.jsx">
                <Spinner data-sentry-element="Spinner" data-sentry-source-file="LoadingSpinner.jsx" />
                {label && <Text sx={{
        fontWeight: "bold",
        marginBottom: 0
      }}>
                        {label}
                    </Text>}
            </Box>
        </Flex>;
}
LoadingSpinner.propTypes = {
  label: PropTypes.string
};