import PropTypes from "prop-types";
import { Box, Circle, Divider, Stack, Text } from "@chakra-ui/react";
function TimelineStep({
  isLastStep,
  title,
  description
}) {
  return <Stack spacing={4} direction="row" data-sentry-element="Stack" data-sentry-component="TimelineStep" data-sentry-source-file="Timeline.jsx">
            <Stack spacing="0" align="center" data-sentry-element="Stack" data-sentry-source-file="Timeline.jsx">
                <Circle size={2} sx={{
        backgroundColor: "text"
      }} data-sentry-element="Circle" data-sentry-source-file="Timeline.jsx" />
                {!isLastStep && <Divider orientation="vertical" sx={{
        borderWidth: "1px",
        borderColor: "gray.lighter"
      }} />}
            </Stack>

            <Stack spacing="0" sx={{
      position: "relative",
      top: -1.5,
      paddingBottom: isLastStep ? "0" : "8"
    }} data-sentry-element="Stack" data-sentry-source-file="Timeline.jsx">
                <Text as="h6" sx={{
        fontSize: "14px",
        color: "text",
        fontWeight: "black",
        marginBottom: 0
      }} data-sentry-element="Text" data-sentry-source-file="Timeline.jsx">
                    {title}
                </Text>
                <Text sx={{
        color: "text",
        fontWeight: "bold"
      }} className="mask-me" data-sentry-element="Text" data-sentry-source-file="Timeline.jsx">
                    {description}
                </Text>
            </Stack>
        </Stack>;
}

/**
 * Timeline component
 * Showing stepItems in a vertical time line, connected with a line and dots.
 */
export function Timeline({
  stepItems,
  ...rest
}) {
  return <Box {...rest} data-sentry-element="Box" data-sentry-component="Timeline" data-sentry-source-file="Timeline.jsx">
            {stepItems.map((step, idx) => <TimelineStep key={step.title} title={step.title} description={step.description} isLastStep={stepItems.length === idx + 1} />)}
        </Box>;
}
Timeline.propTypes = {
  stepItems: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.node.isRequired
  })).isRequired
};