import { useState, useEffect, useMemo } from "react";
import { useRouter } from "next/router";
import { useStore } from "@/store";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useSession } from "next-auth/react";
import { useQuery } from "@tanstack/react-query";
import { Box, Checkbox, Heading, Text, Link, Flex, Button, useBreakpointValue, Container } from "@chakra-ui/react";
import { useHookFormMask } from "use-mask-input";
import { apiFlexii, dataLayerUserIdlocalStorageId } from "@/services/client";
import { gtmPrepareAuthStatusForTracking } from "@/services/client";
import { useIsLocalhost } from "@/hooks/useIsLocalhost";
import { useMicrocopy } from "@/hooks/useMicrocopy";
import { destructureEmail } from "@/utils";
import { stepRoutes } from "@/pages/checkout/[[...step]]";
import { pushLoginToDataLayer } from "@/pages/_app";
import { ArrowLeftIcon, ArrowRightIcon } from "@/components/icons";
import { LoadingSpinner } from "@/components/primitives/LoadingSpinner";
import { Input, AddressInput, PhoneNumberInput } from "@/components/primitives/Input";
import { UserDetailsCard, UserDetailsCardItem } from "./components/UserDetailsCard";

/**
 * Step 2 - SubStep 2 - User logged in, request missing user information
 */
export function Step2({
  globalSettings,
  trackingData
}) {
  const {
    getMicrocopy
  } = useMicrocopy();
  const isLocalhost = useIsLocalhost();
  const router = useRouter();
  const {
    data: session
  } = useSession();
  const [userDetails, setUserDetails] = useState(null);
  const [checkoutSession, setCheckoutSession] = useStore(state => [state.checkout.checkoutSession, state.checkout.setCheckoutSession]);
  const [currentAdvertisingConsent, setCurrentAdvertisingConsent] = useState(null);
  const {
    register,
    handleSubmit,
    formState: {
      errors,
      isValid
    },
    setValue
  } = useForm({
    resolver: yupResolver(yup.object({
      ...(userDetails?.privateAddress && {
        // Only include if user has private address
        firstName: yup.string().label("Fornavn").required(),
        lastName: yup.string().label("Efternavn").required(),
        customAddress: yup.object().label("Adresse").required("${path} er ikke blevet udfyldt korrekt. Søg efter din adresse og vælg den under listen af resultater")
      }),
      ...(!userDetails?.existingCustomer && {
        // Only include for new customers
        email: yup.string().label("E-mail").email().test("isValidGmail", "Hovsa! Mente du ikke gmail.com i stedet for dét du har skrevet?", value => {
          const destructuredEmail = destructureEmail(value);

          // Handle erroneous GMAIL domains that don't use `gmail.com`
          // See corresponding ticket: PX-542
          if (destructuredEmail?.secondLevelDomain === "gmail") {
            return destructuredEmail.topLevelDomain === "com";
          }
          return true;
        }).required(),
        mailMatch: yup.string().label("E-mail").email().oneOf([yup.ref("email"), null], "Kontroller at dine emails er ens").required(),
        phone: yup.string().label("Kontaktnummer").required().transform((value, originalValue) => {
          // Remove non-number characters
          return originalValue ? originalValue.replace(/\D/g, "") : value;
        }).matches(/^[0-9]+$/, "Du må kun bruge tal").min(8, "Dit kontaktnummer er for kort").max(8, "Dit kontaktnummer er for langt")
      }),
      terms: yup.boolean().oneOf([true], "Du mangler at acceptere vores betingelser")
    }))
  });
  const registerWithMask = useHookFormMask(register);
  // Get their current consent status.
  const advertisingConsentQuery = useQuery({
    queryKey: ["advertising-consent"],
    retry: 0,
    // Otherwise the advertisement consent text will pop up after a long delay for new users.
    queryFn: () => apiFlexii.get(`v1/protected/resource/consent`).then(res => res.data)
  });
  const billingGroupId = userDetails?.billingGroupId;

  // Fetch this now so it's ready for the next step
  useQuery({
    queryKey: ["recharge", billingGroupId],
    queryFn: () => apiFlexii.post("v1/protected/resource/get-recharge-ticket", {
      billingGroupId
    }).then(res => res.data),
    onSuccess: ({
      rechargeTicketType,
      rechargeTicket: {
        lastDigits
      }
    }) => {
      setCheckoutSession({
        ...checkoutSession,
        paymentProvider: rechargeTicketType,
        creditCardDigits: lastDigits
      });
    },
    enabled: !!billingGroupId,
    refetchOnWindowFocus: false,
    staleTime: "infinity"
  });

  // eslint-disable-next-line no-unused-vars
  useQuery({
    queryKey: ["userDetails"],
    queryFn: () => apiFlexii.get("v1/protected/resource/cpr-details").then(res => res.data),
    onSuccess: data => setUserDetails({
      ...data
    }),
    onError: error => {
      if (error.response?.status === 403) {
        router.push("/checkout/error?e=692");
      } else if (error.response?.status === 418) {
        router.push("/checkout/error?e=695");
      } else if (error.response?.status === 500 && error.response?.data?.errorId === 812) {
        router.push("/checkout/error?e=812");
      } else {
        router.push("/checkout/error?e=689");
      }
    },
    refetchOnWindowFocus: false,
    staleTime: "infinity"
  });

  /**
   * Push e-commerce tracking data to DataLayer
   * Since we only support online products, this is run on load
   */
  useEffect(() => {
    if (!router.isReady) return undefined;
    if (trackingData?.ecommerce) {
      const trackingUserId = localStorage.getItem(dataLayerUserIdlocalStorageId);
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        ecommerce: null
      }); // Clear the previous ecommerce object.
      window.dataLayer.push({
        event: "add_shipping_info",
        loginStatus: !!trackingUserId,
        ecommerce: {
          ...trackingData.ecommerce,
          shipping_tier: "Online"
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.isReady]);
  useEffect(() => {
    if (!router.isReady || !session) return undefined;
    (async () => {
      await gtmPrepareAuthStatusForTracking(); // set tracking user id in localstorage
      pushLoginToDataLayer();
    })();
  }, [session, router]);
  const handleFormSubmit = formData => {
    setCheckoutSession({
      ...checkoutSession,
      user: {
        ...checkoutSession.user,
        accountId: userDetails.accountId,
        firstName: userDetails.firstName || formData.firstName,
        lastName: userDetails.lastName || formData.lastName,
        personalId: userDetails.cpr,
        gender: userDetails.gender,
        streetName: userDetails.streetName || formData.customAddress?.data.vejnavn,
        houseNumber: userDetails.houseNumber || formData.customAddress?.data.husnr,
        floor: userDetails.floor || formData.customAddress?.data.etage,
        buildingNumber: userDetails.buildingNumber,
        sideNumber: userDetails.sideNumber || formData.customAddress?.data.dør,
        zip: userDetails.zip || formData.customAddress?.data.postnr,
        city: userDetails.city || formData.customAddress?.data.postnrnavn,
        email: userDetails.email || formData.email,
        phone: userDetails.phoneNumber || formData.phone
      },
      advertisingConsent: formData.advertisingConsent
    });
    router.push(nextStep);
  };
  const {
    customAddress
  } = register("customAddress");
  const buttonSize = useBreakpointValue({
    base: "full",
    md: ""
  });
  const previousStep = useMemo(() => ({
    query: {
      step: stepRoutes[0].path
    }
  }), []);
  const nextStep = useMemo(() => ({
    query: {
      step: stepRoutes[2].path
    }
  }), []);

  // Render Loading spinner until we have user details
  if (!userDetails) {
    return <LoadingSpinner label={getMicrocopy("global.api", "apiLoading", {
      fallback: "Henter dine oplysninger"
    })} width="full" />;
  }
  if (typeof session === "undefined") {
    <Container maxWidth="container.sm" sx={{
      padding: 0
    }}>
            <LoadingSpinner />
        </Container>;
  }
  const {
    privateAddress,
    existingCustomer
  } = userDetails;
  if (advertisingConsentQuery.isSuccess) {
    const {
      currentConsent
    } = advertisingConsentQuery.data;
    if (currentAdvertisingConsent === null) {
      setCurrentAdvertisingConsent(currentConsent);
    }
  } else if (advertisingConsentQuery.isError) {
    if (currentAdvertisingConsent === null) {
      setCurrentAdvertisingConsent(false);
    }
  }
  return <Box data-sentry-element="Box" data-sentry-component="Step2" data-sentry-source-file="Step2.jsx">
            <Box sx={{
      marginBottom: 6
    }} data-sentry-element="Box" data-sentry-source-file="Step2.jsx">
                <Heading as="h3" sx={{
        marginBottom: 2
      }} data-sentry-element="Heading" data-sentry-source-file="Step2.jsx">
                    {privateAddress ? <>
                            {getMicrocopy("checkout.step2", "oplysninger.viManglerLidt", {
            fallback: "Vi mangler stadig lidt oplysninger om dig"
          })}{" "}
                        </> : <>
                            {getMicrocopy("checkout.step2", "oplysninger.viHarDetHer", {
            fallback: "Vi har fået disse oplysninger om dig"
          })}
                        </>}
                </Heading>
                <Text sx={{
        fontWeight: "black",
        marginBottom: 0
      }} data-sentry-element="Text" data-sentry-source-file="Step2.jsx">
                    {privateAddress ? <>
                            {getMicrocopy("checkout.step2", "oplysninger.viManglerLidt.hemmeligAdresse", {
            fallback: `Da du er registreret med hemmelig adresse mangler vi
                                            nedenstående oplysninger for at kunne sende dig
                                            simkortet samt ordrebekræftelsen`
          })}
                        </> : <>
                            {getMicrocopy("checkout.step2", "oplysninger.simkortLeveresHer", {
            fallback: "Dit nye simkort leveres til nedenstående adresse."
          })}
                        </>}
                </Text>
            </Box>

            {!privateAddress &&
    // If user has private address, we get no data to present from CPR registery
    <UserDetailsCard {...userDetails} childrenBefore={<UserDetailsCardItem headline="Mobilnummer">
                            {checkoutSession.anumber.reservationToken ? checkoutSession?.anumber?.anumber : checkoutSession?.anumberImportRequest?.phoneNumber}
                        </UserDetailsCardItem>} />}

            <form onSubmit={handleSubmit(handleFormSubmit)}>
                {privateAddress && <>
                        <Input label="Fornavn" errorMessage={errors?.firstName?.message ?? null} {...register("firstName")} />

                        <Input label="Efternavn" errorMessage={errors?.lastName?.message ?? null} {...register("lastName")} />

                        <AddressInput label="Adresse"
        // Must be named something random to prevent Chrome from showing suggestions on top of the dawa dropdown
        {...customAddress} onAddressSelect={address => {
          setValue("customAddress", address, {
            shouldValidate: true
          });
        }} errorMessage={errors?.customAddress?.message ?? null} />
                    </>}

                {!existingCustomer && <>
                        <PhoneNumberInput label="Kontaktnummer" errorMessage={errors?.phone?.message ?? null} {...registerWithMask("phone", "99 99 99 99", {
          placeholder: " "
        })} />

                        <Input label="Mail" errorMessage={errors?.email?.message ?? null} {...register("email")} />
                        <Input label="Gentag mail"
        // Cannot be named emailmatch as this will make chrome autofill the fields :/
        errorMessage={errors?.mailMatch?.message ?? null} {...register("mailMatch")} inputProps={isLocalhost ? null : {
          onPaste: event => {
            event.preventDefault();
            return false;
          }
        }} />
                    </>}

                <Checkbox isInvalid={errors?.terms ?? null} {...register("terms")} sx={{
        fontWeight: "black"
      }} data-test-id="checkbox_accept-tos" data-sentry-element="Checkbox" data-sentry-source-file="Step2.jsx">
                    {getMicrocopy("checkout.step2", "abonnementsvilkår.læst", {
          fallback: "Jeg har læst og accepterer"
        })}{" "}
                    <Text as="span" sx={{
          textDecoration: "underline"
        }} data-sentry-element="Text" data-sentry-source-file="Step2.jsx">
                        <a href={globalSettings.fields.consentUrl} target="_blank" rel="noreferrer">
                            {getMicrocopy("checkout.step2", "abonnementsvilkår", {
              fallback: "abonnementsvilkår"
            })}
                        </a>
                    </Text>
                </Checkbox>

                {currentAdvertisingConsent === false && <Checkbox isInvalid={errors?.advertisingConsent ?? null} {...register("advertisingConsent")} defaultChecked={checkoutSession.advertisingConsent}>
                        {" "}
                        {getMicrocopy("checkout.step2", "samtykke.nyhedsbrev", {
          fallback: `Ja tak til at Flexii må kontakte mig via email, SMS eller
                                    opkald på mobilos, så jeg kan få nys om de nyeste nyheder og
                                    tilbud på telefon- og teleprodukter, teleelektronik og
                                    mobiltilbehør fra Flexii! Jeg kan altid trække mit samtykke
                                    tilbage ved at kontakte Kundeservice på
                                    kundeservice@flexii.dk, hvis jeg skulle ændre mening! Flexii
                                    behandler mine personoplysninger i forbindelse med mit
                                    samtykke til markedsføring. Jeg kan læse mere om Flexiis
                                    behandling`
        })}{" "}
                        <Text as="span" sx={{
          textDecoration: "underline"
        }}>
                            <Link href="/persondatapolitik" target="_blank" rel="noreferrer">
                                her
                            </Link>
                        </Text>
                    </Checkbox>}

                {/* Footer */}
                <Flex sx={{
        justifyContent: "center",
        gap: 5,
        flexWrap: "wrap",
        marginTop: 8,
        gridTemplateColumns: [null, null, "repeat(3, 1fr)"],
        flexDirection: ["column-reverse", null, "row"]
      }} data-sentry-element="Flex" data-sentry-source-file="Step2.jsx">
                    <Button variant="ghost" onClick={() => router.push(previousStep)} size={buttonSize} leftIcon={<ArrowLeftIcon />} sx={{
          margin: 0
        }} data-sentry-element="Button" data-sentry-source-file="Step2.jsx">
                        {getMicrocopy("global", "tilbage", {
            fallback: "Tilbage"
          })}
                    </Button>

                    <Button type="submit" onClick={() => router.push(nextStep)} size={buttonSize} rightIcon={<ArrowRightIcon />} sx={{
          margin: 0
        }} data-test-id="button_continue-to-step-3" isDisabled={!isValid} data-sentry-element="Button" data-sentry-source-file="Step2.jsx">
                        {getMicrocopy("global", "fortsæt", {
            fallback: "Fortsæt"
          })}
                    </Button>
                </Flex>
            </form>
        </Box>;
}