/**
 * Destructure an email address into components.
 *
 * @example
 *          console.log(destructureEmail("user@gmail.com"));
 *          // Outputs:
 *          // {
 *           //    user: "user",
 *          //    domain: "gmail.com",
 *          //    topLevelDomain: "com",
 *          //    secondLevelDomain: "gmail",
 *          // };
 *
 * @param {string} email
 * @returns object
 */
export function destructureEmail(email) {
    if (!email) {
        return null;
    }
    const atIndex = email.indexOf("@");
    if (atIndex === -1) {
        return null; // Invalid email format (missing @ symbol)
    }

    const user = email.substring(0, atIndex);
    const domain = email.substring(atIndex + 1);

    const dotIndex = domain.lastIndexOf(".");
    if (dotIndex === -1) {
        return null; // Not a internet email format, maybe intranet (missing dot)
    }

    const topLevelDomain = domain.substring(dotIndex + 1);
    const secondLevelDomain = domain.substring(0, dotIndex);

    return {
        user: user,
        domain: domain,
        topLevelDomain: topLevelDomain,
        secondLevelDomain: secondLevelDomain,
    };
}
