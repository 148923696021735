import PropTypes from "prop-types";
import { useSession } from "next-auth/react";
import { Button, Flex } from "@chakra-ui/react";
import { AnimatePresence, motion } from "framer-motion";
import { BurgerMenuIcon } from "@/components/icons";
import { SignInOutButton } from "@/components/primitives/SignInOutButton";
import { GradientBackground } from "@/components/primitives/GradientBackground";
import { PadlockButton } from "./PadlockButton";
export function MobileNavigation({
  children,
  isMobileMenuOpen,
  setIsMobileMenuOpen
}) {
  const {
    data: session
  } = useSession();
  return <>
            <Flex sx={{
      gap: 5
    }} data-sentry-element="Flex" data-sentry-source-file="MobileNavigation.jsx">
                <PadlockButton data-sentry-element="PadlockButton" data-sentry-source-file="MobileNavigation.jsx" />
                <BurgerMenuIcon isOpen={isMobileMenuOpen} onClick={() => setIsMobileMenuOpen(prevState => !prevState)} sx={{
        position: "relative",
        zIndex: isMobileMenuOpen ? "popover" : null,
        justifySelf: "flex-end"
      }} data-sentry-element="BurgerMenuIcon" data-sentry-source-file="MobileNavigation.jsx" />
            </Flex>

            <AnimatePresence data-sentry-element="AnimatePresence" data-sentry-source-file="MobileNavigation.jsx">
                {isMobileMenuOpen && <Flex key="navigation" as={motion.div} className="main-navigation" initial={{
        opacity: 0
      }} animate={{
        opacity: 1
      }} exit={{
        opacity: 0
      }} sx={{
        paddingTop: 10,
        flex: "1 1 100%",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: 5
      }}>
                        {children}

                        {session && <SignInOutButton modalProps={{
          blockScrollOnMount: false // TODO: is this still relevant? Only when multiple "modals" are open
        }}>
                                <Button variant="action" sx={{
            borderRadius: "50px",
            marginTop: 10,
            paddingY: 3,
            paddingX: 20
          }}>
                                    Log ud
                                </Button>
                            </SignInOutButton>}
                    </Flex>}

                {isMobileMenuOpen && <GradientBackground key="background" initial={{
        opacity: 0
      }} animate={{
        opacity: 1
      }} exit={{
        opacity: 0
      }} sx={{
        position: "fixed",
        left: 0
      }} />}
            </AnimatePresence>
        </>;
}
MobileNavigation.propTypes = {
  children: PropTypes.node.isRequired
};