import { useRef } from "react";
import PropTypes from "prop-types";
import { Swiper, SwiperSlide } from "swiper/react";
import { motion } from "framer-motion";
import { Box, Button, Flex, Text, useToken } from "@chakra-ui/react";
import { SwiperNavigationBtn } from "./SwiperNavigationBtn";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { LoadingSpinner } from "@/components/primitives/LoadingSpinner";
import { convertRemToPixels } from "@/utils";

/**
 * Caroussel to display available numbers over multiple pages.
 */
export function AvailableNumbersCarousel({
  handleNumberReserve,
  availableNumbersPages,
  loadingText
}) {
  const swiperRef = useRef(null);
  const hasOptions = !!availableNumbersPages?.length;
  const spaceBetweenSlides = convertRemToPixels(useToken("sizes", 4));
  return !loadingText ? <Flex sx={{
    flexWrap: "wrap",
    position: "relative",
    ".swiper-pagination": {
      bottom: 0,
      ".swiper-pagination-bullet": {
        opacity: 0.2,
        "&-active": {
          opacity: 1,
          backgroundColor: "text"
        }
      }
    }
  }} data-sentry-element="Flex" data-sentry-component="AvailableNumbersCarousel" data-sentry-source-file="AvailableNumbersCarousel.jsx">
            <Swiper ref={swiperRef} modules={[Pagination]} pagination={{
      clickable: true,
      dynamicBullets: true,
      dynamicMainBullets: 3
    }} loop={true} slidesPerView={1} spaceBetween={spaceBetweenSlides} data-sentry-element="Swiper" data-sentry-source-file="AvailableNumbersCarousel.jsx">
                {availableNumbersPages.map((numbersArr, idx) => <SwiperSlide key={`availableNumbersPage${idx}`}>
                        <Box sx={{
          paddingY: 6,
          gap: 4,
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)"
        }} data-test-id="available-numbers-carousel">
                            {numbersArr.map(number => <Button key={number} type="button" as={motion.button} onClick={() => handleNumberReserve(number)} sx={{
            margin: 0,
            padding: 4,
            fontSize: "base",
            background: "yellow"
          }} whileHover={{
            boxShadow: "0px 5px 10px -5px rgba(0,0,0, 0.5)"
          }} className="mask-me">
                                    {number}
                                </Button>)}
                        </Box>
                    </SwiperSlide>)}
            </Swiper>

            {hasOptions ? <Box sx={{
      position: "absolute",
      display: "flex",
      width: `calc(100% + 48px)`,
      height: "full",
      justifyContent: "space-between",
      alignItems: "center",
      marginLeft: "-24px"
    }}>
                    <SwiperNavigationBtn handleClick={() => swiperRef.current.swiper.slidePrev()} />
                    <SwiperNavigationBtn handleClick={() => swiperRef.current.swiper.slideNext()} isNext />
                </Box> : <Text sx={{
      width: "full",
      fontWeight: "bold"
    }}>
                    Der er ingen numre inden for din søgning. Prøv igen!
                </Text>}
        </Flex> : <LoadingSpinner label={loadingText} data-sentry-element="LoadingSpinner" data-sentry-component="AvailableNumbersCarousel" data-sentry-source-file="AvailableNumbersCarousel.jsx" />;
}
AvailableNumbersCarousel.propTypes = {
  handleNumberReserve: PropTypes.func.isRequired,
  availableNumbersPages: PropTypes.array,
  loadingText: PropTypes.string
};