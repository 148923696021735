import { useRouter } from "next/router";
import { useState, useEffect } from "react";
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, Text } from "@chakra-ui/react";

/**
 * Breadcrumb code inspired from "nextjs-breadcrumbs" source code.
 * @see https://github.com/NiklasMencke/nextjs-breadcrumbs/blob/main/src/index.tsx
 */

/**
 * Takes an URL String and removes query params and hash params
 *
 * @param url - The URL string
 * @returns The transformed URL string
 */
const getPathFromUrl = url => {
  return url.split(/[#?]/)[0];
};

/**
 * Takes a breadcrumb title (from url path) and replaces
 * special chars to more readable chars
 *
 * @returns The transformed title or the result of the custom transformLabel function
 */
const convertBreadcrumb = breadcrumb => {
  let transformedTitle = getPathFromUrl(breadcrumb);
  const replaceCharacterList = [{
    from: "-",
    to: " "
  }, {
    from: ".",
    to: " "
  }, {
    from: "_",
    to: " "
  }];
  for (const element of replaceCharacterList) {
    transformedTitle = transformedTitle.replaceAll(element.from, element.to);
  }

  // decode for utf-8 characters and return ascii.
  return decodeURI(transformedTitle);
};
export function Breadcrumbs() {
  const router = useRouter();
  const [breadcrumbs, setBreadcrumbs] = useState(null);
  useEffect(() => {
    if (router) {
      const linkPath = router.asPath.split("/");
      linkPath.shift();
      const pathArray = linkPath.map((path, index) => {
        return {
          breadcrumb: path,
          href: `/${linkPath.slice(0, index + 1).join("/")}`
        };
      });
      setBreadcrumbs(pathArray);
    }
  }, [router]);
  if (!breadcrumbs) return null;
  return <Breadcrumb marginBottom={3} separator={<Text sx={{
    fontSize: ".8rem",
    opacity: ".6"
  }}>
                    &gt;
                </Text>} spacing="8px" data-sentry-element="Breadcrumb" data-sentry-component="Breadcrumbs" data-sentry-source-file="Breadcrumbs.jsx">
            {breadcrumbs.length > 0 && breadcrumbs.map(breadcrumb => <BreadcrumbItem key={breadcrumb.href}>
                        <BreadcrumbLink href={breadcrumb.href} sx={{
        textTransform: "capitalize",
        opacity: ".7"
      }}>
                            {convertBreadcrumb(breadcrumb.breadcrumb)}
                        </BreadcrumbLink>
                    </BreadcrumbItem>)}
        </Breadcrumb>;
}