import PropTypes from "prop-types";
import NextLink from "next/link";
import { colors } from "../../../theme/foundations/colors";
import { LogoIcon } from "../../icons/LogoIcon";
import { Link } from "@chakra-ui/react";
export function Logo({
  color,
  ...rest
}) {
  return <Link as={NextLink} href="/" {...rest} className="main-logo" data-sentry-element="Link" data-sentry-component="Logo" data-sentry-source-file="Logo.jsx">
            <LogoIcon fill={color} data-sentry-element="LogoIcon" data-sentry-source-file="Logo.jsx" />
        </Link>;
}
Logo.propTypes = {
  color: PropTypes.string
};
Logo.defaultProps = {
  color: colors.white
};