import { Button, Input } from "@/theme/components";

const reusedTextStyle = {
    color: "text",
    fontWeight: "bold",
};

export const AdyenDropInPaymentStyle = {
    ".adyen-checkout__payment-method, .adyen-checkout__status": {
        /* Payment method container */
        ...reusedTextStyle,
        backgroundColor: "gray.lightest",
        borderRadius: "card",
        border: "none",
        padding: 2,
    },
    ".adyen-checkout__payment-method--selected": {
        /* Payment method that has been selected */
        ...reusedTextStyle,
    },
    ".adyen-checkout__payment-method__header": {
        /* Payment method icon and name */
        ...reusedTextStyle,
    },
    ".adyen-checkout__payment-method__radio": {
        /* Radio button in payment method header */
        ...reusedTextStyle,
    },
    ".adyen-checkout__payment-method__radio--selected": {
        /* Selected radio button in payment method header */
    },
    ".adyen-checkout__payment-method__name": {
        /* Payment method name in the payment method header */
        ...reusedTextStyle,
        fontWeight: "black",
    },
    ".adyen-checkout__spinner__wrapper": {
        /* Spinning icon wrapper */
        ...reusedTextStyle,
    },
    ".adyen-checkout__spinner": {
        /* Spinning icon */
        ...reusedTextStyle,
        borderColor: "text",
        borderTopColor: "transparent",
    },
    ".adyen-checkout__button": {
        /* Buttons */
        ...Button.baseStyle,
        ...Button.variants.action,
        // fontSize: "base",
        ...reusedTextStyle,
        height: "auto",
    },
    ".adyen-checkout__button__icon": {
        ...reusedTextStyle,
        display: "none",
    },
    ".adyen-checkout__button__text": {
        ...reusedTextStyle,
        fontWeight: "black",
    },
    ".adyen-checkout__button--pay": {
        /* Pay button */
        ...reusedTextStyle,
        fontWeight: "black",
    },
    ".adyen-checkout__field": {
        /* Form field container */
        ...reusedTextStyle,
    },
    ".adyen-checkout__label": {
        /* Form label container */
        ...reusedTextStyle,
    },
    ".adyen-checkout__label__text": {
        /* Text element inside the form label container */
        ...reusedTextStyle,
        fontSize: "h5",
        paddingBottom: 2,
    },
    ".adyen-checkout__input": {
        /* Input fields */
        ...Input.baseStyle.field,
        ...reusedTextStyle,
        paddingX: 2,
        borderWidth: "2px",
    },
    ".adyen-checkout__input--error": {
        /* Error state for the input fields */
    },
    ".adyen-checkout__error-text": {
        /* Error message text */
    },
    ".adyen-checkout__card__cardNumber__input": {
        /* Input field for the card number */
        ...reusedTextStyle,
    },
    ".adyen-checkout__field--expiryDate": {
        /* Input field for the expiry date */
        ...reusedTextStyle,
    },
    ".adyen-checkout__field__cvc": {
        /* Input field for the CVC security code */
        ...reusedTextStyle,
    },
    ".adyen-checkout__card__holderName": {
        /* Input field for cardholder name */
        ...reusedTextStyle,
    },
    ".adyen-checkout__checkbox__input": {
        /* Checkboxes */
        ...reusedTextStyle,
    },
    ".adyen-checkout__checkbox__label": {
        /* Checkbox labels */
        ...reusedTextStyle,
    },
    ".adyen-checkout__radio_group__input": {
        /* Radio buttons */
        ...reusedTextStyle,
    },
    ".adyen-checkout__dropdown__button": {
        /* Dropdown button showing list of options */
        ...reusedTextStyle,
    },
    ".adyen-checkout__dropdown__list": {
        /* Dropdown list */
        ...reusedTextStyle,
    },
    ".adyen-checkout__dropdown__element": {
        /* Elements in the dropdown list */
        ...reusedTextStyle,
    },
    ".adyen-checkout__link": {
        /* Links */
        ...reusedTextStyle,
    },

    // Setting custom iframe size for 3ds challenge as the values from Adyens challengeWindowSize does not suit our needs
    // Going below 380px in width will trigger the Nets iFrame breakpoint which makes the content
    // ultra tiny with both horizontal and vertical scrollbars
    // Please note that this fix is not futureproof as we rely iframe content :-(
    ".adyen-checkout__threeds2__challenge--02, .adyen-checkout__threeds2__challenge--02 .adyen-checkout__iframe--threeDSIframe": {
        width: "100%",
        height: "560px", // Exactly fitting for the Nets window
        minWidth: "auto",
    }
};
