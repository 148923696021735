import { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { motion } from "framer-motion";
import Image from "next/image";
import { Box, Flex, Grid, GridItem, Heading, Text, useToken, chakra } from "@chakra-ui/react";

// import { tikTokApi } from "@/services/client/tikTokApi";
import { useHasAdblocker } from "@/hooks";
import { Section } from "@/components/layouts/Section";
import { TikTokIcon } from "@/components/icons/TikTokIcon";
import { Modal } from "@/components/primitives/Modals";

/**
 * Show 3 tik tok thumbnails in the hero and open an embeded tiktok player in a modal on click
 *
 * Currently, tt Data is fetched using oEmbed endpoint: https://developers.tiktok.com/doc/embed-videos/
 * This is a temporary implementation as it's not optimal and may end beeing rate-limited.
 * In a future implementation, we'll use tiktok's official API (v2) to fetch a users latest content.
 *
 * Videos are directly embeded with the embed url https://www.tiktok.com/embed/v2/${videoId}?lang=en-GB, instead
 * of the embeded code (can be retrieved in the share option of a tt video), because the embedded code grants
 * access to tt to access page data (privacy!).
 *
 * It's strongly recommended to not allow more than 3 tiktok videos in the hero due to performance reasons
 *
 * @param {array} ttVideos A list of tiktok urls in following format "https://www.tiktok.com/@dog/video/7164779366004690222"
 */
export function TikTokHero({
  ttVideos,
  sectionStyling,
  headline,
  description
}) {
  const hasAdblocker = useHasAdblocker(); // use has an adblocker or not
  const [cardBorderRadius] = useToken("borderRadius", ["radii.card"]);
  const [ttData, setTTData] = useState([]); // all fetched video data
  const [displayTTModal, setDisplayTTModal] = useState(false); // show/hide embeded tiktok player modal
  const [currentEmbededTT, setCurrentEmbededTT] = useState({}); // the current tiktok dataset to show in the modal

  const checkAndScrollToAnchor = useCallback(() => setTimeout(() => {
    // If a user hits the page with an anchor link below a component with layout shifts,
    // we need to relocate the scrollvalue...
    if (window.location.hash) {
      const hashedElement = document.querySelector(window.location.hash);
      if (!hashedElement) return undefined;
      hashedElement.scrollIntoView();
      hashedElement.focus();
    }
  }, 500), []);

  // Initialize page
  useEffect(() => {
    // Disallow more than 3 videos
    if (ttVideos.length > 3) {
      throw new Error("TikTokHero has too many embedded videos. Maximum allowed 3 videos to preview and embed");
    }

    // Fetch initial data and set in state
    (async () => {
      // fetch all tt data concurrently (≈ parallel)
      const fetchedTTData = await Promise.all(ttVideos.map(ttVideo => getTTData(ttVideo)));
      const formatedData = [];
      ttVideos.forEach((ttVideo, idx) => {
        const [,
        // a comma "," skips the first element during destructuring
        // eslint-disable-next-line no-unused-vars
        author, videoId] = /tiktok.com\/(@[\w.-]+)\/video\/(\d+)/.exec(ttVideo);
        formatedData.push({
          url: ttVideo,
          embed_url: `https://www.tiktok.com/embed/v2/${videoId}?lang=en-GB`,
          ...fetchedTTData[idx]
        });
      });
      setTTData(formatedData);
      checkAndScrollToAnchor();
    })();
  }, [checkAndScrollToAnchor, ttVideos]);

  /**
   * Fetch TikTok Data using the oEmbed endpoint
   * @param {string} link In format "https://www.tiktok.com/@dog/video/7164779366004690222"
   * @returns {object}
   */
  const getTTData = async link => {
    const resp = await axios.get(`https://www.tiktok.com/oembed?url=${link}`);
    return resp.data;
  };

  /**
   * Open the tt embeded video player modal and set the curren active tt video inside the modal
   * @param {object} item
   */
  const openTTModal = item => {
    setDisplayTTModal(true);
    setCurrentEmbededTT(item);
  };
  return <>
            <Section sectionStyling={sectionStyling} sx={{
      paddingY: 10,
      flex: 1
    }} data-sentry-element="Section" data-sentry-source-file="TikTokHero.jsx">
                <Section.Content sx={{
        display: ["block"]
      }} data-sentry-element="unknown" data-sentry-source-file="TikTokHero.jsx">
                    <Flex sx={{
          flexDirection: "column",
          justifyContent: "center",
          color: "white",
          marginBottom: 10
        }} data-sentry-element="Flex" data-sentry-source-file="TikTokHero.jsx">
                        {headline && <Heading as="h2" sx={{
            textAlign: "center",
            fontSize: "60px"
          }}>
                                {headline}
                            </Heading>}

                        {description && <Heading as="h3" sx={{
            textAlign: "center"
          }}>
                                {description}
                            </Heading>}
                    </Flex>

                    <Grid templateColumns={["repeat(1, 1fr)", null, "repeat(2, 1fr)", null, "repeat(3, 1fr)"]} gap={[12]} padding={[5, 70, 0]} data-sentry-element="Grid" data-sentry-source-file="TikTokHero.jsx">
                        {ttData.map(ttItem => <GridItem sx={{
            width: "full"
          }} key={ttItem.thumbnail_url}>
                                <Box as={motion.div} className="tiktok-preview-card" sx={{
              cursor: "pointer"
            }} whileHover={{
              scale: 1.05
            }} onClick={() => {
              openTTModal(ttItem);
            }}>
                                    <Box className="tiktok-inner-box" sx={{
                position: "relative",
                zIndex: 1,
                height: "100%",
                width: "100%",
                borderRadius: `calc(${cardBorderRadius} / 2)`,
                backgroundColor: "white",
                padding: 5
              }}>
                                        <Flex className="tiktok-header" sx={{
                  justifyContent: "space-between",
                  marginBottom: 3
                }}>
                                            <Box>
                                                <Text sx={{
                      fontSize: "h4",
                      fontWeight: "black",
                      marginBottom: 0
                    }}>
                                                    {ttItem.author_name}
                                                </Text>
                                            </Box>
                                            <TikTokIcon sx={{
                    height: "30px"
                  }} />
                                        </Flex>

                                        <Box className="tiktok-thumbnail" sx={{
                  position: "relative",
                  paddingBottom: ttItem.thumbnail_height / ttItem.thumbnail_width * 100 + "%",
                  overflow: "hidden",
                  borderRadius: `calc(${cardBorderRadius}/2)`,
                  marginBottom: 3
                }}>
                                            <Image src={ttItem.thumbnail_url} alt="" fill style={{
                    objectFit: "cover"
                  }} />
                                        </Box>

                                        <Text sx={{
                  marginBottom: 0
                }}>
                                            {ttItem.title}
                                        </Text>
                                    </Box>
                                </Box>
                            </GridItem>)}
                    </Grid>
                </Section.Content>
            </Section>

            <Modal size="md" isOpen={displayTTModal} onClose={() => {
      setDisplayTTModal(false);
    }} data-sentry-element="Modal" data-sentry-source-file="TikTokHero.jsx">
                <Modal.Body data-sentry-element="unknown" data-sentry-source-file="TikTokHero.jsx">
                    {hasAdblocker && <Text>Det er muligt, at TikTok ikke kan afspille din video p.g.a en adblocker. Sluk for den og forsøg venligst igen.</Text>}
                    <Box sx={{
          // calculate tt embeded window height - no other way to know iframe height :(
          height: `
                            calc(${currentEmbededTT.thumbnail_height / currentEmbededTT.thumbnail_width * 325}px` +
          // thumbnail height
          " + 44px " +
          // tiktok redirect banner height
          " + 122px + 14px" +
          // avg. author, description, song + bottom margin
          ")",
          maxHeight: "80vh",
          position: "relative"
        }} className="tt-iframe" data-sentry-element="Box" data-sentry-source-file="TikTokHero.jsx">
                        <chakra.iframe src={currentEmbededTT.embed_url} scrolling="yes" // allow scrolling in iframe if content is hidden
          allow="encrypted-media;" sandbox="allow-same-origin allow-scripts allow-popups allow-forms" // restrict what tiktok can do in the iframe
          sx={{
            position: "absolute",
            left: "0",
            right: "0",
            margin: "auto",
            maxWidth: "605px",
            minWidth: "325px",
            height: "100%"
          }} data-sentry-element="unknown" data-sentry-source-file="TikTokHero.jsx" />
                    </Box>
                </Modal.Body>
            </Modal>
        </>;
}
TikTokHero.propTypes = {
  ttVideos: PropTypes.arrayOf(PropTypes.string),
  sectionStyling: PropTypes.object,
  headline: PropTypes.string,
  description: PropTypes.string
};