import { Button } from "@chakra-ui/react";
import { motion } from "framer-motion";
import PropTypes from "prop-types";
import { ArrowLeftIcon, ArrowRightIcon } from "../../icons";
import { useSwiper } from "swiper/react";
export function MobileHeroSliderButton({
  isNext
}) {
  const swiperInstance = useSwiper();
  const onClick = () => {
    if (isNext) {
      swiperInstance.slideNext();
    } else {
      swiperInstance.slidePrev();
    }
  };
  return <Button type="button" as={motion.button} onClick={onClick} variant="none" sx={{
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    top: 0,
    bottom: 0,
    ...(isNext ? {
      right: 0
    } : {
      left: 0
    }),
    flex: 0,
    paddingY: 0,
    width: 16,
    margin: 0,
    zIndex: 5,
    cursor: "pointer",
    _focus: {
      outline: "none"
    }
  }} data-sentry-element="Button" data-sentry-component="MobileHeroSliderButton" data-sentry-source-file="MobileHeroSliderButton.jsx">
            {isNext ? <ArrowRightIcon fill={"white"} /> : <ArrowLeftIcon fill={"white"} />}
        </Button>;
}
MobileHeroSliderButton.propTypes = {
  isNext: PropTypes.bool
};
MobileHeroSliderButton.defaultProps = {
  isNext: false
};