import PropTypes from "prop-types";
import { Box, chakra, useBreakpointValue, useToken } from "@chakra-ui/react";
import { Swiper as SSwiper, SwiperSlide } from "swiper/react";
import { ProductCard } from "../ProductCard";
import { MobileHeroSliderButton } from "./MobileHeroSliderButton";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { useEffect, useRef, useState } from "react";
import { convertRemToPixels } from "@/utils";
const Swiper = chakra(SSwiper);

/**
 * Minimum required of positionY of any stickers before we
 * add additional padding in top
 */

export function MobileHeroSlider({
  products
}) {
  const [hasProductTabs, setHasProductTabs] = useState(false);
  const [stickerPaddingTop, setStickerPaddingTop] = useState(0);
  const space10 = convertRemToPixels(useToken("space", 10));
  const firstSwiperSlideRef = useRef();
  let isMobile = useBreakpointValue({
    base: true,
    lg: false
  });

  /**
   * Check and update paddingTop if there are stickers or product tabs
   */
  useEffect(() => {
    setHasProductTabs(products.some(obj => !!obj.props.productTab));

    // If there are any stickers, get the highest positioned sticker.
    const allStickers = products.reduce((stickersArr, product) => {
      if (product?.props?.stickers) {
        return stickersArr.concat(product.props.stickers);
      }
      return stickersArr;
    }, []);
    let highestPositionedSticker = null;
    if (allStickers.length > 0) {
      const topStickers = allStickers.filter(sticker => {
        const placement = sticker.fields.placement || "top left";
        return placement.split(" ")[0] === "top";
      });
      if (topStickers.length > 0) {
        highestPositionedSticker = topStickers.reduce((lowestSticker, currentSticker) => currentSticker.fields.positionY < lowestSticker.fields.positionY ? currentSticker : lowestSticker, topStickers[0]);
      }
    }
    setStickerPaddingTop(Math.abs(highestPositionedSticker?.fields?.positionY) - 16);
  }, [products]);
  return <Box sx={{
    ".swiper-wrapper": {
      display: "flex",
      alignItems: "stretch"
    },
    ".swiper-slide": {
      // Add additional space in top if there's any stickers (with less positionY than deltaSpaceStickers) or product tabs
      paddingTop: hasProductTabs && stickerPaddingTop ?
      // use the highest padding value if both exists
      `${Math.max(space10, stickerPaddingTop)}px` :
      // Or use one of if only one exists
      `calc(${0 + (hasProductTabs ? space10 : 0)}px + ${stickerPaddingTop}px)`,
      display: "flex",
      justifyContent: "center",
      width: "grid.columns.3",
      height: "auto"
    },
    ".swiper-pagination": {
      position: "initial",
      top: "initial",
      left: "initial",
      lineHeight: 1,
      marginTop: isMobile ? "3rem" : 4,
      ".swiper-pagination-bullet": {
        opacity: 0.2,
        "&-active": {
          opacity: 1,
          backgroundColor: "text"
        }
      }
    }
  }} data-sentry-element="Box" data-sentry-component="MobileHeroSlider" data-sentry-source-file="MobileHeroSlider.jsx">
            <Swiper spaceBetween={16} //px
    slidesPerView={"auto"} initialSlide={1} centeredSlides navigation pagination={{
      clickable: true
    }} modules={[Pagination]} sx={{
      // Add additional Y space due to overflow:hidden limitation of swiper
      marginY: -24,
      // add more space with negative margin
      paddingY: 24 // push elements back into position
    }} ref={firstSwiperSlideRef} data-sentry-element="Swiper" data-sentry-source-file="MobileHeroSlider.jsx">
                {products.map((productItem, idx) => {
        if (!productItem) return null;
        return <SwiperSlide key={productItem.key} {...idx === 0 ? {
          ref: firstSwiperSlideRef
        } : {}}>
                            {({
            isActive
          }) => <ProductCard context="hero" {...productItem.props} isActive={isActive} />}
                        </SwiperSlide>;
      })}

                <span slot="container-end">
                    <MobileHeroSliderButton data-sentry-element="MobileHeroSliderButton" data-sentry-source-file="MobileHeroSlider.jsx" />
                    <MobileHeroSliderButton isNext data-sentry-element="MobileHeroSliderButton" data-sentry-source-file="MobileHeroSlider.jsx" />
                </span>
            </Swiper>
        </Box>;
}
MobileHeroSlider.propTypes = {
  products: PropTypes.array.isRequired
};