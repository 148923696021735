import PropTypes from "prop-types";
import { Flex, Tooltip as ChakraToolTip } from "@chakra-ui/react";
import { useState } from "react";
import { convertRemToPixels } from "@/utils/convertRemToPixels";
import { textSpecs } from "@/theme/foundations/typography";
export function Tooltip({
  label,
  iconSx,
  variant,
  size,
  ...rest
}) {
  const [isOpen, setIsOpen] = useState(false);
  const sizes = {
    default: {
      fontSize: "base",
      width: 8,
      height: 8
    },
    small: {
      fontSize: "base",
      width: 5,
      height: 5
    },
    XS: {
      fontSize: textSpecs.h6.fontSize,
      width: "1.15rem",
      height: "1.15rem",
      lineHeight: 4
    }
  };
  const variantStyling = {
    default: {
      color: "gray.dark",
      borderColor: "gray.lighter",
      backgroundColor: "white"
    },
    gray: {
      color: "white",
      borderColor: "gray.lighter",
      backgroundColor: "gray.lighter"
    },
    dark: {
      color: "blue.darkestTransparent",
      borderColor: "blue.darkestTransparent",
      backgroundColor: "transparent"
    },
    whiteActive: {
      color: "white",
      borderColor: "transparent",
      backgroundColor: "gray.lighterTransparent"
    },
    whiteInactive: {
      color: "blue.darkestTransparent",
      borderColor: "transparent",
      backgroundColor: "gray.lighterTransparent"
    },
    flexibleDark: {
      color: "blue.darkest",
      borderColor: "transparent",
      backgroundColor: "gray.lighterer"
    },
    flexibleGray: {
      color: "blue.darkestTransparenter",
      borderColor: "transparent",
      backgroundColor: "gray.lighterer"
    }
  };
  return <ChakraToolTip placement="top-end" label={label} hasArrow arrowSize={10} pointerEvents={"all"} isOpen={isOpen} modifiers={[{
    // Add space between tooltip and window borders
    // https://stackoverflow.com/a/60159749
    name: "preventOverflow",
    options: {
      altAxis: true,
      padding: convertRemToPixels(1) //
    }
  }]} {...rest} data-sentry-element="ChakraToolTip" data-sentry-component="Tooltip" data-sentry-source-file="Tooltip.jsx">
            <Flex
    // When Tooltip is used within checkbox, prevent click event from bubbling up
    onClick={e => e.preventDefault()} sx={{
      display: "inline-flex",
      marginLeft: 3,
      border: "2px solid",
      borderRadius: "full",
      alignItems: "center",
      justifyContent: "center",
      lineHeight: 1,
      fontWeight: "black",
      verticalAlign: "middle",
      flexShrink: 0,
      ...variantStyling[variant],
      ...sizes[size],
      ...iconSx
    }} onMouseEnter={() => setIsOpen(true)} onMouseLeave={() => setIsOpen(false)} data-sentry-element="Flex" data-sentry-source-file="Tooltip.jsx">
                ?
            </Flex>
        </ChakraToolTip>;
}
Tooltip.propTypes = {
  label: PropTypes.node.isRequired,
  iconSx: PropTypes.object,
  variant: PropTypes.oneOf(["default", "gray", "dark", "white"]),
  size: PropTypes.oneOf(["default", "small", "XS"])
};
Tooltip.defaultProps = {
  variant: "default",
  size: "default"
};