import { chakra } from "@chakra-ui/react";
export function MobilePayMark({
  sx,
  ...rest
}) {
  return <chakra.svg xmlns="http://www.w3.org/2000/svg" width="48px" height="33px" viewBox="0 0 48 33" fill="none" sx={{
    // height: "50px",
    // width: "auto",
    ...sx
  }} {...rest} data-sentry-element="unknown" data-sentry-component="MobilePayMark" data-sentry-source-file="MobilePayMark.jsx">
            <g clipPath="url(#clip0_1_21)" data-sentry-element="g" data-sentry-source-file="MobilePayMark.jsx">
                <rect y="0.59314" width="48" height="32" rx="3.5" fill="#5A78FF" data-sentry-element="rect" data-sentry-source-file="MobilePayMark.jsx" />
            </g>
            <path fillRule="evenodd" clipRule="evenodd" d="M23.717 19.7131C25.5091 19.167 27.0253 18.8923 28.2655 18.8893C30.316 18.8842 32.0351 19.2912 33.4229 20.1103V14.1537C32.028 13.4314 30.452 13.0459 28.6948 12.997C26.9376 12.9482 25.2783 13.3061 23.717 14.0706V19.7131Z" fill="white" data-sentry-element="path" data-sentry-source-file="MobilePayMark.jsx" />
            <path fillRule="evenodd" clipRule="evenodd" d="M20.8052 13.2278L23.0171 18.4927V13.4469C24.1824 12.8229 25.4161 12.423 26.7182 12.2472C28.0203 12.0714 29.5003 12.0997 31.1582 12.3322L29.9552 9.45799C28.2124 9.34545 26.5522 9.62622 24.9746 10.3003C23.397 10.9744 22.0072 11.9502 20.8052 13.2278Z" fill="white" data-sentry-element="path" data-sentry-source-file="MobilePayMark.jsx" />
            <path d="M25.1807 9.49675C24.9871 9.02706 24.6967 8.32253 24.3096 7.38316C23.9021 6.39455 22.7752 5.92144 21.7926 6.32644L15.7791 8.80502C14.7965 9.21002 14.3303 10.3398 14.7378 11.3284L20.5362 25.3963C20.9436 26.3849 22.0705 26.858 23.0531 26.453L29.0666 23.9744C30.0492 23.5694 30.5154 22.4397 30.1079 21.4511C29.7407 20.5601 29.5011 19.9788 29.3891 19.707C29.0619 19.6877 28.8225 19.6761 28.6709 19.6721C28.5108 19.668 28.2827 19.6675 27.9865 19.6706C28.1309 20.0209 28.4426 20.7773 28.9218 21.9399C29.0577 22.2695 28.9023 22.646 28.5747 22.781L22.5612 25.2596C22.2337 25.3946 21.8581 25.2369 21.7223 24.9074L15.9239 10.8395C15.7881 10.5099 15.9435 10.1334 16.271 9.99837L22.2845 7.51979C22.612 7.38479 22.9877 7.54249 23.1235 7.87203L24.004 10.0084C24.2757 9.87582 24.4845 9.78028 24.6305 9.72178C24.7765 9.66327 24.9599 9.58826 25.1807 9.49675Z" fill="white" data-sentry-element="path" data-sentry-source-file="MobilePayMark.jsx" />
            <defs data-sentry-element="defs" data-sentry-source-file="MobilePayMark.jsx">
                <clipPath id="clip0_1_21" data-sentry-element="clipPath" data-sentry-source-file="MobilePayMark.jsx">
                    <rect width="48" height="32" fill="white" transform="translate(0 0.59314)" data-sentry-element="rect" data-sentry-source-file="MobilePayMark.jsx" />
                </clipPath>
            </defs>
        </chakra.svg>;
}