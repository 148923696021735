import { useCallback, useMemo } from "react";
import { useRouter } from "next/router";
import { Box, Link } from "@chakra-ui/react";
import { apiFlexii } from "@/services/client";
export function PreviewBanner() {
  const router = useRouter();
  const isPreview = useMemo(() => router.isPreview, [router.isPreview]);
  const exitPreview = useCallback(() => {
    apiFlexii.get(`v1/protected/contentful-preview/exit?callbackUrl=${router.asPath}`).then(() => router.reload());
  }, [router]);
  if (!isPreview) {
    return null;
  }
  return <Box className="preview-bar" sx={{
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    fontWeight: "bold",
    color: "text",
    wordWrap: "pre",
    textAlign: "center",
    backgroundColor: "gray.lightest",
    zIndex: "toast"
  }} data-sentry-element="Box" data-sentry-component="PreviewBanner" data-sentry-source-file="PreviewBanner.jsx">
            You are now in preview mode! <Link as="button" type="button" onClick={exitPreview} sx={{
      fontWeight: "inherit"
    }} data-sentry-element="Link" data-sentry-source-file="PreviewBanner.jsx">Click here</Link> to exit preview
        </Box>;
}
;