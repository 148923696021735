import PropTypes from "prop-types";
import { Box } from "@chakra-ui/react";
import Image from "next/image";

/**
 * Adds a sticker/image relative to it's parent
 *
 * @param {node} image Contentful image type
 * @param {number} positionX offset from positionX, default=0
 * @param {number} positionY offset from positionY, default=0
 * @param {string} placement placement of the image, default="top left"
 * @param {number} scale scale the image, default=1, negative values flip on the X axis
 * @param {number} rotate rotate image in degrees, Default=0
 */
export function Sticker({
  identifierName,
  image,
  placement,
  positionX,
  // offsetX in Contentful
  positionY,
  // offsetY in Contentful
  scale,
  rotate
}) {
  const {
    title,
    file: {
      url,
      details: {
        image: {
          width,
          height
        }
      }
    }
  } = image.fields;
  const [placementY = "", placementX = ""] = placement?.split(" ") || [];

  // To maintain old sticker positions from before placement was introduced and we switched from % to px offsets.
  const transformOrigin = placement || "center center";
  const top = placementY === "top" ? "0" : placementY === "center" ? "50%" : !placement ? `${positionY}%` : undefined;
  const bottom = placementY === "bottom" ? "0" : undefined;
  const left = placementX === "left" ? "0" : placementX === "center" ? "50%" : !placement ? `${positionX}%` : undefined;
  const right = placementX === "right" ? "0" : undefined;
  const transform = [placementX === "center" ? "translateX(-50%)" : "", placementY === "center" ? "translateY(-50%)" : "", placement ? `translate(${positionX}px, ${positionY}px)` : "", `scaleX(${scale})`, `scaleY(${Math.abs(scale)})`, `rotate(${rotate}deg)`].join(" ");
  return <Box className={`sticker-${identifierName}`} sx={{
    position: "absolute",
    zIndex: 2,
    top,
    bottom,
    left,
    right,
    transform,
    transformOrigin,
    width: `${width}px`,
    height: `${height}px`
  }} data-sentry-element="Box" data-sentry-component="Sticker" data-sentry-source-file="Stickers.jsx">
            <Image src={`https:${url}`} width={width} height={height} alt={title || ""} priority data-sentry-element="Image" data-sentry-source-file="Stickers.jsx" />
        </Box>;
}

/**
 * Adds stickers relative to their parent.
 * Expects an array of Sticker objects from Contentful
 *
 * @param {array} stickers Array of Sticker objects
 */
export function Stickers({
  stickers
}) {
  if (!stickers?.length) return null;
  return stickers.map(({
    fields
  }, i) => <Sticker {...fields} key={`${i} - ${fields.identifierName}`} />);
}
Stickers.propTypes = {
  stickers: PropTypes.arrayOf(PropTypes.shape({
    fields: PropTypes.shape(Sticker.propTypes)
  }))
};
Sticker.propTypes = {
  identifierName: PropTypes.string,
  image: PropTypes.object.isRequired,
  placement: PropTypes.oneOf(["top left", "top center", "top right", "center left", "center center", "center right", "bottom left", "bottom center", "bottom right"]),
  positionX: PropTypes.number,
  positionY: PropTypes.number,
  scale: PropTypes.number,
  rotate: PropTypes.number
};