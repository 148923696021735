import PropTypes from "prop-types";
import { useState } from "react";
import { Box } from "@chakra-ui/react";
import { FaqQuestion } from "@/components/modules/Faq/FaqQuestion";

/**
 *  A wrapper for the FaqQuestion component
 */

export function QuestionAndAnswer({
  questions,
  isCheckout = false
}) {
  const [openQuestion, setOpenQuestion] = useState([]);
  const onClickHandler = questionItem => {
    if (openQuestion === questionItem) {
      setOpenQuestion(null);
    } else {
      setOpenQuestion(questionItem);
    }
    if (isCheckout) {
      // Push FAQ Click to dataLayer. Related Ticket PX-551
      window.dataLayer = window.dataLayer || []; // if dataLayer doesn't exist yet, initialize it
      window.dataLayer.push({
        event: "accordion_click",
        accordionCategory: "Checkout",
        accordionClick: questionItem.question || "Undefined"
      });
    }
  };
  return <Box sx={{
    marginTop: 2
  }} data-sentry-element="Box" data-sentry-component="QuestionAndAnswer" data-sentry-source-file="QuestionAndAnswer.jsx">
            {questions.map((questionItem, i) => <FaqQuestion key={questionItem.question} questionId={i} question={questionItem.question} answer={questionItem.answer} onQuestionClick={onClickHandler} openQuestions={[openQuestion]} lastQuestion={i === questions.length - 1} sx={{
      fontSize: "1rem"
    }} isCheckout />)}
        </Box>;
}
QuestionAndAnswer.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.shape({
    question: PropTypes.node.isRequired,
    answer: PropTypes.node.isRequired
  })).isRequired
};