import PropTypes from "prop-types";
import NextImage from "next/image";
import NextLink from "next/link";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import { Box, Heading, useBreakpointValue } from "@chakra-ui/react";
import { Section } from "@/components/layouts/Section";
import "swiper/css";
import "swiper/css/pagination";

/**
 * A custom carousel that manually slides through a list of objects
 * @param {object} sectionStyling Object with list of styling values to be applied on the outer Section module
 * @param {string} headline Headline above the carousel
 * @param {array} slides list of slides to show in the carousel
 */
export function SnackSlider({
  sectionStyling,
  headline,
  slides
}) {
  const isMobile = useBreakpointValue({
    base: true,
    md: false
  }
  // {
  //     ssr: false,
  // }
  );
  return <Section sectionStyling={sectionStyling} sx={{
    backgroundColor: "#EEFAFF",
    paddingY: 20,
    ".swiper": {
      overflow: "visible"
    },
    ".swiper-wrapper": {
      display: "flex",
      alignItems: "stretch"
    },
    ".swiper-slide": {
      position: "relative",
      display: "flex",
      justifyContent: "center",
      width: "100%",
      maxWidth: {
        base: "85%",
        md: "grid.columns.8"
      }
    },
    ".swiper-pagination": {
      display: "flex",
      justifyContent: "flex-end",
      gap: 1,
      position: "initial",
      top: "initial !important",
      left: "initial !important",
      transform: "none !important",
      width: "auto !important",
      lineHeight: 1,
      marginTop: 4,
      ".swiper-pagination-bullet": {
        opacity: 1,
        width: 3,
        height: 3,
        backgroundColor: "blue.light",
        opacity: 0.7,
        "&-active": {
          opacity: 1,
          width: 12,
          borderRadius: 6
        }
      }
    }
  }} data-sentry-element="Section" data-sentry-component="SnackSlider" data-sentry-source-file="SnackSlider.jsx">
            <Box sx={{
      overflowX: "hidden",
      overflowY: "visible"
    }} data-sentry-element="Box" data-sentry-source-file="SnackSlider.jsx">
                <Section.Content data-sentry-element="unknown" data-sentry-source-file="SnackSlider.jsx">
                    <Heading as="h3" textStyle="h2" variant="gradient" sx={{
          marginBottom: 4
        }} data-sentry-element="Heading" data-sentry-source-file="SnackSlider.jsx">
                        {headline}
                    </Heading>

                    <Swiper modules={[Pagination]} spaceBetween={isMobile ? 16 : 40} //in px
        slidesPerView={"auto"} initialSlide={0} pagination={{
          clickable: true,
          type: "custom",
          renderCustom: (swiper, current, total) => {
            const maximumDots = 3;
            const activeBulletNumber = current > 1 && current < total ? 2 : current === total ? maximumDots : current;
            let returnString = "";
            for (let n = 1; n <= maximumDots; n++) {
              const isActive = activeBulletNumber === n;
              returnString += `
                                        <span
                                            class="swiper-pagination-bullet${isActive ? " swiper-pagination-bullet-active" : ""}">
                                        </span>
                                    `;
            }
            return returnString;
          }
        }} data-sentry-element="Swiper" data-sentry-source-file="SnackSlider.jsx">
                        {slides.map((slideItem, idx) => {
            if (!slideItem?.fields) return null;
            const image = slideItem.fields.backgroundImage?.fields;
            const mobileImage = slideItem.fields.backgroundImageMobile?.fields || image;
            if (!image) return null;
            return <SwiperSlide key={`${slideItem.fields.identifierName}-${idx}`}>
                                    <NextLink href={slideItem.fields.url}>
                                        {/* || isMobile === undefined is nessesary to fix PX-508 due to https://github.com/vercel/next.js/discussions/21379 */}
                                        {(isMobile || isMobile === undefined) && <NextImage src={`https:${mobileImage.file.url}`} alt={mobileImage.title || ""} width={mobileImage.file.details.image.width} height={mobileImage.file.details.image.height} />}
                                        {(!isMobile || isMobile === undefined) && <NextImage src={`https:${image.file.url}`} alt={image.title || ""} width={image.file.details.image.width} height={image.file.details.image.height} />}
                                    </NextLink>
                                </SwiperSlide>;
          })}
                    </Swiper>
                </Section.Content>
            </Box>
        </Section>;
}
SnackSlider.propTypes = {
  headline: PropTypes.string,
  slides: PropTypes.array.isRequired
};