import { useEffect, useState, useRef } from "react";
import { useRouter } from "next/router";
import { useStore } from "../../../store";
import { stepRoutes } from "../../../pages/checkout/[[...step]]";
import { debounce } from "lodash-es";
import ReCAPTCHA from "react-google-recaptcha";
import { signIn, useSession } from "next-auth/react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Container, Box, Heading, Text, Button, Checkbox, Flex, Grid, GridItem, useBreakpointValue, Spinner } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useHookFormMask } from "use-mask-input";
import { apiFlexii, dataLayerUserIdlocalStorageId } from "@/services/client";
import { Tooltip } from "@/components/primitives/Tooltip";
import { initialCheckoutSession } from "@/store/checkout";
import { useMicrocopy } from "@/hooks/useMicrocopy";
import { criiptoLoginHint } from "@/utils";
import { ArrowRightIcon } from "../../../components/icons/ArrowRightIcon";
import { Input, PhoneNumberInput } from "../../../components/primitives/Input";
import { Radios } from "../../../components/primitives/Radios";
import { AvailableNumbersCarousel } from "./components/AvailableNumbersCarousel";
const schema = yup.object({
  keepExistingNumber: yup.boolean(),
  // boolean doesn't exist in HTML Radio
  existingNumber: yup.string().nullable().label("Nuværende nummer").when("keepExistingNumber", {
    is: true,
    // here, yup casts "true" string to true boolean
    then: yup.string().required().transform((value, originalValue) => {
      // Remove non-number characters
      return originalValue ? originalValue.replace(/\D/g, "") : value;
    }).matches(/^[0-9]+$/, "Du må kun bruge tal").min(8, "Dit mobilnummer er for kort").max(8, "Dit mobilnummer er for langt")
  }),
  reservedNumber: yup.string().nullable().label("Ønskede nummer").when("keepExistingNumber", {
    is: false,
    // here, yup casts "false" string to false boolean
    then: yup.string().required().matches(/^[0-9]+$/, "Du må kun bruge tal")
  }),
  reservationToken: yup.string().nullable().when("keepExistingNumber", {
    is: false,
    // here, yup casts "false" string to false boolean
    then: yup.string().required("Du har ikke reserveret et nummer. Vælg et nummer fra listen af ledige telefonnumre")
  }),
  secretNumber: yup.boolean().default(true)
}).required();
const availableNumbersPageLength = 6;

/**
 * Step 1 Checkout View
 * Displays the phone number selection or transfer input fields
 */
export function Step1({
  trackingData,
  globalSettings
}) {
  const {
    getMicrocopy
  } = useMicrocopy();
  const recaptchaRef = useRef();
  const {
    data: session
  } = useSession();
  const [checkoutSession, setCheckoutSession] = useStore(state => [state.checkout.checkoutSession, state.checkout.setCheckoutSession]);
  const [beginCheckoutEventFired, setBeginCheckoutEventFired] = useState(false);
  const [showBalanceControl, setShowBalanceControl] = useState(false);
  const [validating, setValidating] = useState(false);
  const router = useRouter();
  const nextStep = {
    query: {
      step: stepRoutes[1].path
    }
  }; // update only current path slug to the next step
  const {
    register,
    handleSubmit,
    clearErrors,
    setValue,
    getValues,
    setError,
    watch,
    formState: {
      errors,
      isValid
    }
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      // New number takes precedence over transfer existing number when coming to populate persisted state values
      // keepExistingNumber: checkoutSession.anumber.anumber ? "false" : "true" // doesnt work here -> apply defaultValue on input level
      ...(checkoutSession.anumber.anumber ? {
        reservedNumber: checkoutSession.anumber.anumber
      } : {
        existingNumber: checkoutSession.anumberImportRequest.phoneNumber
      })
    }
  });
  const registerWithMask = useHookFormMask(register);
  const [availableNumbers, setAvailableNumbers] = useState();
  const [availableNumbersPages, setAvailableNumbersPages] = useState();
  const [availableNumbersLoadingText, setAvailableNumbersLoadingText] = useState("Henter numre");
  const buttonSize = useBreakpointValue({
    base: "full",
    md: ""
  });
  const textUnderRadio = useBreakpointValue({
    base: true,
    md: false
  });
  const getRecaptchaToken = async () => {
    const recaptchaToken = await recaptchaRef.current.executeAsync();
    return recaptchaToken;
  };

  /**
   * Variable tracking which number option has been selected by the user.
   * check for string "true" to create a boolean value. Boolean doesn't exist for on HTML Input values.
   * watch("XXX") updates the component on every value change
   */
  const keepExistingNumberOptionSelected = watch("keepExistingNumber") === "true";

  /**
   * Switching between new/existing number
   */
  useEffect(() => {
    if (!availableNumbers && !keepExistingNumberOptionSelected) {
      getAvailableNumbers();
    }
    setValue("reservationToken", "");
    setValue("existingNumber", "");
    setValue("reservedNumber", "");
    clearErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keepExistingNumberOptionSelected]);

  // handle show/hide of balanceControl and set value to false when not showing.
  const handleToggleBalanceControl = ({
    target: {
      checked
    }
  }) => {
    setShowBalanceControl(checked);
    if (!checked) {
      setValue("balanceControl", false);
    }
  };

  // Additional onChange handler for input fields
  const handleOnChange = () => {
    handleGtmEvent();
  };

  // Push e-commerce tracking data to DataLayer only once
  const handleGtmEvent = () => {
    if (!beginCheckoutEventFired && trackingData?.ecommerce) {
      setBeginCheckoutEventFired(true);
      const trackingUserId = localStorage.getItem(dataLayerUserIdlocalStorageId);
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        ecommerce: null
      }); // Clear the previous ecommerce object.
      window.dataLayer.push({
        event: "begin_checkout",
        loginStatus: !!trackingUserId,
        ecommerce: {
          ...trackingData.ecommerce
        }
      });
    }
  };
  const getAvailableNumbers = pattern => {
    setAvailableNumbersLoadingText("Henter numre");
    getRecaptchaToken().then(recaptchaToken => {
      recaptchaRef.current.reset();
      apiFlexii.get("v1/public/checkout/a-numbers", {
        params: {
          ...(pattern && {
            pattern
          }),
          gResponse: recaptchaToken
        }
      }).then(({
        data
      }) => createAvailableNumbers(data.numbers)).then(() => setAvailableNumbersLoadingText(null)).catch(() => {
        createAvailableNumbers([]);
        setAvailableNumbersLoadingText(null);
      });
    }).catch(() => {
      setAvailableNumbersLoadingText(null);
    });
  };

  /**
   * Split the large availableNumbers array into smaller chunks
   * Sets both availableNumbers and availableNumbersPages (chunk amounts) state
   */
  const createAvailableNumbers = numbers => {
    if (!numbers) return;
    const subArrays = [];
    // Convert numbers array into subArrays of X numbers
    let availableNumbersPage = 1;
    for (let i = 1; i < numbers.length; i += availableNumbersPageLength) {
      const pageNumbersGroup = numbers.slice(i - 1, availableNumbersPage * availableNumbersPageLength);
      subArrays.push(pageNumbersGroup);
      availableNumbersPage += 1;
    }
    setAvailableNumbers(numbers);
    setAvailableNumbersPages(subArrays);
  };

  /**
   * Debounce number input search avoiding machine gun firing
   */
  const handleInputNumberSearch = debounce(event => {
    getAvailableNumbers(event.target.value);
  }, 500);

  /**
   * Handle the reservation of a new number
   * @param {string} preferredNumber
   */
  const handleNumberReserve = preferredNumber => {
    setAvailableNumbersLoadingText(getMicrocopy("checkout.step1", "reserverer", {
      fallback: "Reserverer nummer"
    }));
    handleGtmEvent();
    getRecaptchaToken().then(recaptchaToken => {
      apiFlexii.put("/v1/public/checkout/reserve-number", null, {
        params: {
          preferredNumber,
          gResponse: recaptchaToken
        }
      }).then(({
        data
      }) => {
        setAvailableNumbersLoadingText(null);
        setValue("reservedNumber", data.reservedNumber, {
          shouldValidate: true
        });
        setValue("reservationToken", data.reservationToken, {
          shouldValidate: true
        });
        clearErrors(["reservedNumber", "reservationToken"]);
      });
    }).catch(() => {
      setAvailableNumbersLoadingText(null);
    });
  };

  /**
   * Check if a number is importable  Flexii
   * Returns true if the number is available for import, or the check fails.
   * Returns false if the number is already at Flexii, or is being ported in
   *
   * @param {string} phonenumber
   * @returns {boolean}
   */
  const isPhoneNumberValidForImport = async phonenumber => {
    if (!phonenumber) return true;
    try {
      const recaptchaToken = await getRecaptchaToken();
      const response = await apiFlexii.post("/v1/public/checkout/check-number-status", {
        phonenumber,
        gResponse: recaptchaToken
      });
      return response.status !== 200; // Number is already at Flexii, or being ported in
    } catch (error) {
      return true;
    }
  };

  // Run method on valid form submission
  const onSubmit = async validatedData => {
    if (validatedData.existingNumber) {
      setValidating(true);
      try {
        const importable = await isPhoneNumberValidForImport(validatedData.existingNumber);
        if (!importable) {
          setError("existingNumber", {
            type: "manual",
            message: getMicrocopy("checkout.step1", "fejl.nummerEksisterer", {
              fallback: "Det lader fileme til, at nummeret du har indtastet ikke kan flyttes! Er du Flexii-kunde i forvejen, skal du logge ind for at skifte dit abonnement."
            })
          });
          return;
        }
      } finally {
        setValidating(false);
      }
    }
    setCheckoutSession({
      ...checkoutSession,
      ...(validatedData.keepExistingNumber ? {
        // Keep existing number
        anumberImportRequest: {
          ...checkoutSession.anumberImportRequest,
          phoneNumber: validatedData.existingNumber
        },
        // Get new number
        anumber: initialCheckoutSession.anumber // nullify the other choice
      } : {
        // Get new number
        anumber: {
          ...checkoutSession.anumber,
          anumber: validatedData.reservedNumber,
          reservationToken: validatedData.reservationToken
        },
        anumberImportRequest: initialCheckoutSession.anumberImportRequest // nullify the other choice
      }),
      secretNumber: validatedData.secretNumber,
      balanceControl: validatedData.balanceControl
    });
    if (session) {
      router.push(nextStep);
    } else {
      signIn("criipto", {
        callbackUrl: "/checkout/oplysninger"
      }, {
        login_hint: criiptoLoginHint(globalSettings.fields.enableMitIdAppButton)
      });
    }
  };

  // When reservedNumber is equal to 8 digits, a number has been selected and we hide available numbers
  const showAvailableNumbers = getValues("reservedNumber")?.length !== 8;
  return <Container maxWidth="container.sm" sx={{
    padding: 0
  }} data-sentry-element="Container" data-sentry-component="Step1" data-sentry-source-file="Step1.jsx">
            <Box sx={{
      marginBottom: 8
    }} data-sentry-element="Box" data-sentry-source-file="Step1.jsx">
                <Heading as="h3" sx={{
        marginBottom: 2
      }} data-sentry-element="Heading" data-sentry-source-file="Step1.jsx">
                    {getMicrocopy("checkout.step1", "vælgNummer", {
          fallback: "Vælg dit mobilnummer"
        })}
                </Heading>
                <Text sx={{
        fontWeight: "black",
        marginBottom: 0
      }} data-sentry-element="Text" data-sentry-source-file="Step1.jsx">
                    {getMicrocopy("checkout.step1", "vælgNummer.undertekst", {
          fallback: "Det er selvfølgelig gratis, og vi sørger for det hele."
        })}
                </Text>
            </Box>

            <form onSubmit={handleSubmit(onSubmit)}>
                <Box sx={{
        marginBottom: 4
      }} data-sentry-element="Box" data-sentry-source-file="Step1.jsx">
                    <Radios defaultValue={checkoutSession.anumber.anumber ? "false" : "true"} {...register("keepExistingNumber", {
          onChange: handleOnChange
        })} sx={{
          flexDirection: "row"
        }} labelSx={{
          fontWeight: "black"
        }} options={[{
          value: "true",
          // boolean doesn't exist in HTML Radio
          label: "Behold nummer"
        }, {
          value: "false",
          // boolean doesn't exist in HTML Radio
          label: "Nyt nummer",
          testId: "radio-option_available-numbers"
        }]} data-sentry-element="Radios" data-sentry-source-file="Step1.jsx" />
                </Box>

                {keepExistingNumberOptionSelected ? <PhoneNumberInput placeholder="Indtast nummer" errorMessage={errors?.existingNumber?.message} {...registerWithMask("existingNumber", "99 99 99 99", {
        onChange: handleOnChange,
        placeholder: " "
      })} /> : <>
                        <Input hidden label="ReservationToken" {...register("reservationToken")} />
                        <PhoneNumberInput placeholder="Indtast ønskede nummer" errorMessage={errors?.reservedNumber?.message || errors?.reservationToken?.message} onKeyUp={handleInputNumberSearch} isDisabled={!!getValues("reservationToken")} {...register("reservedNumber", {
          onChange: handleOnChange
        })} inputProps={{
          "data-test-id": "number-input_available-numbers"
        }} />

                        {/* Available numbers */}
                        <Box sx={{
          position: "relative",
          marginBottom: 6
        }} data-test-id="ava">
                            {showAvailableNumbers && <AvailableNumbersCarousel handleNumberReserve={handleNumberReserve} availableNumbersPages={availableNumbersPages} loadingText={availableNumbersLoadingText} />}
                        </Box>
                    </>}
                <Box sx={{
        ".grecaptcha-badge": {
          zIndex: "sticky"
        }
      }} data-sentry-element="Box" data-sentry-source-file="Step1.jsx">
                    <ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey={process.env.NEXT_PUBLIC_G_RECAPTCHA_SITE_KEY} data-sentry-element="ReCAPTCHA" data-sentry-source-file="Step1.jsx" />
                </Box>

                <Flex sx={{
        flexDirection: "column",
        alignItems: "flex-start",
        gap: 2,
        marginBottom: 4
      }} data-sentry-element="Flex" data-sentry-source-file="Step1.jsx">
                    <Checkbox defaultChecked marginBottom={0} isInvalid={errors?.secretNumber} {...register("secretNumber", {
          onChange: handleOnChange
        })} data-sentry-element="Checkbox" data-sentry-source-file="Step1.jsx">
                        <Text as="b" data-sentry-element="Text" data-sentry-source-file="Step1.jsx">
                            {getMicrocopy("checkout.step1", "skjulteOplysninger", {
              fallback: "Skjulte personoplysninger"
            })}
                        </Text>
                        <Tooltip label={getMicrocopy("checkout.step1", "skjulteOplysninger.tooltip", {
            fallback: "Udeladt nummer, navn og adresse (bliver ikke vist på fx 118.dk)"
          })} size="small" data-sentry-element="Tooltip" data-sentry-source-file="Step1.jsx" />
                    </Checkbox>
                    <Checkbox onChange={handleToggleBalanceControl} data-sentry-element="Checkbox" data-sentry-source-file="Step1.jsx">
                        <Text as="b" data-sentry-element="Text" data-sentry-source-file="Step1.jsx">
                            {getMicrocopy("checkout.step1", "saldokontrol.checkout.overskrift", {
              fallback: "Saldokontrol"
            })}
                        </Text>
                        <Tooltip label={getMicrocopy("checkout.step1", "saldokontrol.checkout.overskrift.tooltip", {
            fallback: "Med Saldokontrol bestemmer du selv hvilket beløb du max vil bruge pr. måned på fx opkald til udlandet eller servicenumre eller andet forbrug der er udover dit abonnement. Skulle du nå du dit max, bliver du spærret for forbrug der koster penge."
          })} size="small" data-sentry-element="Tooltip" data-sentry-source-file="Step1.jsx" />
                    </Checkbox>
                    {showBalanceControl && <Radios options={[{
          value: "25",
          label: "25 kr"
        }, {
          value: "50",
          label: "50 kr"
        }, {
          value: "100",
          label: "100 kr"
        }, {
          value: "200",
          label: "200 kr"
        }]} sx={{
          flexDirection: "row",
          marginBottom: 8,
          marginLeft: 8,
          columnGap: 6
        }} labelSx={{
          fontWeight: "black"
        }} textUnderRadio={textUnderRadio} {...register("balanceControl")} />}
                </Flex>

                {/* Finalizing buttons */}
                <Grid sx={{
        marginTop: 8,
        gridTemplateColumns: [null, null, "1fr"]
      }} data-sentry-element="Grid" data-sentry-source-file="Step1.jsx">
                    <GridItem sx={{
          display: "flex",
          justifyContent: "center"
        }} data-sentry-element="GridItem" data-sentry-source-file="Step1.jsx">
                        <Button type="submit" size={buttonSize} rightIcon={<Flex justifyContent="center" width="24px">
                                    {validating ? <Spinner /> : <ArrowRightIcon />}
                                </Flex>} sx={{
            margin: 0,
            paddingX: {
              base: 10,
              sm: 14
            }
          }} data-test-id="button_continue-to-step-2" isDisabled={!isValid} data-sentry-element="Button" data-sentry-source-file="Step1.jsx">
                            {!!session ? getMicrocopy("global", "fortsæt", {
              fallback: "Fortsæt"
            }) : getMicrocopy("checkout.step1", "logindMitID", {
              fallback: "Log ind med MitID"
            })}
                        </Button>
                    </GridItem>
                </Grid>
            </form>
        </Container>;
}