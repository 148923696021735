import PropTypes from "prop-types";
export function ArrowHanddrawnRightIcon({
  stroke = null,
  strokeWidth = "1.4px",
  transform = "",
  ...rest
}) {
  return <svg xmlns="http://www.w3.org/2000/svg" height="100%" width="100%" viewBox="0 0 30.824 17.449" {...rest} data-sentry-element="svg" data-sentry-component="ArrowHanddrawnRightIcon" data-sentry-source-file="ArrowHanddrawnRightIcon.jsx">
            <g id="Group_621" transform={`translate(-124.346 -123.178) ${transform}`} data-sentry-element="g" data-sentry-source-file="ArrowHanddrawnRightIcon.jsx">
                <path id="Path_355" d="M-18284.977-4416.833s3.359,3.058,11.6,6.416-9.539,2.974-11.6,9.158" transform="translate(18425.559 4541)" fill="none" stroke={stroke ?? "currentColor"} strokeLinecap="round" strokeLinejoin="round" strokeWidth={strokeWidth} data-sentry-element="path" data-sentry-source-file="ArrowHanddrawnRightIcon.jsx" />
                <path id="Path_356" d="M-18273.742-4413.041s-17.254-3.483-29.113.5" transform="translate(18428.088 4544.996)" fill="none" stroke={stroke ?? "currentColor"} strokeLinecap="round" strokeWidth={strokeWidth} data-sentry-element="path" data-sentry-source-file="ArrowHanddrawnRightIcon.jsx" />
            </g>
        </svg>;
}
ArrowHanddrawnRightIcon.propTypes = {
  stroke: PropTypes.string,
  strokeWidth: PropTypes.string,
  transform: PropTypes.string
};