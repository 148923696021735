import PropTypes from "prop-types";
import { Card } from "@/components/layouts/Card";
import { Heading } from "@chakra-ui/react";
import { FaqQuestion } from "./FaqQuestion";
import Image from "next/image";
export function FaqCategory({
  icon,
  headline,
  questions,
  onQuestionClick,
  openQuestions
}) {
  return <Card as="ul" key={headline} sx={{
    backgroundColor: "white"
  }} data-sentry-element="Card" data-sentry-component="FaqCategory" data-sentry-source-file="FaqCategory.jsx">
            <Heading as="h3" sx={{
      display: "flex",
      alignItems: "baseline",
      flexWrap: "wrap",
      gap: 2,
      color: "text"
    }} data-sentry-element="Heading" data-sentry-source-file="FaqCategory.jsx">
                {!!icon && <Image src={`https://${icon.fields.file.url}`} width={40} height={40} alt={icon.fields.description || ""} />}

                {headline}
            </Heading>

            {questions.map((questionItem, i) => {
      if (!questionItem.fields) return null;
      return <FaqQuestion key={questionItem.sys.id} questionId={questionItem.fields.anchorId || questionItem.sys.id} question={questionItem.fields.question} answer={questionItem.fields.answer} onQuestionClick={(id, text) => onQuestionClick(id, text, headline)} // forward id from FaqQuestion + attach headline
      openQuestions={openQuestions} lastQuestion={i === questions.length - 1} />;
    })}
        </Card>;
}
FaqCategory.propTypes = {
  icon: PropTypes.object,
  headline: PropTypes.string.isRequired,
  questions: PropTypes.array.isRequired,
  onQuestionClick: PropTypes.func.isRequired,
  openQuestions: PropTypes.array.isRequired
};