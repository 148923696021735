import PropTypes from "prop-types";
import { Container, Stack } from "@chakra-ui/react";
import { Step } from "./Step";
export function ProgressSteps({
  steps,
  currentStepIndex,
  onStepClickHandler,
  ...rest
}) {
  return <Container sx={{
    paddingTop: 0,
    paddingX: {
      base: 4,
      lg: 0
    },
    paddingBottom: {
      base: 10,
      lg: 14
    },
    maxWidth: "md"
  }} {...rest} data-sentry-element="Container" data-sentry-component="ProgressSteps" data-sentry-source-file="ProgressSteps.jsx">
            <Stack spacing="0" direction="row" justifyContent="center" data-sentry-element="Stack" data-sentry-source-file="ProgressSteps.jsx">
                {steps.map((step, idx) => {
        return <Step key={idx} stepIndex={idx} isCompleted={idx <= currentStepIndex} onClick={() => onStepClickHandler(idx)} title={step.title} />;
      })}
            </Stack>
        </Container>;
}
ProgressSteps.propTypes = {
  currentStepIndex: PropTypes.number,
  onStepClickHandler: PropTypes.func,
  // onStepClickHandler receives the index value of the clicked step
  steps: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired
  })).isRequired
};