import { useRef } from "react";

/**
 * Create a unique, NONE cryptographic, id
 *
 * useRef is used here to create a constant instance variable
 * @see https://stackoverflow.com/a/70039497/3673659
 * @returns {string}
 */
export const useUniqueId = () => {
  let {
    current: id
  } = useRef(`uid-${`${Math.random().toString(36)}00000000000000000`.slice(2, 12)}`);
  return id;
};