import PropTypes from "prop-types";
export function StarIcon({
  fill = null,
  ...rest
}) {
  return <svg fill="none" height={14} width={15} xmlns="http://www.w3.org/2000/svg" {...rest} data-sentry-element="svg" data-sentry-component="StarIcon" data-sentry-source-file="StarIcon.jsx">
            <path d="m7.5 0 1.684 5.182h5.449l-4.408 3.203 1.683 5.183L7.5 10.365l-4.408 3.203 1.683-5.183L.367 5.182h5.45L7.5 0Z" fill={fill ?? "#E3B40F"} data-sentry-element="path" data-sentry-source-file="StarIcon.jsx" />
        </svg>;
}
StarIcon.propTypes = {
  fill: PropTypes.string
};