import React, { useEffect, useRef, useState, useCallback } from "react";
import PropTypes from "prop-types";
import AdyenCheckout from "@adyen/adyen-web";
import "@adyen/adyen-web/dist/adyen.css";
import { Box, Text, Button } from "@chakra-ui/react";
import { apiFlexii } from "@/services/client";
import { LoadingSpinner } from "@/components/primitives/LoadingSpinner";
import { AdyenDropInPaymentStyle } from "./AdyenDropInPayment.style";
import { useRouter } from "next/router";
const inlineErrorTypes = {
  cancelled: {
    title: "For Flexii da – der skete en fejl!",
    message: "Din betaling blev afbrudt"
  },
  refused: {
    title: "For Flexii da – der skete en fejl!",
    message: "Din betaling blev afvist"
  },
  network: {
    title: "For Flexii da – der skete en fejl!",
    message: "Der skete en netværksfejl i håndteringen af din betaling. Prøv igen."
  },
  error: {
    title: "For Flexii da – der skete en fejl!",
    message: "Der skete en fejl i håndteringen af din betaling"
  }
};

/**
 * Instantiate AdyenDropIn Window
 * =======
 * Test environment card numbers and response codes
 * https://docs.adyen.com/development-resources/testing/test-card-numbers#visa
 * https://docs.adyen.com/development-resources/testing/result-code-testing/adyen-response-codes
 * https://docs.adyen.com/development-resources/testing/test-card-numbers#test-3d-secure-authentication
 */
export function AdyenDropInPayment({
  /**
   * payload is all client data gathered before payment happens. This data will temporarily be stored
   * in the Database and retrieved when the adyen notification webhook is received
   */
  payload,
  amount,
  onPaymentSuccess,
  onError // onError callback function that returns an Error object
}) {
  const router = useRouter();
  const isMountedRef = useRef(false);
  const dropInRef = useRef();
  const adyenInstance = useRef();
  const [isLoading, setIsLoading] = useState(true);
  const [inlineError, setInlineError] = useState(null);
  const [loadingText, setLoadingText] = useState("Henter betalingsvindue");

  /**
   * Initialize Page
   */
  useEffect(() => {
    if (!router.isReady) return undefined; // check if router and query params are ready to be read

    if (isMountedRef.current && !router?.query?.redirectResult)
      // This is to prevent remounting in development with ReactStrictMode on, which causes double session.
      return undefined;
    isMountedRef.current = true;

    // Initialize Adyen Drop In Window or handle redirect result
    initAdyenDropin();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router]);

  /**
   * Handler when closing the inline error window button.
   * (Reloads adyen dropin window to accept new payment)
   */
  const onInlineErrorClose = event => {
    event.preventDefault();
    setInlineError(null);
    setIsLoading(true);

    // Reload adyen payment window to accept a new payment
    adyenInstance.current.update().then(() => {
      setIsLoading(false);
    });
  };

  /**
   * Init Adyen DropIn Component, either:
   * - fetches adyen server session and creates a payment window
   * - a payment has already been made, and the page is loaded again with `sessionId` and `returnUrl`
   *   query params (coming from 3DSecure). If true, verify if previous payment was successful and
   *   redirect to next page.
   */
  const initAdyenDropin = async () => {
    if (router?.query?.redirectResult) {
      // Redirect from 3DS Secure - handle `sessionId` and `returnUrl` and check if previous payment was successful
      setLoadingText("Gennemfører betaling");
      const checkout = await getAdyenCheckoutInstance({
        id: router.query.sessionId
      });
      checkout.submitDetails({
        details: {
          redirectResult: router.query.redirectResult
        }
      });
    } else {
      // New payment request - get server side session and instantiate payment window
      if (!adyenInstance.current && dropInRef.current) {
        // Payment window has not yet been instantiated
        const serverPaymentSession = await fetchServerPaymentSession();
        if (!serverPaymentSession) return undefined; // stop if server payment failed - error is handled in above method
        adyenInstance.current = await getAdyenCheckoutInstance({
          id: serverPaymentSession.data.id,
          sessionData: serverPaymentSession.data.sessionData
        });
        // Set merchantReference in sessionStorage to make it accessible when returning from an optional 3DS pag redirect
        sessionStorage.setItem("merchantReference", serverPaymentSession.data.merchantReference);
        // Check a second time if dropinRef exists. In some scenarios (e.g. returning
        // from kvittering to payment page in checkout) may actually return "null" AT THIS POINT
        // eventough it had a value just before. This is likely due to the slight delay from the
        // await statements.
        if (dropInRef.current) {
          adyenInstance.current.create("dropin", {
            showStoredPaymentMethods: false,
            onReady: () => setIsLoading(false)
          }).mount(dropInRef.current);
        }
      }
    }
  };

  /**
   * Return server side initialized Adyen session
   * and set it to state variable.
   */
  const fetchServerPaymentSession = useCallback(async () => apiFlexii.post("v1/protected/resource/payment/session", {
    // body
    amount,
    payload
  }).then(res => res).catch(error => {
    // Session initializer failed.
    onError(inlineErrorTypes.network.message);
    console.error(`Error initiating Adyen Session`, error);
    setIsLoading(false);
    return undefined;
  }), [amount, onError, payload]);

  /**
   * Create an AdyenCheckout instance
   * An AdyenCheckout instance is required for both instantiating a payment window,
   * aswell as verifying an additional payment verification step (e.g 3DS Secure).
   * @param {object} session {id: <session id>, data: <session data>}
   * @returns {AdyenCheckout} instance
   */
  const getAdyenCheckoutInstance = async session => {
    const configuration = {
      locale: "da-DK",
      // The language used in the Drop-in UI.
      environment: process.env.NEXT_PUBLIC_ADYEN_ENVIRONMENT,
      clientKey: process.env.NEXT_PUBLIC_ADYEN_CLIENT_KEY,
      session,
      // session.sessionData contains all the adyen session backend data, that was sent raw to adyen, in an encrypted form - making a secure frontend payment.
      paymentMethodsConfiguration: {
        card: {
          name: "Betalingskort",
          hasHolderName: process.env.NEXT_PUBLIC_ADYEN_ENVIRONMENT === "TEST",
          showBrandsUnderCardNumber: false
        }
      },
      translations: {
        "da-DK": {
          confirmPreauthorization: "Tilmeld",
          payButton: "Tilmeld og betal"
        }
      },
      onError: error => {
        /**
         * https://docs.adyen.com/online-payments/web-drop-in#drop-in-error-handling
         *
         * the only relevant error type in the onError callback, for us, is NETWORK_ERROR.
         * Even if this error is super generic, it is handled on it's own here to make sure
         * the implementation is visible for future changes / debugging.
         */
        const errorObj = new Error(inlineErrorTypes.network.message);
        errorObj["adyenRawError"] = `${error.name || ""} ${error.message || ""}`;
        onError(errorObj);
      },
      onPaymentCompleted: result => {
        /**
         * A completed payment may still fail after additional checks.
         * The resultcode has the final saying in this, tho a resultcode is not always
         * available, for example of the client didn't return to the website.
         * Only use the resultcode to present payment result to the shopper!
         * @see https://docs.adyen.com/online-payments/web-components?tab=codeBlocksessions_wCoHk_JS_6#handle-redirect-result
         *
         * @example You can trigger a failed resultcode with using a random CVC code (e.g. 666)
         */
        switch (result.resultCode) {
          case "Authorised":
            onPaymentSuccess({
              merchantReference: sessionStorage.getItem("merchantReference")
            });
            sessionStorage.removeItem("merchantReference");
            break;
          case "Cancelled":
            setInlineError(inlineErrorTypes.cancelled);
            onError(new Error(inlineErrorTypes.cancelled.message));
            break;
          case "Refused":
            setInlineError(inlineErrorTypes.refused);
            onError(new Error(inlineErrorTypes.refused.message));
            break;
          default:
            setInlineError(inlineErrorTypes.error);
            onError(new Error(inlineErrorTypes.error.message));
            break;
        }
      }
    };
    return await AdyenCheckout(configuration);
  };
  return <Box id="adyen-payment" className="adyen-dropin" sx={{
    ...AdyenDropInPaymentStyle
  }} data-sentry-element="Box" data-sentry-component="AdyenDropInPayment" data-sentry-source-file="AdyenDropInPayment.jsx">
            {isLoading && !inlineError && <Box sx={{
      backgroundColor: "gray.lightest",
      borderRadius: "card"
    }}>
                    <LoadingSpinner label={loadingText} />
                </Box>}

            {inlineError && <Box className="adyen-checkout__status adyen-checkout__status--error">
                    <Text as="span" className="adyen-checkout__status__text" sx={{
        marginBottom: 4
      }}>
                        <Text as="strong" sx={{
          fontSize: "h3",
          color: "error"
        }}>
                            {inlineError.title}
                        </Text>
                        <br />
                        {inlineError.message}
                    </Text>
                    <Button type="button" variant="action" onClick={onInlineErrorClose}>
                        Start forfra
                    </Button>
                </Box>}

            {/* The AdyenDropIn Payment provider will be embeded in below element */}
            <Box ref={dropInRef} sx={{
      display: !!inlineError || !!isLoading ? "none" : null
    }} className="mask-me" data-sentry-element="Box" data-sentry-source-file="AdyenDropInPayment.jsx" />
        </Box>;
}
AdyenDropInPayment.propTypes = {
  payload: PropTypes.object.isRequired,
  amount: PropTypes.number.isRequired,
  onError: PropTypes.func.isRequired,
  onPaymentSuccess: PropTypes.func.isRequired
};
AdyenDropInPayment.defaultProps = {
  amount: 0
};