import PropTypes from "prop-types";
import { Box, Flex, Radio, RadioGroup, Text, Divider } from "@chakra-ui/react";
import { forwardRef, Fragment } from "react";
import { Tooltip } from "../Tooltip";

/**
 * Radio Button component.
 * !!! DOES NOT REQUIRE VALIDATION SINCE THE FIRST OPTION IS SELECTED BY DEFAULT UNLESS SPECIFIED.
 */
function RadiosComponent({
  options,
  defaultValue,
  sx,
  labelSx,
  onChange,
  onBlur,
  name,
  textUnderRadio,
  divider,
  ...rest
}, ref) {
  return <RadioGroup defaultValue={defaultValue ?? `${options[0].value}`} sx={{
    display: "flex",
    columnGap: 4,
    rowGap: 2,
    flexFlow: "wrap",
    ...sx
  }} {...rest} data-sentry-element="RadioGroup" data-sentry-component="RadiosComponent" data-sentry-source-file="Radios.jsx">
            {options.map((option, index) => <Fragment key={option.value}>
                    {divider && index > 0 && <Divider my={2} borderBottomWidth="1px" borderBottomColor="gray.lighter" />}
                    <Flex key={option.value} alignItems="center" justifyContent="space-between">
                        <Radio {...{
          onChange,
          onBlur,
          name,
          ref
        }} value={`${option.value}`} flexDirection={textUnderRadio ? "column" : "row"} isDisabled={option.isDisabled}>
                            <Box>
                                <Text as="span" sx={{
              marginBottom: 0,
              marginLeft: textUnderRadio ? -2 : 0,
              ...labelSx
            }} {...option.testId ? {
              "data-test-id": option.testId
            } : {}}>
                                    {option.label}
                                </Text>

                                {option.tooltip && <Tooltip label={option.tooltip} size="small" />}
                            </Box>
                        </Radio>
                        {option.icon && <span>{option.icon}</span>}
                    </Flex>
                </Fragment>)}
        </RadioGroup>;
}
export const Radios = forwardRef(RadiosComponent);
Radios.propTypes = {
  defaultValue: PropTypes.string,
  labelSx: PropTypes.object,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  textUnderRadio: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
    label: PropTypes.string.isRequired,
    testId: PropTypes.string,
    isDisabled: PropTypes.bool
  })),
  sx: PropTypes.object
};