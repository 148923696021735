import PropTypes from "prop-types";
import { chakra } from "@chakra-ui/react";
const Svg = chakra("svg");
export function PadlockIcon({
  isOpen,
  ...rest
}) {
  return <Svg viewBox="0 0 16 25" width={16} height={25} fill="none" xmlns="http://www.w3.org/2000/svg" {...rest} data-sentry-element="Svg" data-sentry-component="PadlockIcon" data-sentry-source-file="PadlockIcon.jsx">
            <path fillRule="evenodd" clipRule="evenodd" d={isOpen ? "M6.5 4.5c0-.41.13-.634.248-.752S7.091 3.5 7.5 3.5c.346 0 .628.014.863.052.234.037.36.09.422.128a.244.244 0 0 1 .106.12c.04.086.109.29.109.7h3c0-1.505-.53-2.7-1.66-3.386C9.384.535 8.244.5 7.5.5 6.41.5 5.384.87 4.627 1.627 3.87 2.384 3.5 3.409 3.5 4.5V8.166a4 4 0 0 0-3.5 3.97v8a4 4 0 0 0 4 4h8a4 4 0 0 0 4-4v-8a4 4 0 0 0-4-4H6.5V4.5Z" : "M6.5 4.5c0-.41.13-.634.248-.752S7.091 3.5 7.5 3.5c.346 0 .628.014.863.052.234.037.36.09.422.128a.244.244 0 0 1 .106.12c.04.086.109.29.109.7V8.135H6.5V4.5Zm-3 3.666V4.5c0-1.09.37-2.116 1.127-2.873C5.384.87 6.409.5 7.5.5c.744 0 1.884.035 2.84.614C11.47 1.8 12 2.995 12 4.5V8.135a4 4 0 0 1 4 4v8a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4v-8a4 4 0 0 1 3.5-3.969Z"} fill="#fff" data-sentry-element="path" data-sentry-source-file="PadlockIcon.jsx" />
            <path d="M6 14.949a2 2 0 1 1 4 0v2.124a2 2 0 1 1-4 0V14.95Z" fill="#3BAFC8" data-sentry-element="path" data-sentry-source-file="PadlockIcon.jsx" />
        </Svg>;
}
PadlockIcon.propTypes = {
  isOpen: PropTypes.bool
};
PadlockIcon.defaultProps = {
  isOpen: false
};